// Child Component of: 
// --- BaseBlock

// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    paper: {
      width: 515,//600
      height: 200,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
    },
    grid: {
        justify: 'flex-start',
    },
    gridWhite: {
        justify: 'flex-start',
        backgroundColor: 'white',
        borderRadius: '15px',
        padding: theme.spacing(1),
    },
    titleText: {
        color: "white",
        margin: theme.spacing(1),
    },
    inputField: {
        width: "100%",
        color: "white",
        marginTop: theme.spacing(0.6),
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    inputFieldRC: {
        width: "100%",
        color: "white",
        marginTop: theme.spacing(0.6),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        textAlign: "center",
    },
    regularText: {
        color: "white",
        margin: theme.spacing(1),
    },
    textButton: {
        color: "white",
        margin: theme.spacing(0),
        border: '1px solid #000',
    },
    colorDisplay: {
        margin: '7px',
        height: '25px',
        width: '50px',
        borderRadius: '5px',
        border: '1px solid #000',
    },
    textSelect: {
        color: "white",
        marginTop: theme.spacing(0.4),        
    },
});




// Component Class
class LocoHexBlocks extends React.Component {

    state = {
        LBlocksOptions: {
            ipose: 
            {
                id: 0,
                title: 'Pose',
                height: 145, 
                color: '#009788',
                control: {btn1: 0, btn1Txt: 'DEFAULT POSE', in1: 0},
                codeRef(ctrl) {
                    return [
                        `# Position in Pose ${ctrl.btn1Txt} over ${ctrl.in1} seconds`, 
                        `locohex.pose(locohex.${ctrl.btn1}, ${ctrl.in1})`,
                    ]
                },
                code: [],
                btn1Options: ['DEFAULT POSE', 'DEFAULT POSE M'],
                btn1map: {'DEFAULT POSE':'DEFAULT_POSE', 'DEFAULT POSE M':'DEFAULT_POSE_M'},
                inWidth: '40px',
                in1Type: 'float',
                in1Limits: [0, 10],
                text1: 'Position in Pose ',
                text2: 'over ',
                text3: 'seconds',
            },
            itravel: 
            {
                id: 0,
                title: 'Travel',
                height: 135, 
                color: '#4DAF4E',
                control: {btn1: 0, btn1Txt: 'FORWARD', in1: 0, },
                codeRef(ctrl) {
                    const ms_dur = ctrl.in1 * 1000;
                    return [
                        `# Travel ${ctrl.btn1Txt} for ${ms_dur} milliseconds`, 
                        `locohex.travel(locohex.${ctrl.btn1}, ${ms_dur})`,
                    ]
                },
                code: [],
                btn1Options: [
                    'FORWARD', 'BACKWARD', 'LEFT', 'RIGHT',
                ],
                btn1map: {
                    'FORWARD':'FORWARD','BACKWARD':'BACKWARD',
                    'LEFT':'LEFT','RIGHT':'RIGHT',
                },
                inWidth: '40px',
                in1Type: 'int',
                in1Limits: [0, 10],
                text1: 'Travel ',
                text2: 'for ',
                text3: 'seconds',
            },
            iaction:
            {
                id: 0,
                title: 'Action',
                height: 135, 
                color: '#FF2424',
                control: {btn1: 'STAND_HIGH', btn1Txt: 'STAND HIGH', },
                codeRef(ctrl) {
                    return [
                        `# Perform Action: ${ctrl.btn1Txt}`, 
                        `locohex.action(locohex.${ctrl.btn1})`,
                    ]
                },
                code: [],
                btn1Options: [
                    'STAND HIGH', 'STAND MIDDLE', 'STAND LOW',
                    'TURN LEFT', 'TURN RIGHT'
                ],
                btn1map: {
                    'STAND HIGH':'STAND_HIGH',
                    'STAND MIDDLE':'STAND_MIDDLE','STAND LOW':'STAND_LOW',
                    'TURN LEFT':'TURN_LEFT','TURN RIGHT':'TURN_RIGHT'
                },
                text1: 'Perform ',
            },
            iloop:
            {
                id: 0,
                title: 'Loop',
                height: 135, 
                color: '#8057c1',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Loop for ${ctrl.in1} times`, `for i in range(${ctrl.in1}):`]
                },
                code: [],
                inWidth: '40px',
                in1Type: 'int',
                text1: 'Repeat',
                text2: 'times',
            },
            iloopend:
            {        
                id: 0,
                title: 'LoopEnd',
                height: 135, 
                color: '#8057c1',
                control: {},
                code: [],
            },
            ipause:
            {
                id: 0,
                title: 'Pause',
                height: 135, 
                color: '#fbc22d',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Pause for ${ctrl.in1} seconds`, `time.sleep(${ctrl.in1})`]
                },
                code: [],
                inWidth: '40px',
                in1Type: 'float',
                text1: 'Pause for',
                text2: 'seconds',
                in1Limits: [0, 60],
            },
        },
        renderType: this.props.blockType,
        btn1: 0,
        btn2: 0,
        btn3: 0,
        btn4: 0,
        btn1Txt: '',
        btn2Txt: '',
        btn3Txt: '',
        btn4Txt: '',
        in1: 0,
        in2: 0,
        in3: 0,
        in4: 0,
        sel1Txt: '',
        sel2Txt: '',
        sel1: 0,
        sel2: 0,
    }

    componentDidMount() {
        let updates = {control: this.props.control};
        this.componentRefresh(updates);
    }

    componentRefresh = (updates) => {
        
        let tempRenderType = this.state.renderType;
        
        if ('name' in updates) {
            tempRenderType = updates.name;
            this.setState( {renderType: updates.name} );
        }

        let tempLBlocks = { ...this.state.LBlocksOptions };

        tempLBlocks[tempRenderType].id = this.props.id
        if ((typeof updates.control !== "undefined") && (Object.keys(updates.control).length !== 0)) {
            let keyNames = Object.keys(updates.control);
            keyNames.forEach((val, i) => {
                tempLBlocks[tempRenderType].control[val] = updates.control[val];
                this.setState( {[val]: updates.control[val]} );
                if (tempLBlocks[tempRenderType].hasOwnProperty('codeRef')) {
                    tempLBlocks[tempRenderType].code = tempLBlocks[tempRenderType].codeRef(tempLBlocks[tempRenderType].control);
                }
            });
        } else {
            if (tempLBlocks[tempRenderType].hasOwnProperty('codeRef')) {
                tempLBlocks[tempRenderType].code = tempLBlocks[tempRenderType].codeRef(tempLBlocks[tempRenderType].control);
            }

            const controls = ['btn1', 'btn2', 'btn3', 'btn4', 'in1', 'in2', 'in3', 'in4', 'sel1', 'sel2'];

            controls.forEach(control => {
                if (control in tempLBlocks[tempRenderType].control) {
                    if (control.startsWith('btn')) {
                        const optionKey = `${control}Options`;
                        const mapKey = `${control}map`;
                        tempLBlocks[tempRenderType].control[control] = tempLBlocks[tempRenderType][mapKey][tempLBlocks[tempRenderType][optionKey][0]];
                        tempLBlocks[tempRenderType].control[`${control}Txt`] = tempLBlocks[tempRenderType][optionKey][0];
                        this.setState({ [`${control}Txt`]: tempLBlocks[tempRenderType].control[`${control}Txt`] });
                    } else if (control.startsWith('in')) {
                        this.setState({ [control]: tempLBlocks[tempRenderType].control[control] });
                    } else if (control.startsWith('slid')) {
                        this.setState({ [control]: tempLBlocks[tempRenderType].control[control] });
                    } else if (control.startsWith('sel')) {
                        const optionKey = `${control}Options`;
                        tempLBlocks[tempRenderType].control[`${control}Txt`] = tempLBlocks[tempRenderType][optionKey][0];
                        this.setState({ [`${control}Txt`]: tempLBlocks[tempRenderType].control[`${control}Txt`] });
                    }
                }
            });
        }
        
        this.setState( {LBlocksOptions: tempLBlocks} );
        this.props.handleSubState(tempLBlocks[tempRenderType]);
    }

    handleInOnBlur = (event, name) => {
        if (!this.state.hasOwnProperty(name)) {
            return
        }
        let m = this.state[name]
        let inName = name

        let inLimits = inName + 'Limits'
        let inCust = inName + 'Cust'
        let inType = inName + "Type"

        let tempLBlocks = this.state.LBlocksOptions;
        if (!isNaN(parseFloat(m)) && isFinite(m)) {
            let n = Number(m);
            
            if (inLimits in tempLBlocks[this.state.renderType]) {
                if (n < tempLBlocks[this.state.renderType][inLimits][0]) {
                    if (tempLBlocks[this.state.renderType][inLimits][0] < 0) {
                        n = 0
                        m = "0"
                    } else {
                        n = tempLBlocks[this.state.renderType][inLimits][0]
                        m = String(tempLBlocks[this.state.renderType][inLimits][0])
                    }
                } else if (n > tempLBlocks[this.state.renderType][inLimits][1]) {
                    n = tempLBlocks[this.state.renderType][inLimits][1]
                    m = String(tempLBlocks[this.state.renderType][inLimits][1])
                }
            } else if (inCust in tempLBlocks[this.state.renderType]) {
                n = tempLBlocks[this.state.renderType].boundRef(n)
                m = String(n)
            }
            if (tempLBlocks[this.state.renderType][inType] === 'int') {
                tempLBlocks[this.state.renderType].control[inName] = Math.floor(n);
                m = String(Math.floor(n))
                tempLBlocks[this.state.renderType].code = tempLBlocks[this.state.renderType].codeRef(tempLBlocks[this.state.renderType].control);
            } else {
                tempLBlocks[this.state.renderType].control[inName] = n;
                tempLBlocks[this.state.renderType].code = tempLBlocks[this.state.renderType].codeRef(tempLBlocks[this.state.renderType].control);
            }
        } else {
            if (inLimits in tempLBlocks[this.state.renderType]) {
                if (tempLBlocks[this.state.renderType][inLimits][0] < 0) {
                    m = "0"
                } else {
                    m = String(tempLBlocks[this.state.renderType][inLimits][0])
                }
            } else {
                m = "0"
            }
            tempLBlocks[this.state.renderType].code = tempLBlocks[this.state.renderType].codeRef(tempLBlocks[this.state.renderType].control);
        }
        
        this.setState( {LBlocksOptions: tempLBlocks, [name]: m} );
        this.props.handleSubState(tempLBlocks[this.state.renderType]);
    }

    handleIn = (event) => {
        let m = event.target.value;
        this.setState( {[event.target.name]: m} );
    }
    
    handleBtn = (num) => {

        let numStr = String(num);
        let btnMap = 'btn' + numStr + 'map'
        let btnOptions = 'btn' + numStr + 'Options'
        let btnName = 'btn' + numStr
        let btnText = 'btn' + numStr + 'Txt'

        let tempLBlocks = this.state.LBlocksOptions;

        let nowIndex = tempLBlocks[this.state.renderType][btnOptions].indexOf(tempLBlocks[this.state.renderType].control[btnText])
        nowIndex += 1
        if (nowIndex >= tempLBlocks[this.state.renderType][btnOptions].length) {
            nowIndex = 0
        }
        tempLBlocks[this.state.renderType].control[btnName] = 
            tempLBlocks[this.state.renderType][btnMap][tempLBlocks[this.state.renderType][btnOptions][nowIndex]];

        if (btnMap in tempLBlocks[this.state.renderType]) {
            tempLBlocks[this.state.renderType].control[btnText] = tempLBlocks[this.state.renderType][btnOptions][nowIndex];
            this.setState( {[btnText]: tempLBlocks[this.state.renderType].control[btnText]} );
        } else {
            this.setState( {[btnText]: tempLBlocks[this.state.renderType].control[btnName]} );
        }
        tempLBlocks[this.state.renderType].code = tempLBlocks[this.state.renderType].codeRef(tempLBlocks[this.state.renderType].control);
        
        this.setState( {LBlocksOptions: tempLBlocks} );
        this.props.handleSubState(tempLBlocks[this.state.renderType]);
    }

    handleSelect = (event) => {

        let hName = event.target.name;
        let hText = hName + 'Txt';
        let hMap = hName + 'map';

        let tempLBlocks = this.state.LBlocksOptions;
        if (hMap in tempLBlocks[this.state.renderType]) {
            tempLBlocks[this.state.renderType].control[hName] = tempLBlocks[this.state.renderType][hMap][event.target.value];                
            tempLBlocks[this.state.renderType].control[hText] = event.target.value;

            this.setState( {[hText]: tempLBlocks[this.state.renderType].control[hText]} );
        }
        
        tempLBlocks[this.state.renderType].code = tempLBlocks[this.state.renderType].codeRef(tempLBlocks[this.state.renderType].control);
        
        this.setState( {LBlocksOptions: tempLBlocks} );
        this.props.handleSubState(tempLBlocks[this.state.renderType]);
    }

    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Box 
                    height={this.state.LBlocksOptions[this.state.renderType].height}
                    style={{backgroundColor: this.state.LBlocksOptions[this.state.renderType].color}}
                >
                    {this.state.LBlocksOptions[this.state.renderType]?.title ? (
                        <Typography 
                            className={classes.titleText}
                            variant='h4'
                            style={{backgroundColor: this.state.LBlocksOptions[this.state.renderType].color}}
                        >
                            {this.state.LBlocksOptions[this.state.renderType].title}
                        </Typography>
                    ) : (
                        <div style={{ paddingTop: '8px' }}></div> // Add padding when there is no title
                    )}
                    {((this.state.renderType === 'iloop') || 
                        (this.state.renderType === 'ipause')) && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{
                                    width: this.state.LBlocksOptions[this.state.renderType].inWidth,
                                  }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        </Grid>
                    )}
                    {(this.state.renderType === 'iaction') && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        </Grid>
                    )}
                    {((this.state.renderType === 'ipose') || 
                        (this.state.renderType === 'itravel')) && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{
                                        width: this.state.LBlocksOptions[this.state.renderType].inWidth,
                                    }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>
                        </Grid>
                    )}

                </Box>
            </React.Fragment>
        );
    }
}

export default withStyles(styles)(LocoHexBlocks);