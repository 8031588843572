// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';

import GeneralMessage from "../drawers/GeneralMessage"



// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      position: 'absolute',
      width: 500,//400,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      borderRadius: '15px',
      square: false,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      overflow: 'visible',
      maxHeight: '375px',
    },
    actionButton: {
      //justify: "center",
      borderRadius: '15px',
      margin: theme.spacing(0),
      color: "white",
      '&:hover': {
        backgroundColor: theme.palette.primary.main, // hover color
        color: "black", // text color on hover
      },
    },  
    cancelButton: {
        borderRadius: '15px',
        marginTop: theme.spacing(-0.5),
        color: "white",
        '&:hover': {
            backgroundColor: theme.palette.primary.main, 
            color: "black", 
        },
    },
    titleText: {
      color: "white",
      margin: theme.spacing(1),
    },
    lightIndices: {
        borderRadius: '70%',
        width: '25px',
        height: '25px',
    }, 
    inputField: {
        width: "90%",
        marginLeft: theme.spacing(2),
    },
    grid: {
        margin: theme.spacing(0),
        justify: "space-between",
        justifyContent: "space-between",
    },
    
});

// Constants for Modal Top Text
const STATE_TEXT_OPTS = ["Scan", "Connecting", "Scanning...", "Select"];
const STATE_TEXT_ERRS = ["No Devices Found, Try Scanning Again", "Could Not Connect, Try Again"];

const FLOW_STATES = {
    IDLE: 0,
    CONNECTED: 1,
}

export const MODAL_TYPE = {
    SELECT: 0,
    COPY: 1,
}

// Component Class
class ButtonsListModal extends React.Component {

    // Class constructor
    constructor(props) {
        // Access to this.props
        super(props);


        this.drawerMessage = React.createRef();
    }

    
    // Class state
    state = {
        isOpen: false,  // Modal display state 
        LRRef: null,    // LR Object Reference
        stateMessage: STATE_TEXT_OPTS[0], // Top message in modal
        flowState: FLOW_STATES.IDLE,   // Flow state for modal
        disable_for_scan: false, // Flag for scanning state to control buttons enable states
        names: [], // List of names found during scan
        type: MODAL_TYPE.SELECT, // Modal type
    };

    resetState = () => {
        this.setState({
            isOpen: false,
            LRRef: null,
            stateMessage: STATE_TEXT_OPTS[0],
            flowState: FLOW_STATES.IDLE,
            disable_for_scan: false, // Flag for scanning state to control buttons enable states
            names: [], // List of names found during scan
            type: MODAL_TYPE.SELECT, // Modal type
        });
    }

    handleOpen = (LRStr, type) => {
        if (!Object.values(MODAL_TYPE).includes(type)) {
            type = MODAL_TYPE.SELECT;
        }

        const LRRef = window.shared[LRStr + 'Obj'];

        this.setState({isOpen: true, LRRef: LRRef, type: type});
    }

    handleClose = (event) => {

        this.resetState();
    }

    handleManualClose = async (name) => {

        if (this.state.type === MODAL_TYPE.SELECT) {
            if (name !== "") {
                this.props.onClose(name);
            }
        }

        await this.state.LRRef.disconnect_quick();

        this.handleClose();
    }

    handleCancel = async () => {
        
        await this.state.LRRef.disconnect_quick();

        this.handleClose();
    }

    // webserial connection process
    handleSetup = async () => {        
        //if (Object.keys(window.serialport).length === 0) {
        try {
            this.setState( {disable_for_scan: true, stateMessage: STATE_TEXT_OPTS[1]} );
            const setupComplete = await this.state.LRRef.setup()
            if (setupComplete === -1) {
                this.setState( {stateMessage: STATE_TEXT_OPTS[0], disable_for_scan: false} );
                return
            }
            await this.handleScan()
        } catch (err) {
            this.setState( {stateMessage: STATE_TEXT_OPTS[0], disable_for_scan: false} );
            return
        }
    }

    handleScan = async () => {
        // Update state and wait for scan to complete
        this.setState( {stateMessage: STATE_TEXT_OPTS[2]} );
        await this.state.LRRef.scan();
        
        // Get scan data and update state if found or not
        let namesTemp = [...this.state.LRRef.robotData['scanData']]
        if (namesTemp.length !== 0) {
            this.setState( {flowState: FLOW_STATES.CONNECTED, stateMessage: STATE_TEXT_OPTS[3]} )
        } else {
            this.setState( {stateMessage: STATE_TEXT_ERRS[0]} )
        }
        // Update state for scan complete and any found
        this.setState( {disable_for_scan: false, names: namesTemp} )
    }

    handleBtn = (event, name) => {
        // If scan isn't active
        if (this.state.disable_for_scan === false) {
            if (this.state.type === MODAL_TYPE.SELECT) {
                this.handleManualClose(name);
            } else if (this.state.type === MODAL_TYPE.COPY) {
                let quoteName = "\"" + name + "\""
                // Copy to Clipboard
                navigator.clipboard.writeText(quoteName)        
                // Drawer String
                let msgStr = "Copied drone named " + quoteName + " to the clipboard"        
                // Open Temporary Drawer (pass name)
                this.drawerMessage.current.handleOpen("green", msgStr, 1000)    
            }
        }
    }

    // For opening drawer message
    handleMessage = (msgStr, timeSpan) => {
        // Open Temporary Drawer 
        this.drawerMessage.current.handleOpen("red", msgStr, timeSpan)
    }
    // render HTML
    render() {
    
        // Referenced below for setting styles
        const { classes } = this.props;

        // What to Display
        return (
            <div className={classes.root}>
                <Modal
                    className={classes.modal}
                    open={this.state.isOpen}
                    onClose={this.handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                <Fade in={this.state.isOpen}>
                    <div className={classes.paper}>
                        <Grid
                            spacing = {1}
                            direction="row"
                            className={classes.grid}
                            container
                        >
                        <Grid item xs={9}>
                            <Typography>
                                {this.state.stateMessage}
                            </Typography> 
                        </Grid>
                        <Grid item xs={3} style = {{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.cancelButton}
                                onClick={this.handleCancel}
                                disabled={this.state.disable_for_scan}
                            >
                                Cancel
                            </Button>  
                        </Grid>
                        {(this.state.flowState === FLOW_STATES.IDLE) && (
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.handleSetup}
                                disabled={this.state.disable_for_scan}
                            >
                                Scan
                            </Button>  
                        )}
                        {(this.state.flowState === FLOW_STATES.CONNECTED) && (    
                            <Grid item xs={12} style = {{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Paper
                                style = {{overflowY: 'auto', overflowX: 'hidden', maxHeight: '275px'}}
                            >
                            {this.state.names.map((r) => 
                                <Card 
                                    style = {{border: '1px solid black', marginTop: '5px', width: '325px'}}
                                    key = {r}
                                >
                                <Grid 
                                    container 
                                    spacing={1}
                                    className={classes.grid}
                                    direction="row"
                                    align = "center" justifyContent = "center"
                                    key = {'rg' + r}
                                    style = {{backgroundColor: '#34495e'}}
                                >
                                    <Grid item xs={12}
                                        onClick={(e) => this.handleBtn(e, r)}
                                        key = {'r' + r}
                                    >
                                        <Typography
                                            className={classes.titleText}
                                            key = {'n' + r}
                                        > 
                                            {r}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                </Card>
                            )}
                            </Paper>
                            </Grid>
                        )}
                        </Grid>
                    </div>
                </Fade>
                </Modal>
                <GeneralMessage
                    ref = {this.drawerMessage}
                    onClose = {() => void 0}
                />
            </div>
        );
    }
};

export default withStyles(styles)(ButtonsListModal);
