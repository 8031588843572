import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
//import axios from 'axios';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { AuthContext } from "../context/auth";
import { Typography } from "@material-ui/core";

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: 'white',
        maxHeight: '100vh',
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    paper: {
        padding: theme.spacing(4),
        textAlign: "center",
        justify: "center",
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
        minHeight: '500px', // Set a maximum height for the card area
        maxHeight: '500px', // Set a maximum height for the card area
        maxWidth: 400,
        alignItems: "center",
        marginTop: theme.spacing(4), // Add margin above the card to shift it down
    },
    form: {
        textAlign: "center",
        justify: "center",
        alignItems: "center",
    },
    grid: {
        //padding: theme.spacing(2),
    },
    actionButton: {
        fontSize:'18px',
        type: "submit",
        justify: "center",
        margin: theme.spacing(2),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
        width: 200,
        transition: 'background-color 0.2s, color 0.2s, transform 0.2s', // Add transition for smooth effect
        '&:hover': {
            backgroundColor: theme.palette.primary.dark, // Change background color on hover
            color: theme.palette.primary.contrastText, // Change text color on hover
            transform: 'scale(1.1)', // Scale up slightly on hover
        },
    },
    input: {
        justify: "center",
        margin: theme.spacing(2),
        color: theme.palette.primary["contrastText"],
        backgroundColor: theme.palette.primary["light"],
        '& .MuiOutlinedInput-root': {
            borderRadius: '15px', // Increase the border radius
            '& fieldset': {
                borderColor: theme.palette.primary.dark, // Default border color
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.green, // Border color on hover
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.dark, // Border color when focused
            },
        },
    },
    title: {
        color: theme.palette.primary["contrastText"],
        backgroundColor: theme.palette.primary["light"],
        paddingBottom: theme.spacing(2),
    },
    inline: {
      display: "inline"
    },
    buttonProgress: {
      color: "white",
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: 40,
      marginLeft: -12,
    },
  });


// Component Class - Login
class Login extends Component {

    constructor(props) {
        super(props);

        //this.postLogin.bind(this);
        this.handleLoginAttempt.bind(this);

        
    }

    static contextType = AuthContext;

    state = {
        isLoggedIn: false,
        isError: false,
        username: '',
        password: '',
        helperText: ' ',
        loading: false,
    }

    enterFunction = (event) => {
        if(event.keyCode === 13) {
          this.handleLoginAttempt();
        }
      }

    componentDidMount() {
        document.addEventListener("keydown", this.enterFunction, false);        
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.enterFunction, false);
    }



    handleLoginAttempt = () => {
        this.setState( {loading: true} );
        this.context.AuthInstance.login(this.state.username,this.state.password)
            .then(res =>{
                return this.handleLoginSuccess();
            })
            .then(() => {
                return this.context.AuthInstance.getProgramList()
            })
            .then(() => {
                this.setState( {loading: false} );
                this.props.history.replace('/program');
            })
            .catch(err =>{
                //alert(err);
                this.setState( {isError: true, helperText: 'Username or password is incorrect!'} );
                this.setState( {loading: false} );
            });/*.then(() => {
                // remove this "then" when not cheating
                this.handleLoginSuccess();
                this.setState( {loading: false} );
                this.props.history.replace('/');
                this.context.AuthInstance.authTokens = true;
                this.context.AuthInstance.userName = "username";
            });*/
    }

    handleLoginSuccess = async () => {
        return this.context.AuthInstance.getProductAccess();
    };

    handleProgramList = () => {
        this.context.AuthInstance.getProgramList().then(() => {
            return Promise.resolve("done");
        });
    }



    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction="column"
                    alignItems="center"
                    className={classes.grid}
                >
                    <Grid>

                    <Card 
                        className={classes.paper}
                        style={{borderRadius: 20}}
                    >
                        <CardMedia
                            component="img"
                            image={require("../images/logo.png")}
                            title="LocoRobo"
                        />
                        <CardContent>
                            <FormControl className={classes.form}>
                                <Typography
                                    className={classes.title}
                                    variant='h4'
                                >
                                    LocoRobo Academy Login
                                </Typography>
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    type="username"
                                    value={this.state.username}
                                    onChange={e => {
                                        this.setState( {helperText: ' ', username:  e.target.value} );
                                    }}
                                    placeholder="Username"
                                />
                                <TextField
                                    className={classes.input}
                                    variant="outlined"
                                    type="password"
                                    value={this.state.password}
                                    onChange={e => {
                                        this.setState( {helperText: ' ', password:  e.target.value} );
                                    }}
                                    placeholder="Password"
                                />
                                <Button 
                                    onClick={this.handleLoginAttempt}
                                    className={classes.actionButton}
                                    disabled={this.state.loading}
                                    style={{borderRadius: 20}}
                                >
                                    Sign In
                                </Button>
                                <FormHelperText 
                                    style={{color: "firebrick", 
                                            fontSize: '15px', 
                                            textAlign: "center", 
                                            fontWeight:"bold"
                                        }}
                                >
                                    {this.state.helperText}
                                </FormHelperText>
                                {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </FormControl>
                        </CardContent>
                    </Card>
                        
                </Grid>
                </Grid>
            </div>
        );

    }
}

export default withStyles(styles)(Login);
