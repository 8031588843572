
// Import React and Material-UI Modules
import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
//import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from "@material-ui/core/Select";
import MenuItem from '@material-ui/core/MenuItem';

import { AuthContext } from "../context/auth";

// Material-UI CSS-type Style Specifications
const styles = theme => ({
    root: {
      flexGrow: 1,
      backgroundColor: "white",
      color: theme.palette.primary["light"],
      overflow: "hidden",
      backgroundSize: "cover",
      backgroundPosition: "0 400px",
      paddingBottom: 200,
      margin: theme.spacing(0),
    },  
    inputLabel: {
        margin: theme.spacing(1.3),
        //marginLeft: theme.spacing(1),
        color: theme.palette.primary["light"],
        "&.Mui-focused": {
            color: theme.palette.primary["dark"],
        }
    },
    inputAltLabel: {
        marginTop: theme.spacing(1.0),
    },
    formAltControl: {
        marginTop: theme.spacing(0.5),
    },
    inputField: {
        color: theme.palette.primary["light"],
        margin: theme.spacing(0.7),
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.palette.primary["light"]
        },
        "&.MuiOutlinedInput-root": {
          "fieldset": {
            borderColor: theme.palette.primary["light"]
          },
          "&:hover fieldset": {
            borderColor: theme.palette.primary.lighter,
          },
          "&.Mui-focused fieldset": {
            borderColor: theme.palette.primary["dark"],
          },
        },
    },
    actionButton: {
      justify: "right",
      margin: theme.spacing(1),
      transition: 'transform 0.2s', // Add transition for smooth scaling
      "&:hover": {
          transform: 'scale(1.1)', // Scale up slightly on hover
      },
    },
    inline: {
        display: "inline"
    },
    flex: {
        display: 'flex',
        margin: theme.spacing(0.5),
    },
    selectOptions: {
        fontSize: '20px',
        "& .MuiSelect-root": {
            "&:hover": {
                backgroundColor: theme.palette.primary.lighter, // Apply primary lighter color on hover
            },
        },
        '&:hover': {
            backgroundColor: theme.palette.primary.lighter, // Apply primary lighter color on hover
        },
    }
});


let TYPE_OPTS = [
    'All',/*
    'LocoXtreme',
    'LocoDrone',
    'LocoDroneT',
    'PythonOnly',
    'LocoWear',
    'LocoArm',
    'LocoArmS',
    'LocoArmM',
    'LocoArmMS',
    'LocoIoT',
    'LocoArmMini',*/
];

class ProgramSearch extends Component {

    
    static contextType = AuthContext;
    
    // 
    state = {
        filtername: '',
        productType: TYPE_OPTS[0],
    }

    componentDidMount() {
        if (TYPE_OPTS.length == 1) {
            for (let k = 0; k < this.context.AuthInstance.userAccess.length; k++) {
                TYPE_OPTS.push(this.context.AuthInstance.userAccess[k])
            }
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    applyFilter = () => {
        this.props.updateSearch(this.state.filtername, this.state.productType)
    }

    clearFilter = () => {
        this.setState( {filtername: '', productType: TYPE_OPTS[0]} )
        this.props.updateSearch('', "All")
    }

    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
                <div className={classes.flex}>
                    <FormControl fullWidth className={classes.form}>
                        <InputLabel shrink className={classes.inputLabel} classes = {{ focused: classes.inputFocused }}>Search Programs by Name</InputLabel>
                        <OutlinedInput className={classes.inputField}
                            id="standard-adornment-pn"
                            aria-describedby="standard-pn-helper-text"
                            inputProps={{
                                'aria-label': 'pn',
                            }}
                            name = "filtername"
                            value = {this.state.filtername}
                            onChange = {this.handleChange}
                            autoComplete="off"
                        />
                    </FormControl>
                    <FormControl
                        fullWidth
                        variant="outlined"
                        className={classes.formAltControl}
                    >
                    <InputLabel shrink className={classes.inputAltLabel} classes = {{ focused: classes.inputFocused }}>Product Type</InputLabel>
                    <Select
                        value={this.state.productType}
                        onChange={this.handleChange}
                        aria-describedby="progtype-pn-helper-text"
                        className={classes.selectOptions}
                        input={
                        <OutlinedInput
                            name="productType"
                        />
                        }
                    >
                        {TYPE_OPTS.map((val) => {
                            return (
                                <MenuItem key={val} value={val} className={classes.selectOptions}>
                                    {val}
                                </MenuItem>
                            );
                        })}
                    </Select>       
                    </FormControl>
                    <Button
                        name="applyFilterBtn"
                        color="primary"
                        variant="contained"
                        className={classes.actionButton}
                        onClick={this.applyFilter}
                    >
                        Apply
                    </Button>
                    <Button
                        name="clearFilterBtn"
                        color="primary"
                        variant="contained"
                        className={classes.actionButton}
                        onClick={this.clearFilter}
                    >
                        Clear
                    </Button>
                    <FormControl/>
                </div>
        );
    }
}



export default withStyles(styles)(ProgramSearch)