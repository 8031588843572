
import { Commander, MotionCommander } from "./LCCommanders";
import { CRTPPacket, CRTPPort, DR_2MPS, mapValue, RadioLink } from "./LCExtra";
import { Log, LogConfig, LogTocElement, CHAN_LOGDATA } from "./LCLogging";
import { log_toc_cached } from "./LogTocCache";
import { param_toc_cached } from "./ParamsTocCache";
import { Param } from "./LCParams";
import { Memory, MemoryElement } from "./LCMemories";


export const LC_RCHAN_MIN = 0;
export const LC_RCHAN_MAX = 125;
export const LC_DEF_RCHAN = 80;
export const LC_DEF_RADDR = "E7E7E7E7E7";

const THREAD_INTERVAL = 25; // 50; // ms

const DATA_STREAM_MS = 200; // 250; // ms

class LocoCraze {
    constructor() {

        this.link = new RadioLink(true);
        this.elementClass = LogTocElement;
        this.log = new Log(this);
        this.param = new Param(this);
        this.commander = new Commander(this);
        this.motionCommander = new MotionCommander(this);
        this.mem = new Memory(this);

        this.protocolVersion = -1;
        this.needsResending = true;
        this.hasSafelink = false;
        this.retryBeforeDisconnect = 10;
        this.rateLimit = null;
        this.timeoutId = null;
        this._sp = false;

        this.answerPacket = null;
        this.dataOut = new Uint8Array([0xFF]);
        this.outPacket = null;
        this.waitTime = 0;
        this.emptyCtr = 0;
        this.ackStatus = null;
        this.useV2 = false;

        this.nbr_of_items = null;
        this._crc = 0;
        this.toc_requested_index = 0;
        this.toc = null;

        this._lg_stab = null;
        
        this.matchingPacketFlag = null;
        this.singleSend = false;
        
        this.MOVE_DIRECTIONS = {
            MOVE_UP: 0,
            MOVE_DOWN: 1,
            MOVE_LEFT: 2,
            MOVE_RIGHT: 3,
            MOVE_FORWARD: 4,
            MOVE_BACK: 5
        }
        this.YAW_DIRECTIONS = {
            YAW_CW: 0,
            YAW_CCW: 1
        };

        this.CIRCLE_DIRECTIONS = {
            CIRCLE_LEFT: 0,
            CIRCLE_RIGHT: 1
        }

        this.moveFunctionMap = {
            [this.MOVE_DIRECTIONS.MOVE_UP]: this.motionCommander.up,
            [this.MOVE_DIRECTIONS.MOVE_DOWN]: this.motionCommander.down,
            [this.MOVE_DIRECTIONS.MOVE_LEFT]: this.motionCommander.left,
            [this.MOVE_DIRECTIONS.MOVE_RIGHT]: this.motionCommander.right,
            [this.MOVE_DIRECTIONS.MOVE_FORWARD]: this.motionCommander.forward,
            [this.MOVE_DIRECTIONS.MOVE_BACK]: this.motionCommander.back,
        }
        this.yawFunctionMap = {
            [this.YAW_DIRECTIONS.YAW_CW]: this.motionCommander.turn_right,
            [this.YAW_DIRECTIONS.YAW_CCW]: this.motionCommander.turn_left,
        }

        this.circleFunctionMap = {
            [this.CIRCLE_DIRECTIONS.CIRCLE_LEFT]: this.motionCommander.circle_left,
            [this.CIRCLE_DIRECTIONS.CIRCLE_RIGHT]: this.motionCommander.circle_right,
        }

        this.YAW_RATE_LIM = {MIN: 36, MAX: 144} // degrees/s
        this.YAW_ANGLE_RANGE = {MIN: 1, MAX: 360} // degrees
        this.RC_IN_RANGE = {MIN: -100, MAX: 100} // range of values for RC commands
        this.VEL_BOUNDS = {MIN: 0.1, MAX: 0.3} // m/s
        this.DISTANCE_BOUNDS = {MIN: 0.0, MAX: 2.0} // meters
        
        this.COMPLETE_RES = "OK"
        this.FAILED_RES = "FAILED"

        this.gravity = 9.81; // m/s^2

        this.DATA_TYPES = {
            ATTITUDE: "ATTITUDE",
            POSITION: "POSITION",
            BATTERY: "BATTERY",
        }

        this.x = 0; this.y = 0; this.z = 0; // m
        this.accx = 0; this.accy = 0; this.accz = 0; // g
        this.roll = 0; this.pitch = 0; this.yaw = 0; // degrees
        this.tof = 0; // m
        this.flight_time = 0; // s
        this.vbat = 0; // V
        this.isFlying = false;



        this.bufferSize = 100;

        this.optionNames = [
            'Attitude', 'Accelerometer', 'Battery'
        ];

        this.options = {
            'Attitude': {refer: 'READ_ATT', count: 3, range: ['min', 'min', 'min'], offset: 2},
            'Accelerometer': {refer: 'READ_ACC', count: 3, range: ['min', 'min', 'min'], offset: 1.2},
            'Battery': {refer: 'READ_BATTERY', count: 1, range: ['fixed'], fixed: [2, 5]},
        };
        
        this.self = {
            READ_ATT: {
                varref: ['roll', 'pitch', 'yaw'],
                names: ['Roll', 'Pitch', 'Yaw'],
                ylabels: ["Roll (deg)", "Pitch (deg)", "Yaw (deg)"],
                arrays: ['rArray', 'pArray', 'yArray'],
            },
            READ_ACC: {
                varref: ['accx', 'accy', 'accz'],
                names: ['Acc X', 'Acc Y', 'Acc Z'],
                ylabels: ["X (g)", "Y (g)", "Z (g)"],
                arrays: ['accArrayX', 'accArrayY', 'accArrayZ'],
            },
            READ_BATTERY: {
                varref: ['vbat'],
                names: ['Battery'],
                ylabels: ["Battery (V)"],
                arrays: ['batArray'],
            },
            rArray: [],
            pArray: [],
            yArray: [],
            accArrayX: [],
            accArrayY: [],
            accArrayZ: [],
            batArray: [],
        };


    }


    resetArrays = () => {
        this.self.rArray = []
        this.self.pArray = []
        this.self.yArray = []
        this.self.accArrayX = []
        this.self.accArrayY = []
        this.self.accArrayZ = []
        this.self.batArray = []
    }
    get_data_array = (type) => {
        return type.map(name => this.self[name]);
    };
    set_buffer_size = (buffer_size) => {
        this.bufferSize = buffer_size
    }
    get_buffer_size = () => {
        return this.bufferSize
    }
    send_command = (ref_obj) => { // for data vis, just get latest values and put in arrays
        
        const {varref, arrays} = ref_obj;
        // Create shallow copies
        const arraysCopy = [...arrays];
        const varrefCopy = [...varref];

        // ex arrays: arrays: ['rArray', 'pArray', 'yArray'],
        for (let i = 0; i < varrefCopy.length; i++) {

            const val = this[varrefCopy[i]];
            this.self[arraysCopy[i]].push(val);
            if (this.self[arraysCopy[i]].length > this.bufferSize) {
                this.self[arraysCopy[i]].shift();
            }

        }
    }
    

    get_attitude = () => {
        return [this.roll, this.pitch, this.yaw];
    }
    get_position = () => {
        return [this.x, this.y, this.z];
    }
    get_battery = () => {
        return this.vbat;
    }

    drone_takeoff = async() => {
        console.log("starting takeoff")
        try {
            await this.motionCommander.takeoff();
            // wait 2 seconds
            await new Promise((resolve) => setTimeout(resolve, 2000));
            console.log("takeoff complete");
            return this.COMPLETE_RES;
        } catch (error) {
            return this.FAILED_RES;
        }
    };

    drone_land = async() => {
        console.log("starting land")
        try {
            await this.motionCommander.land();
            // wait 2 seconds
            await new Promise((resolve) => setTimeout(resolve, 2000));
            return this.COMPLETE_RES;
        } catch (error) {
            return this.FAILED_RES;
        }
    };

    drone_move = async(direction, distance, velocity = null) => {

        // convert distance from cm to m
        // convert velocity from cm/s to m/s
        distance = distance / 100;
        velocity = velocity ? velocity / 100 : null;

        // bound values
        distance = Math.min(this.DISTANCE_BOUNDS.MAX, Math.max(this.DISTANCE_BOUNDS.MIN, distance));
        velocity = velocity ? Math.min(this.VEL_BOUNDS.MAX, Math.max(this.VEL_BOUNDS.MIN, velocity)) : null;

        // Get Function Based on Direction
        const func = this.moveFunctionMap[direction];
        if (func) {
            // Assemble args
            const args = [distance];
            if (velocity !== null) {

                args.push(velocity);
            }
            try {
                // Call the function
                await func.apply(this, args);
                return this.COMPLETE_RES;
            } catch (error) {
                return this.FAILED_RES;
            }
        } else {
            return this.FAILED_RES;
        }
    };

    drone_yaw = async(direction, angle_degrees, rate = null) => {

        // bound rate if not null
        if (rate !== null) {
            rate = Math.min(this.YAW_RATE_LIM.MAX, Math.max(this.YAW_RATE_LIM.MIN, rate));
        }

        // Get Function Based on Direction
        const func = this.yawFunctionMap[direction];
        if (func) {
            // Assemble args
            const args = [angle_degrees];
            if (rate !== null) {
                args.push(rate);
            }
            // Call the function
            try {
                await func.apply(this, args);
                return this.COMPLETE_RES;
            } catch (error) {
                return this.FAILED_RES;
            }
        } else {
            return this.FAILED_RES;
        }
    };

    drone_go = async(xyz, velocity = null) => {
        // convert xyz from cm to m
        // convert velocity from cm/s to m/s
        xyz = xyz.map((val) => val / 100);
        velocity = velocity ? velocity / 100 : null;
        // bound values
        xyz = xyz.map((val, idx) => Math.min(this.DISTANCE_BOUNDS.MAX, Math.max(this.DISTANCE_BOUNDS.MIN, val)));
        velocity = velocity ? Math.min(this.VEL_BOUNDS.MAX, Math.max(this.VEL_BOUNDS.MIN, velocity)) : null;
        
        console.log("starting go", xyz[0], xyz[1], xyz[2])
        try {
            if (velocity !== null) {
                await this.motionCommander.move_distance(xyz[0], xyz[1], xyz[2], velocity);
            } else {
                await this.motionCommander.move_distance(xyz[0], xyz[1], xyz[2]);
            }
            return this.COMPLETE_RES;
        } catch (error) {
            return this.FAILED_RES;
        }
    }

    drone_rc = async(roll, pitch, yawrate, thrust) => {
        
        // Map the values
        const mappedRoll = mapValue(
            roll, this.RC_IN_RANGE.MIN, this.RC_IN_RANGE.MAX, -15, 15
        );
        const mappedPitch = mapValue(
            pitch,this.RC_IN_RANGE.MIN, this.RC_IN_RANGE.MAX, -15, 15
        );
        const mappedYawrate = mapValue(
            yawrate, this.RC_IN_RANGE.MIN, this.RC_IN_RANGE.MAX, -72, 72
        );
        const mappedThrust = mapValue(
            thrust, this.RC_IN_RANGE.MIN, this.RC_IN_RANGE.MAX, 18000, 60000
        );

        // Pass the mapped values to send_setpoint
        await this.commander.send_setpoint(mappedRoll, mappedPitch, mappedYawrate, mappedThrust);
        return this.COMPLETE_RES;
    };

    drone_hover = async() => {

        //send_hover_setpoint = (vx, vy, yawrate, zdistance)

        await this.commander.send_hover_setpoint(0, 0, 0, this.motionCommander.default_height);
        return this.COMPLETE_RES;
    };

    
    drone_circle = async(
        circle_direction, radius_m, angle_degrees, velocity = null
    ) => {

        // Get Function Based on Direction
        const func = this.circleFunctionMap[circle_direction];
        if (func) {
            // Assemble args
            let args;
            if (velocity !== null) {
                args = [radius_m, velocity, angle_degrees];
            } else {
                args = [radius_m, undefined, angle_degrees];
            }
            // Call the function
            await func.apply(this, args);
            return this.COMPLETE_RES;
        } else {
            return this.FAILED_RES;
        }
    };

    usb_connect = async () => {

        // Initialize the radio
        const res = await this.link.initRadio();
        return res;
    };

    usb_disconnect = async () => {
        await this.link.disconnectDevice();
    };

    drone_scan = async(hexAddr) => {

        const results = await this.link.scanChannels(hexAddr);

        return results;
    };

    connect = async(hexAddr, channel) => { // for data vis

        // 
        try {
            const resusb = await this.usb_connect(hexAddr);
            if (!resusb) {
                return resusb;
            }
            
            const res = await this.drone_connect(hexAddr, channel);
            return res;

        } catch (err) {
            return false;
        }
    }

    drone_connect = async(hexAddr, channel) => {  
        
        this._sp = false;

        //const channel = 80;
        const dataRate = DR_2MPS;

        const address = parseInt(hexAddr, 16);

        await this.link.setRadioChannel(channel);
        await this.link.setDataRate(dataRate);

        await this.link.setAddress(address);
        await this.link.setArc(3);

        // Start the loop with a reasonable interval
        this.timeoutId = setTimeout(() => this.run(), THREAD_INTERVAL);

        const res = await this.postConnect();
        // return if false
        if (!res) {
            return res;
        }

        await new Promise((resolve) => setTimeout(resolve, 2000));

        // enable logging
        await this.enableLogging();
        console.log("Logging Enabled");
        return true;
    };

    disconnect = async() => {

        try {
            // disable logging
            await this.disableLogging();
        } catch (error) {
            //console.error('Error disabling logging:', error);
        }
        // wait 1 second
        await new Promise((resolve) => setTimeout(resolve, 1000));
        //console.log("after disable logging");
        // stop the main thread
        this.stop();
        // wait 1 second
        await new Promise((resolve) => setTimeout(resolve, 1000));
        //console.log("after stop and pause");
        try {
            // disconnect from usb
            await this.usb_disconnect();
        } catch (error) {
            //console.error('Error disconnecting from USB:', error);
        }
        // wait 1 second
        await new Promise((resolve) => setTimeout(resolve, 1000));
        //console.log("after usb disconnect");
    }



    start = async(hexAddr) => {

        // Initialize the radio
        await this.link.initRadio();

        const channel = 80;
        const dataRate = DR_2MPS;

        // Drone Default Address
        //const hexAddr = 'E7E7E7E7E7';
        const address = parseInt(hexAddr, 16);

        await this.link.setRadioChannel(channel);
        await this.link.setDataRate(dataRate);

        await this.link.setAddress(address);
        await this.link.setArc(3);

        // Try up to 10 times to enable the safelink mode
        /*
        for (let attempt = 0; attempt < 10; attempt++) {
            const resp = await this.sendPacket(new Uint8Array([0xff, 0x05, 0x01]));
            if (resp && resp.data && Array.from(resp.data).toString() === [0xff, 0x05, 0x01].toString()) {
                this.hasSafelink = true;
                this.currUp = 0;
                this.currDown = 0;
                console.log('Safe link enabled');
                break;
            }
        }
        */

        if (!this.hasSafelink) {
            this.needsResending = true;
        }

        // Start the loop with a reasonable interval
        this.timeoutId = setTimeout(() => this.run(), THREAD_INTERVAL);

        await this.postConnect();
    }

    stop = () => {
        // Stop the loop
        clearInterval(this.timeoutId);
        this._sp = true;
    }

    run = async() => {

        if (this._sp) {
            // stop the interval and exit
            clearInterval(this.timeoutId);
            return;
        }

        try {
            if (this.hasSafelink) {
                this.ackStatus = await this.link.sendPacketSafe(this.dataOut);
            } else {
                this.ackStatus = await this.link.sendPacket(this.dataOut);
            }
        } catch (error) {
            console.error('Error communicating with crazy radio:', error);
            return;
        }

        // if ackStatus ack is false
        //if (this.ackStatus.ack === false) {
        //    console.log('No ack received');
        //    this.link = null;
        //    return;
        //}
        // check if this.ackStatus is null
        if (this.ackStatus === null) {
            console.log("Could not read Ack status");
            return;
        }

        // uh
        if (this.ackStatus.retry !== undefined) {

        }

        // If no copter, retry
        if (!this.ackStatus.ack) {
            this.retryBeforeDisconnect -= 1;
            if (this.retryBeforeDisconnect === 0) {
                console.error('Too many packets lost');
            }
            return;
        }
        this.retryBeforeDisconnect = 10;

        const data = this.ackStatus.data;

        if (data instanceof Uint8Array && data.length > 0) {
            const header = data[0];
            const packetData = Array.from(data.slice(1));
            let inPacket = new CRTPPacket(header, packetData);
            //console.log(inPacket);
            // Check for the matching inPacket
            if (this.matchingPacketFlag && 
                Object.keys(this.matchingPacketFlag).length > 0 
                && inPacket) 
            {
                //console.log("here", inPacket.port, inPacket.channel, this.matchingPacketFlag.port, this.matchingPacketFlag.channel)
                //console.log(data[0]);
                if (inPacket.port === this.matchingPacketFlag.port && inPacket.channel === this.matchingPacketFlag.channel) {
                    //console.log("check passed")
                    this.matchingPacketFlag = null;
                    this.outPacket = null;
                    // pass inPacket to where it is waiting for it
                    this.handleInPacket(inPacket);
                }
            }
            if (inPacket.port === CRTPPort.LOGGING) {
                //console.log("here, logging packet", inPacket.channel, CHAN_LOGDATA)
                if (inPacket.channel === CHAN_LOGDATA) {
                    await this.log.handlePacket(inPacket);
                }
            }

            this.waitTime = 0;
            this.emptyCtr = 0;
          } else {
            this.emptyCtr += 1;
            if (this.emptyCtr > 10) {
                this.emptyCtr = 10;
                this.waitTime = 0.01;
            } else {
                this.waitTime = 0;
            }
        }

        // If there is a rate limit setup, sleep here to force the rate limit
        if (this.rateLimit) {
            await this.sleep(1000 / this.rateLimit);
            this.waitTime = 0;
        }

        if (this.outPacket) {
            this.dataOut = new Uint8Array([this.outPacket.header, ...this.outPacket.data]);
            //console.log('sending other packet')
            if (this.singleSend === true) {
                this.singleSend = false;
                this.outPacket = null;
            }
        } else {
            this.dataOut = new Uint8Array([0xFF]);
        }

        if (!this._sp) {
            this.timeoutId = setTimeout(() => this.run(), THREAD_INTERVAL);
        }
    }

    postConnect = async () => {
        // wait 
        await new Promise((resolve) => setTimeout(resolve, 2000)); // was 5000

        const setupFunctions = [
            this.getProtocolVersion.bind(this),
            this.setupLogToc.bind(this),
            this.setupParamsToc.bind(this),
        ]
        // Iterate over the setup functions
        for (const setupFunction of setupFunctions) {
            const res = await setupFunction();
            if (res === false) {
                this.stop();
                return false;
            }
        }
        return true;
    }

    enableLogging = async () => {

        this._lg_stab = new LogConfig('LogData', DATA_STREAM_MS) // name, periodInMs
        this._lg_stab.addVariable('stabilizer.roll', 'float')
        this._lg_stab.addVariable('stabilizer.pitch', 'float')
        this._lg_stab.addVariable('stabilizer.yaw', 'float')
        this._lg_stab.addVariable('stateEstimate.x', 'float')
        this._lg_stab.addVariable('stateEstimate.y', 'float')
        this._lg_stab.addVariable('stateEstimate.z', 'float')
        this._lg_stab.addVariable('pm.vbat', 'FP16')   
        // add the log config to the log
        this.log.add_config(this._lg_stab)

        await this._lg_stab.start()
    };

    disableLogging = async () => {

        await this._lg_stab.stop()
    };

    updateLogData = async(data) => {

        console.log(data);


    };

    logTest = async() => {

        this._lg_stab = new LogConfig('Stabilizer', 250) // name, periodInMs
        this._lg_stab.addVariable('stabilizer.roll', 'float')
        this._lg_stab.addVariable('stabilizer.pitch', 'float')
        this._lg_stab.addVariable('stabilizer.yaw', 'float')
        this.log.add_config(this._lg_stab)
        console.log('Log Config added');

        await this._lg_stab.start()

        // wait 10 seconds
        await new Promise((resolve) => setTimeout(resolve, 10000));
        await this._lg_stab.stop()

    }

    motorsTest = async() => {

        await this.commander.send_setpoint(0, 0, 0, 0);
        console.log("set intial 0s")
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await this.commander.send_setpoint(0, 0, 0, 10001);
        console.log("set low thrust")
        await new Promise((resolve) => setTimeout(resolve, 5000));
        await this.commander.send_setpoint(0, 0, 0, 0);
        console.log("reset to 0s")
        await new Promise((resolve) => setTimeout(resolve, 1000));

    }

    paramTest = async() => {

        //await this.param.request_param_update('firmware.revision0');
        //await this.param.getDefaultValue('kalman.initialX');
        let res = await this.param.request_param_update('kalman.initialX');
        console.log('initial value: ', res)
        res = await this.param.set_value('kalman.initialX', 2);
        console.log('ser response: ', res)
        res = await this.param.request_param_update('kalman.initialX');
        console.log('re-read value: ', res)

    };

    motionCommanderTest = async() => {
        await this.motionCommander.takeoff();
        await new Promise((resolve) => setTimeout(resolve, 4000));

        await this.motionCommander.right(0.3);
        
        await this.motionCommander.land();
        await new Promise((resolve) => setTimeout(resolve, 4000));
    };

    memTest = async() => {


        await this.mem.refresh();
        
        console.log(this.mem.mems);
        const mems = this.mem.get_mems(MemoryElement.TYPE_I2C);
        //console.log(mems);
        console.log(mems[0]);
        //const elements = await mems[0].update();
        //console.log("here");
        //console.log(elements);

        let elems = mems[0].elements;
        elems['version'] = 1
        elems['pitch_trim'] = 0.0
        elems['roll_trim'] = 0.0
        elems['radio_channel'] = 60//80
        elems['radio_speed'] = 2
        elems['radio_address'] = 0xE6E6E6E6E6//0xE7E7E7E7E7

        await mems[0].write_data();
        console.log("completed write");

        await new Promise((resolve) => setTimeout(resolve, 1000));

        const elements = await mems[0].update();
        console.log(elements);
        console.log("completed read");

    };

    handleInPacket = async(inPacket) => {
        //console.log("copying inpacket")
        // copy the inPacket to the answerPacket
        this.answerPacket = inPacket;
    }

    sendPacketAndWaitForResponse = async({pk, port, channel, timeout, resend} = {}) => {

        this.outPacket = pk;
        this.matchingPacketFlag = {port: port, channel: channel};
        this.answerPacket = null;

        let attempt = 0;
        let attempts = resend ? 3 : 1;

        while (attempt < attempts) {

            try {
                await this.waitForVariableOrTimeout(timeout);
                this.outPacket = null;
                return this.answerPacket;
            } catch (error) {
                if (resend && attempt < attempts) {
                    attempt += 1;
                    //console.log('Resending packet:', attempt, ", port: ", port, ", channel: ", channel);
                    continue;
                } else {
                    console.error('Error:', error.message);
                    this.outPacket = null;
                    //return;
                    throw("Failed to get response");
                }
            }
        }
    };

    waitForVariableOrTimeout(timeout = 5000) {
        return new Promise((resolve, reject) => {
            const checkInterval = 10; // Interval to check the variable
            let intervalId;
            const timeoutId = setTimeout(() => {
                clearInterval(intervalId); // Clear the interval if timeout occurs
                reject(new Error('Timeout waiting for variable to be set'));
            }, timeout);

            intervalId = setInterval(() => {
                if (this.answerPacket !== null) {
                    clearTimeout(timeoutId); // Clear the timeout if variable is set
                    clearInterval(intervalId); // Clear the interval
                    resolve(this.answerPacket);
                }
            }, checkInterval);
        });
    }

    sendAndNoReply = async(pk) => {

        this.outPacket = pk;
        this.singleSend = true;
    }
    

    getProtocolVersion = async() => {
        // Create a new CRTPPacket instance
        const pk = new CRTPPacket();
        // Set the header for the packet
        pk.set_header(CRTPPort.LINKCTRL, LINKSERVICE_SOURCE);
        // Set the data for the packet
        pk.data = new Uint8Array([0]);

        try {
            const inPacket = await this.sendPacketAndWaitForResponse({
                pk: pk, 
                port: CRTPPort.LINKCTRL, 
                channel: LINKSERVICE_SOURCE,
                timeout: 3000,
                resend: false,
            });
            
            // Check if Bitcraze Crazyflie is in the magic string
            const magicString = new TextDecoder('utf-8').decode(inPacket.data.slice(0, 18));
            if (magicString === 'Bitcraze Crazyflie') {
                const vpk = new CRTPPacket();
                vpk.set_header(CRTPPort.PLATFORM, VERSION_COMMAND);
                vpk.data = new Uint8Array([VERSION_GET_PROTOCOL]);
                const inPacket = await this.sendPacketAndWaitForResponse({
                    pk: vpk, 
                    port: CRTPPort.PLATFORM, 
                    channel: VERSION_COMMAND,
                    timeout: 3000,
                    resend: false,
                });
                this.protocolVersion = inPacket.data[1]
                //console.log('Protocol version:', this.protocolVersion);
                return true;
            } else {
                return false;
            }
        } catch (error) {
            console.error('Error:', error);
            return false;
        }
    }

    setupLogToc = async() => {
        try {
            // get log toc cache
            const log_toc_cache = JSON.parse(JSON.stringify(log_toc_cached));
            // run refresh logic (using cache in this case)
            await this.log.refresh_toc(this.protocolVersion, log_toc_cache);
            return true;
        } catch (error) {
            return false;
        }
    }

    setupParamsToc = async() => {
        try {
            // get param toc cache
            const param_toc_cache = JSON.parse(JSON.stringify(param_toc_cached));
            // run refresh logic (using cache in this case)
            await this.param.refresh_toc(this.protocolVersion, param_toc_cache);
            return true;
        } catch (error) {
            return false;
        }
    }

    
    


}



const PLATFORM_COMMAND = 0
const VERSION_COMMAND = 1
const APP_CHANNEL = 2
const PLATFORM_SET_CONT_WAVE = 0
const PLATFORM_REQUEST_ARMING = 1
const PLATFORM_REQUEST_CRASH_RECOVERY = 2
const VERSION_GET_PROTOCOL = 0
const VERSION_GET_FIRMWARE = 1
const LINKSERVICE_SOURCE = 1




export { LocoCraze };