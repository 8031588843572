import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import { AuthContext } from "../context/auth";

import CssBaseline from "@material-ui/core/CssBaseline";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import InputLabel from '@material-ui/core/InputLabel';

import DroneIcon from '@material-ui/icons/Flight';

import Topbar from "./Topbar";
import GeneralMessage from "./drawers/GeneralMessage";
import DroneCanvas from "./DroneCanvas";
import TextField from "@material-ui/core/TextField";
import LCInputsModal from "./modals/LCInputsModal";


const DRONE_VIEW_OPTS = ['LocoCraze', 'LocoDroneC'];
const NEEDS_RADIO_INFO = ['LocoCraze'];

const FRAME_INTV = 500; // Update the drone position

// Material-UI CSS-type Style Specifications
const styles = (theme) => {
    const actionButton = {
        justify: "center",
        margin: theme.spacing(1),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
        borderRadius: '25px',
        '&:hover': {
            backgroundColor: "darkgreen", // Change to a darker green or any other color
        },
    };

    return {
        root: {            
            flexGrow: 1,
            backgroundColor: "white",
        },
        accessDiv: {
            display: 'flex',
            maxHeight: `calc(100vh - ${window.navBarOffset})`, 
            minHeight: `calc(100vh - ${window.navBarOffset})`,
        },
        accessSelectDiv: {
            height: '100%'
        },
        lftUpButton: {
            ...actionButton,
            width: '200px',
        },
        leftFlightButtons: {
            ...actionButton,
            width: '200px',
        },
        leftSection: {
            padding: theme.spacing(2), // Add padding for internal spacing
            backgroundColor: '#303030'
        },
        sectionBox: {  
            width: '100%',
            border: '1px solid #ccc',
            borderRadius: '4px',
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            display: 'flex', // Use Flexbox
            flexDirection: 'column', // Arrange items in a column
            justifyContent: 'center', // Center items vertically
            alignItems: 'center', // Center items horizontally
            boxSizing: 'border-box', // Ensure padding and border are included in the element's total width and height
        },
        rightSection: {
            flex: '1', // Default size: take the remaining space
            backgroundColor: '#ffffff', // Optional: Add a background color for visibility
            display: 'flex',
            flexDirection: 'column',
        },
        infoSection: {
            padding: theme.spacing(2),
            backgroundColor: '#e4e4e4', // Optional: Add a background color for visibility
            flex: '0 0 auto', // Do not grow or shrink
        },
        canvasContainer: {
            flex: '1', // Take the remaining space
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        loadingIndicator: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
        },
        tabbedText: {
            whiteSpace: 'pre', // Preserve whitespace and tabs
        },
        gotoInput: {
            color: "white",
            width: '30px', // Set a fixed width for the input items
            margin: theme.spacing(1), // Optional: Add margin for spacing
            '& .MuiInputBase-input': {
                color: 'white', // Change text color to white
                textAlign: 'center', // Center the text
            },
        },
        subsection: {
            //border: '1px solid #ccc',
            borderRadius: '4px',
            padding: theme.spacing(2),
            marginBottom: theme.spacing(2),
            boxSizing: 'border-box', // Ensure padding and border are included in the element's total width and height
        },
        centeredDiv: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
        actionButton: {
            justify: "center",
            margin: theme.spacing(1),
            color: theme.palette.primary["contrastText"],
            backgroundColor: "green",
            borderRadius: '25px',
        },
        card: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(2),
            height: 150, // Set the height of the card
            maxWidth: 500, // Set a maximum width for the cards
            margin: 'auto', // Center the cards horizontally
            borderRadius: '10px',
        },
        cardLeft: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: theme.spacing(2),
            height: 150, // Set the height of the card
            maxWidth: 500, // Set a maximum width for the cards
            margin: 'auto', // Center the cards horizontally
            borderRadius: '10px',
            transition: 'transform 0.3s, box-shadow 0.3s', // Add transition for smooth hover effect
            '&:hover': {
                transform: 'scale(1.05)', // Slightly scale up the card on hover
                boxShadow: theme.shadows[6], // Add a shadow effect on hover
            },
        },
        icon: {
            fontSize: 40,
            marginBottom: theme.spacing(1),
            transition: 'transform 0.3s', // Add transition for smooth rotation
            '&:hover': {
                animation: '$tiltBackAndForth 0.5s ease-in-out', // Apply animation on hover
            },
        },
        '@keyframes tiltBackAndForth': {
            '0%': {
                transform: 'rotate(0deg)',
            },
            '25%': {
                transform: 'rotate(10deg)',
            },
            '50%': {
                transform: 'rotate(-10deg)',
            },
            '75%': {
                transform: 'rotate(10deg)',
            },
            '100%': {
                transform: 'rotate(0deg)',
            },
        },
        formControlSelect: {
            justify: "center",
            margin: theme.spacing(1),
        },
        gridSelect: {
            //marginTop: theme.spacing(4),
            background: `linear-gradient(45deg, ${theme.palette.secondary.lighter} 30%, ${theme.palette.secondary.lighter} 90%)`,
            padding: theme.spacing(2),
            borderRadius: theme.shape.borderRadius,
            height: '100%', // Take up the full height of the parent
            display: 'flex',
            flexDirection: 'row', // Ensure the cards stay in the same row
            alignItems: 'center', // items vertically
            justifyContent: 'flex-start', // Align items to the start
        },
        cardCenter: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(2),
            height: '50%', // Set the height of the card
            maxWidth: 500, // Set a maximum width for the cards
            margin: 'auto', // Center the cards horizontally
            borderRadius: '10px',
            transform: 'translateY(-25%)', // Offset items slightly above center
            transition: 'transform 0.3s, box-shadow 0.3s', // Add transition for smooth hover effect
            '&:hover': {
                transform: 'translateY(-25%) scale(1.05)', // Slightly scale up the card on hover
                boxShadow: theme.shadows[6], // Add a shadow effect on hover
            },
        },
        select: {
            width: 175,
            //'&:hover': {
            //    backgroundColor: theme.palette.secondary.lighter,
            //},
            transition: 'border-width 0.2s', // Add transition for smooth effects
            '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.secondary.lighter,
                borderWidth: '4px', // Change border thickness on hover
            }
        },
        menuItem: {
            '&:hover': {
                backgroundColor: theme.palette.secondary.lighter,
            },
        }
    };
};


class DroneView extends Component {
  
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.droneCanvasRef = React.createRef();

        this.LCInputsModalRef = React.createRef();
        this.MsgElement = React.createRef();
        
        this.LRobj = null;
        this.dataIntvId = null;
    }
    state = {
        hasAccess: false,
        isCanvasReady: false,
        loading: true,
        isConnected: false,
        isFlying: false,
        pos: {
            x: 0,
            y: 0,
            z: 0
        },
        att: {
            roll: 0,
            pitch: 0,
            yaw: 0
        },
        gotoX: 0,
        gotoY: 0,
        gotoZ: 0,
        radioDetailsSet: false,
        hdrLoaded: false,
        modelLoaded: false,
        
        hardwareSelection: "",
        selected: false,
        hOptions: [],
    }

    componentDidMount() {

        this.hardwareOptions();

    }

    componentWillUnmount() {

        this.handleWrapup();

        // clear intervals
        if (this.dataIntvId) {
            clearInterval(this.dataIntvId);
        }
    }

    resetState = () => {
        this.setState({
            isCanvasReady: false,
            loading: true,
            isConnected: false,
            isFlying: false,
            pos: {
                x: 0,
                y: 0,
                z: 0
            },
            att: {
                roll: 0,
                pitch: 0,
                yaw: 0
            },
            gotoX: 0,
            gotoY: 0,
            gotoZ: 0,
            radioDetailsSet: false,
            hdrLoaded: false,
            modelLoaded: false,
            selected: false,
        });
        
        this.LRobj = null;
    };

    handleWrapup = async() => {
        try {
            // check if flying and land/disconnect
            if (this.state.isFlying) {
                await this.LRobj.drone_land();                    
            }
        } catch (err) {
            // land failed message
            //this.MsgElement.current.handleOpen('red', 'Landing Error', 3000);
        }

        try {
            // check if connected and disconnect
            if (this.state.isConnected) {
                await this.LRobj.disconnect();
            }
        } catch (err) {

        }
        this.resetState();
    }

    hardwareOptions = () => {
        let hardware = []
        for (let j = 0; j < DRONE_VIEW_OPTS.length; j++) {
            if (this.context.AuthInstance.userAccess.includes(DRONE_VIEW_OPTS[j])) {
                hardware.push(DRONE_VIEW_OPTS[j])
            }
        }
        
        if (hardware.length === 0) {
            this.setState({ 
                hOptions: hardware, 
                hardwareSelection: null, 
                hasAccess: false 
            });
        } else {
            this.setState({ 
                hOptions: hardware, 
                hardwareSelection: hardware[0], 
                hasAccess: true 
            });
        }
    }

    handleCanvasReady = () => {
        this.setState({ isCanvasReady: true, loading: false });
    };

    handleRadioInfo = () => {
        this.LCInputsModalRef.current.handleOpen(this.context.AuthInstance);
    }

    handleLCInputs = async () => { // Only gets here when not "cancel"/close-only in modal
        this.setState({ radioDetailsSet: true });
    }

    handleConnection = async () => {

        // reset position and angle values in state
        this.setState({
            pos: {
                x: 0,
                y: 0,
                z: 0
            },
            att: {
                roll: 0,
                pitch: 0,
                yaw: 0
            }
        });

        // if connected, disconnect
        if (this.state.isConnected) {
            
            if (this.state.dataIntvId) {
                clearInterval(this.state.dataIntvId);
                this.setState({ dataIntvId: null });
            }

            if (this.state.isFlying) {
                try {
                    await this.LRobj.drone_land();
                } catch (err) {
    
                }
            }
    
            try {
                await this.LRobj.disconnect();
            } catch (err) {
                //
            }
        
        } else {
    
            // Open connect message modal
            this.MsgElement.current.handleOpenStay('blue', 'Connecting...');

            let res = false;
            if (this.hardwareSelection === 'LocoCraze') {
                // Connection process
                const hexAddr = this.context.AuthInstance.loadedState.LocoCraze.address;
                const channel = this.context.AuthInstance.loadedState.LocoCraze.channel;
                
                res = await this.LRobj.connect(hexAddr, channel);
            } else {

                res = await this.LRobj.connect();

            }

    
            try {
                if (res) {
                    this.MsgElement.current.handleClose();
                } else {
                    this.MsgElement.current.handleOpen('red', 'Connection Failed', 3000);
                    await this.LRobj.disconnect();
                    return;
                }
            } catch (err) {
                this.MsgElement.current.handleOpen('red', 'Connection Failed', 3000);
                return
            };
        }

        this.setState({
            isConnected: !this.state.isConnected
        });

    };

    trackLogData = () => {
        
        let { x, y, z, roll, pitch, yaw } = this.LRobj;
        
        //console.log('x: ', x.toFixed(2), 'y: ', y.toFixed(2), 'z: ', z.toFixed(2));
        //console.log('roll: ', roll, 'pitch: ', pitch, 'yaw: ', yaw);
        this.droneCanvasRef.current.updateQuadcopter(y, z, x, roll, pitch, yaw);
        // update state
        this.setState({
            pos: {
                x: x,
                y: y,
                z: z
            },
            att: {
                roll: roll,
                pitch: pitch,
                yaw: yaw
            }
        });
    };

    resetDronePositionAndOrientation = () => {
        this.setState({
          pos: { x: 0, y: 0, z: 0 },
          att: { roll: 0, pitch: 0, yaw: 0 },
        });
        this.droneCanvasRef.current.updateQuadcopter(0, 0, 0, 0, 0, 0);
    };

    handleFlight = async () => {

        if (this.state.isFlying) {
            try {
                // Land
                await this.LRobj.drone_land();
            } catch (err) {
                // land failed message
                this.MsgElement.current.handleOpen('red', 'Landing Error', 3000);
            }

            this.resetDronePositionAndOrientation();
            
            if (this.state.dataIntvId) {
                clearInterval(this.state.dataIntvId);
                this.setState({ dataIntvId: null });
            }
        } else {
            const interval = setInterval(this.trackLogData, FRAME_INTV);
            this.setState({ dataIntvId: interval });

            // Takeoff
            try {
                await this.LRobj.drone_takeoff();
            } catch (err) {
                // takeoff failed message
                this.MsgElement.current.handleOpen('red', 'Takeoff Error', 3000);
                return;
            }
        }

        this.setState({ isFlying: !this.state.isFlying });
    };

    handleIn = (event) => {
        this.setState( {[event.target.name]: event.target.value} );
    };

    handleInOnBlur = (event, name) => {

        if (!this.state.hasOwnProperty(name)) {
            return
        }

        let m = this.state[name];

        if (name.includes('goto')) {
            // Validate input using a regular expression
            const isValid = /^[0-9]+$/.test(m);

            if (!isValid) {
                m = "0";
            } else {
                // Convert m to a number
                const parsedValue = parseInt(m, 10);

                // Check if the value is a valid integer
                if (Number.isNaN(parsedValue)) {
                    m = "0";
                } else {
                    m = parsedValue.toString();
                }
            }
            this.setState({ [name]: m });
        }

    };

    handleGoTo = async () => {

        // get x, y, and z values from input fields in state
        const x = parseFloat(this.state.gotoX);
        const y = parseFloat(this.state.gotoY);
        const z = parseFloat(this.state.gotoZ);

        const target = [x, y, z];
        await this.LRobj.drone_go(target);

    };

    handleHDRLoad = () => {
        //console.log('HDR texture loaded in DroneView');
        this.setState({ hdrLoaded: true, modelLoaded: true });
    };
    
    handleModelLoad = () => {
        //console.log('GLTF model loaded in DroneView');
        this.setState({ modelLoaded: true });
    };

    renderInfoSection = (classes) => {
        const { hdrLoaded, modelLoaded } = this.state;
    
        if (!hdrLoaded) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={24} style={{ marginRight: 8 }} />
              <Typography variant="body1" className={this.props.classes.tabbedText}>
                Loading Textures...
              </Typography>
            </div>
          );
        } else if (hdrLoaded && !modelLoaded) {
          return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <CircularProgress size={24} style={{ marginRight: 8 }} />
              <Typography variant="body1" className={this.props.classes.tabbedText}>
                Loading Models...
              </Typography>
            </div>
          );
        } else {
          return (
            <Typography variant="body1" className={classes.tabbedText}>
            Position (cm): ({this.state.pos.x.toFixed(1)}, {this.state.pos.y.toFixed(1)}, {this.state.pos.z.toFixed(1)})
            {'\t'}Orientation (deg): Roll: {this.state.att.roll.toFixed(1)}, Pitch: {this.state.att.pitch.toFixed(1)}, Yaw: {this.state.att.yaw.toFixed(1)}
            </Typography>
          );
        }
    };


    openDisplay = () => {
        if (!(NEEDS_RADIO_INFO.includes(this.state.hardwareSelection))) {
            this.setState({ radioDetailsSet: true });
        }

        // Create a shorter reference to window.shared object based on selected hardware + 'Obj'
        let tempObj = window.shared[this.state.hardwareSelection + 'Obj'];

        // update state with the selected object and with the selected flag
        this.setState( {selected: true} );
        this.LRobj = tempObj;
    }

    handleChange = event => {
        this.setState( {[event.target.name]: event.target.value} );
    };

    handleBack = async() => {

        await this.handleWrapup();

    }
    
    render() {
        const { classes } = this.props;
        const { 
            loading, hasAccess, isFlying, 
            isConnected, radioDetailsSet, 
            modelLoaded 
        } = this.state;

        // For TopBar Route Highlight
        const currentPath = this.props.location.pathname;

        // What to Display - Selectable Views Based on activeStep
        return (
            <div className={classes.root}    
            style={{ height: '100vh', maxHeight: '100vh', backgroundColor: 'white', overflow: 'hidden'}}>
                <CssBaseline />
                <Topbar currentPath={currentPath} />
                {!hasAccess ? ( // Advertisement
                <div>


                </div>
                ) : ( // Real Content
                <div className={(this.state.selected) ? classes.accessDiv : classes.accessSelectDiv}>
                    {this.state.selected === false && (
                        <Grid container justifyContent="center" className={classes.gridSelect}>
                            <Grid item xs={12} md={12}>
                                <Card className={classes.cardCenter}>
                                    <Grid container justifyContent="center" alignItems="center">
                                        <Grid item>
                                            <DroneIcon className={classes.icon} color="primary" />
                                        </Grid>
                                        <Grid item>
                                            <CardContent>
                                                <Typography variant="h5" component="h2">
                                                    Drone 3D View
                                                </Typography>
                                                <Typography variant="body2" component="p">
                                                    Choose an option and press select to continue.
                                                </Typography>
                                            </CardContent>
                                        </Grid>
                                        <Grid item>
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControlSelect}
                                            >
                                                <InputLabel id="hardware-select-label">Hardware</InputLabel>
                                                <Select
                                                    className={classes.select}
                                                    labelId="hardware-select-label"
                                                    label="Hardware"
                                                    name="hardwareSelection"
                                                    value={this.state.hardwareSelection}
                                                    onChange={this.handleChange}
                                                    aria-describedby="hardware-pn-helper-text"
                                                >
                                                    {this.state.hOptions.map((val) => {
                                                        return (
                                                            <MenuItem key={val} value={val}
                                                                className={classes.menuItem}
                                                            >
                                                                {val}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                className={classes.actionButton}
                                                onClick={this.openDisplay}
                                            >
                                                Select
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    )}
                    {this.state.selected === true && (
                        <React.Fragment>
                        <Grid container className={classes.accessDiv}>
                            <Grid item xs={3} className={classes.leftSection}>
                                <Box className={classes.sectionBox}>
                                    {NEEDS_RADIO_INFO.includes(this.state.hardwareSelection) && (
                                        <Button
                                            className={classes.lftUpButton}
                                            onClick={this.handleRadioInfo}
                                            disabled={loading || !modelLoaded}
                                            >
                                            Set Radio Info
                                        </Button>  
                                    )}         
                                    <Button
                                        className={classes.lftUpButton}
                                        onClick={this.handleConnection}
                                        disabled={loading || !radioDetailsSet}
                                        >
                                        {isConnected ? 'Disconnect' : 'Connect'}
                                    </Button>
                                </Box>
                                <Box className={classes.sectionBox}>
                                    <Button
                                        className={classes.leftFlightButtons}
                                        onClick={this.handleFlight}
                                        disabled={!isConnected}
                                        >
                                        {isFlying ? 'Land' : 'Takeoff'}
                                    </Button>
                                    <Box className={classes.subsection}>
                                        <Button
                                            className={classes.leftFlightButtons}
                                            onClick={this.handleGoTo}
                                            disabled={!isFlying}
                                            >
                                            Go To
                                        </Button>
                                        <div className={classes.centeredDiv}>
                                            <TextField
                                                className={classes.gotoInput}
                                                name="gotoX"
                                                value = {this.state.gotoX}
                                                onChange={this.handleIn}
                                                onBlur = {(e) => this.handleInOnBlur(e, 'gotoX')}
                                            />
                                            <TextField
                                                className={classes.gotoInput}
                                                name="gotoY"
                                                value = {this.state.gotoY}
                                                onChange={this.handleIn}
                                                onBlur = {(e) => this.handleInOnBlur(e, 'gotoY')}
                                            />
                                            <TextField
                                                className={classes.gotoInput}
                                                name="gotoZ"
                                                value = {this.state.gotoZ}
                                                onChange={this.handleIn}
                                                onBlur = {(e) => this.handleInOnBlur(e, 'gotoZ')}
                                            />
                                        </div>
                                    </Box>
                                </Box>
                                <Box className={classes.sectionBox}>
                                    <Button
                                        className={classes.leftFlightButtons}
                                        onClick={this.handleBack}
                                    >
                                        Back
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={9} className={classes.rightSection}>
                                <div className={classes.rightSection}>
                                    <div className={classes.infoSection}>
                                        {this.renderInfoSection(classes)}
                                    </div>
                                    <div className={classes.canvasContainer}>
                                        <DroneCanvas
                                            ref={this.droneCanvasRef}
                                            onReady={this.handleCanvasReady}                                
                                            onHDRLoad={this.handleHDRLoad}
                                        />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                    )}
                </div>
                )}




                <LCInputsModal
                    ref={this.LCInputsModalRef}
                    onClose={this.handleLCInputs}
                />
                <GeneralMessage
                    ref = {this.MsgElement}
                    onClose = {() => void 0}
                />
            </div>
        );
    }


};

export default withRouter(withStyles(styles)(DroneView));
