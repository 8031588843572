import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, withStyles } from '@material-ui/core/styles'
import './App.css';

import { lighten } from '@material-ui/core/styles';

import { Route, HashRouter, Switch } from 'react-router-dom'
import Program from './components/Program'
import Academy from './components/Academy'
import DataVis from './components/DataVis'
import DroneRC from './components/DroneRC'
import DogRC from './components/DogRC'
//import Main from './components/Main'
import Login from './components/Login'
import ScrollToTop from './components/ScrollTop'
import PrivateRoute from './PrivateRoute';
import { AuthContext } from "./context/auth";

import AuthService from './components/AuthService';

//import Routes from './routes'
import { green, indigo, orange, purple } from '@material-ui/core/colors'
import DroneView from './components/DroneView';

class App extends Component {

  constructor(props) {
    super(props);

    this.Auth = new AuthService();
    
  }

  state = {
    data: null,
    ready: false,
  };

  componentDidMount() {
    // Call our fetch function below once the component mounts
    this.callBackendAPI()
      .then(res => this.setState({ data: res.express }))
      .catch(err => console.log(err));

    try {
      const refToken = this.Auth.getRefreshToken();
      
      if (refToken) {      
        const refAttempt = this.Auth.validRefresh();
        if (refAttempt) {
          this.refreshAccess();
          const tokenA = this.Auth.getAccessToken();
          this.Auth.authTokens = tokenA;
        } else {
          this.Auth.logout();
          this.setState( {ready: true} );
        }
      } else {
        this.setState( {ready: true} );
      }
    } catch {
    }
    //this.setState( {ready: true} );




    /*
    const tokenA = this.Auth.getAccessToken();
    if (tokenA) {
      try {
        this.refreshAccess();
        this.Auth.authTokens = tokenA;
      } catch {
        this.Auth.logout();
        this.setState( {ready: true} )
      }
    } else {
      this.setState( {ready: true} )
    }
    */
  }

  refreshAccess = () => {
    this.Auth.getProductAccess().then(() => {
      this.Auth.getProgramList().then(() => {
        this.setState( {ready: true} )
      }).catch(() => {
        this.Auth.logout();
        window.location.reload(false);
      });
    }).catch(() => {
      this.Auth.logout();
      window.location.reload(false);
    });
  }


  // Fetches our GET route from the Express server. (Note the route we are fetching matches the GET route from server.js
  callBackendAPI = async () => {
    const response = await fetch('/express_backend');
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message) 
    }
    return body;
  };
 

  render() {

    return (
      <div style={{ maxHeight: '100vh', backgroundColor: 'white', overflow: 'visible'}}>
        {(this.state.ready === true) && (
          <ThemeProvider theme={theme}>
            <AuthContext.Provider value={{ AuthInstance: this.Auth }}>
              <HashRouter>
                <ScrollToTop>
                  <Switch>
                    <PrivateRoute exact path='/' component={ Program } />
                    <Route exact path='/login' component={Login} />
                    <PrivateRoute exact path='/program' component={ Program } />
                    <PrivateRoute exact path='/datavis' component={ DataVis } />
                    <PrivateRoute exact path='/dronerc' component={ DroneRC } />
                    <PrivateRoute exact path='/dogrc' component={ DogRC } />
                    <PrivateRoute exact path='/droneview' component={ DroneView } />
                    <PrivateRoute exact path='/academy' component={ Academy } />
                  </Switch>
                </ScrollToTop>
              </HashRouter>
            </AuthContext.Provider>
          </ThemeProvider>
        )}
      </div>
    );
  }
}
//<PrivateRoute exact path='/' component={ Main } />
//<PrivateRoute exact path='/academy' component={ Academy } />

const primaryMain = '#058af4';
const primaryLight = '#058af4';//lighten(primaryMain, 0.85);
const primaryLighter = lighten(primaryMain, 0.75);

const secondaryMain = indigo[50];
const secondaryPurple = purple[500];
const secondaryLighter = lighten(purple[500], 0.85);

const tertiaryMain = orange[900];
const tertiaryLighter = lighten(orange[900], 0.85);

const greenMain = green[900];
const greenLighter = lighten(greenMain, 0.55);

const redMain = '#f40558';
const redLighter = lighten(redMain, 0.85);

//theme.palette.action.hovers

const theme = createTheme({
  palette: {
    secondary: {
      main: secondaryMain,
      purple: secondaryPurple,
      lighter: secondaryLighter,
      green: greenMain,
      greenLighter: greenLighter,
      red: redMain,
      redLighter: redLighter,
    },
    primary: {
      main: primaryMain,
      light: primaryLight,
      lighter: primaryLighter,
    },
    tertiary: {
      main: tertiaryMain,
      lighter: tertiaryLighter,
    },
    action: {
      hover: primaryLighter,
    }
  }, 
});




export default App;