// Child Component of: 
// --- BaseBlock

// Import React and Material-UI Modules
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Slider from '@material-ui/core/Slider';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    paper: {
      width: 515,//600
      height: 200,
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      square: false,
      boxShadow: theme.shadows[5],
    },
    grid: {
        justify: 'flex-start',
    },
    gridWhite: {
        justify: 'flex-start',
        backgroundColor: 'white',
        borderRadius: '15px',
        padding: theme.spacing(1),
    },
    titleText: {
        color: "white",
        margin: theme.spacing(1),
    },
    inputField: {
        width: "100%",
        color: "white",
        marginTop: theme.spacing(0.6),
        marginLeft: theme.spacing(1),
        textAlign: "center",
    },
    inputFieldRC: {
        width: "100%",
        color: "white",
        marginTop: theme.spacing(0.6),
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        textAlign: "center",
    },
    regularText: {
        color: "white",
        margin: theme.spacing(1),
    },
    textButton: {
        color: "white",
        margin: theme.spacing(0),
        border: '1px solid #000',
    },
    colorDisplay: {
        margin: '7px',
        height: '25px',
        width: '50px',
        borderRadius: '5px',
        border: '1px solid #000',
    },
    textSelect: {
        color: "white",
        marginTop: theme.spacing(0.4),        
    },
});




// Component Class
class LocoDroneCBlocks extends React.Component {

    state = {
        LDCBlocksOptions: {
            itakeoff: 
            {
                id: 0,
                title: 'Takeoff',
                height: 135, 
                color: '#ec4078',
                control: {},
                code: ['# Command Drone to Takeoff', 'ldc.drone_takeoff()'],
            },
            iland: 
            {
                id: 0,
                title: 'Land',
                height: 135, 
                color: '#ec4078',
                control: {},
                code: ['# Command Drone to Land', 'ldc.drone_land()'],
            },
            iloop:
            {
                id: 0,
                title: 'Loop',
                height: 135, 
                color: '#8057c1',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Loop for ${ctrl.in1} times`, `for i in range(${ctrl.in1}):`]
                },
                code: [],
                inWidth: '40px',
                in1Type: 'int',
                text1: 'Repeat',
                text2: 'times',
            },
            iloopend:
            {        
                id: 0,
                title: 'LoopEnd',
                height: 135, 
                color: '#8057c1',
                control: {},
                code: [],
            },
            ihover:
            {
                id: 0,
                title: 'Hover',
                height: 135, 
                color: '#fbc22d',
                control: {in1: 0, },
                codeRef(ctrl) {
                    return [`# Hover for ${ctrl.in1} seconds`, `ldc.drone_rc(0, 0, 0, 0)`, `time.sleep(${ctrl.in1})`]
                },
                code: [],
                inWidth: '40px',
                in1Type: 'float',
                text1: 'Hover for',
                text2: 'seconds',
                in1Limits: [0, 20],
            },
            imove: {
                id: 0,
                title: 'Move',
                height: 135, 
                color: '#009788',
                control: {btn1: '1', btn1Txt: 'UP', btn2: '1', btn2Txt: 'cm', in1: 50, },
                codeRef(ctrl) {
                    return [
                        `# Move ${ctrl.btn1Txt} for ${ctrl.in1} ${ctrl.btn2Txt}`, 
                        `ldc.drone_move(ldc.MOVE_${ctrl.btn1Txt}, 
                        '${ctrl.btn2Txt}', ${ctrl.in1})`
                    ]
                },
                code: [],
                text1: 'Move ',
                text2: 'for',
                text3: 'centimeters',
                btn1Options: ['UP', 'DOWN', 'LEFT', 'RIGHT', 'FORWARD', 'BACK'],
                btn1map: {'UP':'UP', 'DOWN':'DOWN', 'LEFT':'LEFT', 'RIGHT':'RIGHT', 'FORWARD':'FORWARD', 'BACK':'BACK'},
                btn2Options: ['cm', 'inches'],
                btn2map: {'CM':'cm', 'INCHES':'inches'},
                inWidth: '40px',
                in1Type: 'int',
                in1Limits: [50, 200],
            },
            ipos: {
                id: 0,
                title: 'Position',
                height: 150, 
                color: '#4DAF4E',
                control: {in1: 0, in2: 0, in3: 0, in4: 50, btn1: '1', btn1Txt: 'RELATIVE', },
                codeRef(ctrl) {
                    let codeArray = []
                    if (ctrl.btn1Txt === 'ABSOLUTE') {
                        codeArray.push(`# Go to (${ctrl.in1}, ${ctrl.in2}, ${ctrl.in3}) at ${ctrl.in4} cm/s`, `ldc.drone_go([${ctrl.in1},${ctrl.in2},${ctrl.in3}], ${ctrl.in4})`)
                    } else if (ctrl.btn1Txt === 'RELATIVE') {
                        codeArray.push(`# Go to (${ctrl.in1}, ${ctrl.in2}, ${ctrl.in3}) at ${ctrl.in4} cm/s`, `ldc.drone_waypoint([${ctrl.in1},${ctrl.in2},${ctrl.in3}], ${ctrl.in4})`)
                    }
                    return codeArray
                },
                code: [],
                text1: 'Go to (',
                text2: ')',
                text3: 'at ',
                text4: 'cm/s',
                btn1Options: ['RELATIVE', 'ABSOLUTE'],
                btn1map: {'RELATIVE':'RELATIVE', 'ABSOLUTE':'ABSOLUTE'},
                inWidth: '40px',
                in1Type: 'int',
                //in1Limits: [-500, 500],
                in2Type: 'int',
                //in2Limits: [-500, 500],
                in3Type: 'int',
                //in3Limits: [-500, 500],
                in4Type: 'int',
                in4Limits: [50, 200],
                in1Cust: '',
                in2Cust: '',
                in3Cust: '',
                boundRef(x) {
                    let m = x
                    if (m < -500) {
                        m = -500
                    } else if (m > 500) {
                        m = 500
                    } else if ((m >= 1) && (m <= 9)) {
                        m = 0
                    } else if ((m <= -1) && (m >= -9)) {
                        m = 0
                    } else if ((m <= -10) && (m >= -19)) {
                        m = -20
                    } else if ((m >= 10) && (m <= 19)) {
                        m = 20
                    }
                    return m;
                },
            },
            icircle: {
                id: 0,
                title: 'Circle',
                height: 150,
                color: '#FF2424',
                control: {btn1:1, btn1Txt: 'LEFT', in1: 0, in2: 1},//in1=seconds, in2=speed
                // skulp => circle_direction, duration, speed = null
                // circleTurn(speed, seconds, direction)
                codeRef(ctrl) { 
                    return [`# Circle ${ctrl.btn1} for ${ctrl.in1} seconds at speed ${ctrl.in2}`,
                            `ldc.drone_circle(ldc.CIRCLE_${ctrl.btn1}, ${ctrl.in1}, ${ctrl.in2})`,
                            ]
                },
                code: [],
                text1: 'Fly Drone ',
                text2: 'in a circle for ',
                text3: 'seconds at speed ',
                btn1Options: ['LEFT', 'RIGHT'],
                btn1map: {'LEFT':'LEFT', 'RIGHT':'RIGHT'},
                inWidth: '40px',
                in1Type: 'int',
                in1Limits: [1, 20],
                in2Type: 'int',
                in2Limits: [1, 100], // is # range, no cm/s
            },
            irc: {                
                id: 0,
                title: 'Remote Control',
                height: 180, 
                color: '#f16749',
                control: {in1: 0, in2: 0, in3: 0, in4: 0, in5: 0, btn1: 1, btn2: 1, btn3: 1, btn4: 1},
                codeRef(ctrl) {
                    return [`# RC Control ${ctrl.in1}, ${ctrl.in2}, ${ctrl.in4}, ${ctrl.in3} for ${ctrl.in5} seconds`, 
                            `ldc.drone_rc(${Number(ctrl.btn1) * Number(ctrl.in1)}, ${Number(ctrl.btn2) * Number(ctrl.in2)}, ${Number(ctrl.btn4) * Number(ctrl.in4)}, ${Number(ctrl.btn3) * Number(ctrl.in3)})`,
                            `time.sleep(${ctrl.in5})`,
                            `ldc.drone_rc(0, 0, 0, 0)`]
                },
                code: [],
                inWidth: '40px',
                in1Type: 'int',
                in1Limits: [0, 100],
                in2Type: 'int',
                in2Limits: [0, 100],
                in3Type: 'int',
                in3Limits: [0, 100],
                in4Type: 'int',
                in4Limits: [0, 100],
                in5Type: 'float',
                in5Limits: [1, 20],
                text1: 'for',
                text2: 'seconds',
                btn1Options: ['Left', 'Right'],
                btn1map: {'Left':-1, 'Right':1},
                btn2Options: ['Forward', 'Backward'],
                btn2map: {'Forward':1, 'Backward':-1},
                btn3Options: ['Up', 'Down'],
                btn3map: {'Up':1, 'Down':-1},
                btn4Options: ['Yaw CW', 'Yaw CCW'],
                btn4map: {'Yaw CW':1, 'Yaw CCW':-1, 'Yaw Clockwise':1, 'Yaw Couter-Clockwise':-1},
            },
            iflip:
            {
                id: 0,
                title: 'Flip',
                height: 150, 
                color: '#FF2424',
                control: {btn1: 'LEFT', btn1Txt: 'LEFT', },
                codeRef(ctrl) {
                    return [`# Flip ${ctrl.btn1Txt}`, `ldc.drone_flip(ldc.FLIP_${ctrl.btn1})`]
                },
                code: [],
                text1: 'Flip Drone ',
                btn1Options: ['LEFT', 'RIGHT', 'FORWARD', 'BACK'],
                btn1map: {'LEFT':'LEFT', 'RIGHT':'RIGHT', 'FORWARD':'FORWARD', 'BACK':'BACKWARD'},
            },
            iyaw: {
                id: 0,
                title: 'Yaw',
                height: 135, 
                color: '#2196f3',
                control: {btn1: 'YAW_CW', btn1Txt: 'Clockwise', in1: 0, },
                codeRef(ctrl) {
                    const directionMultiplier = ctrl.btn1 === 'YAW_CW' ? 1 : -1;
                    const adjustedIn1 = ctrl.in1 * directionMultiplier;
                    return [
                        `# Yaw ${ctrl.btn1Txt} for ${adjustedIn1} degrees`, 
                        `ldc.drone_yaw(${adjustedIn1})`
                    ]
                },
                code: [],
                text1: 'Yaw ',
                text2: 'for',
                text3: 'degrees',
                btn1Options: ['Clockwise', 'Counter-clockwise'],
                btn1map: {'Clockwise': 'YAW_CW', 'Counter-clockwise':'YAW_CCW'},
                inWidth: '40px',
                in1Type: 'int',
                in1Limits: [0, 180],
            },
            ilights_rgb: {
                id: 0,
                title: 'LED Color',
                height: 200, 
                color: '#909090',
                control: {
                    btn1: 0, btn1Txt: 'DRONE', 
                    slid1: 255, slid2: 255, slid3: 255, slid4: 125
                },
                codeRef(ctrl) {
                    let codeArray =  [
                        `# Set ${ctrl.btn1Txt} LED Color`, 
                    ]
                    if (ctrl.btn1Txt === 'DRONE') {
                        codeArray.push(`ldc.drone_LED_color(${ctrl.slid1}, ${ctrl.slid2}, ${ctrl.slid3}, ${ctrl.slid4})`)
                    } else if (ctrl.btn1Txt === 'CONTROLLER') {
                        codeArray.push(`ldc.controller_LED_color(${ctrl.slid1}, ${ctrl.slid2}, ${ctrl.slid3}, ${ctrl.slid4})`)
                    }
                    return codeArray
                },
                code: [],
                text1: 'Set RGB LED ',
                btn1Options: ['DRONE', 'CONTROLLER'],
                btn1map: {'DRONE':'DRONE', 'CONTROLLER':'CONTROLLER'},
                text2: ' to color ',
                text3: ' at brightness ',
            },
            ibuzzer: {
                id: 0,
                title: 'Buzzer',
                height: 150, 
                color: '#00D8C8',
                control: {
                    btn1: '1', btn1Txt: 'Drone', in1: 0, 
                    sel1: 'C', sel1Txt: 'C', sel2: '0', sel2Txt: '0',
                },
                codeRef: function(ctrl) {
                    const commentSel = Object.keys(this.sel1map).find(key => this.sel1map[key] === ctrl.sel1);
                    let codeArray = [`# On the ${ctrl.btn1Txt}, play tone ${commentSel} at octave ${ctrl.sel2} for ${ctrl.in1} seconds`]
                    // combine sel1 and sel2 to get the note
                    const note = ctrl.sel1 + ctrl.sel2;
                    const duration = Math.floor(parseFloat(ctrl.in1) * 1000);
                    if (ctrl.btn1Txt === 'drone') {
                        codeArray.push(`ldc.drone_buzzer('${note}', ${duration})`)
                    } else {
                        codeArray.push(`ldc.controller_buzzer('${note}', ${duration})`)
                    }
                    return codeArray;
                },
                code: [],
                text1: 'On ',
                text2: 'play tone ',
                text3: 'at octave ',
                text4: 'for ',
                text5: 'seconds',
                btn1Options: ['drone', 'controller'],
                btn1map: {'DRONE':'drone', 'CONTROLLER':'controller'},
                sel1Options: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
                sel2Options: ['0', '1', '2', '3', '4', '5', '6', '7', '8'],
                sel1map: {'C':'C', 'C#':'CS', 'D':'D', 'D#':'DS', 'E':'E', 'F':'F', 'F#':'FS', 'G':'G', 'G#':'GS', 'A':'A', 'A#':'AS', 'B':'B'},
                sel2map: {'0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6', '7': '7', '8': '8'},
                inWidth: '40px',
                in1Type: 'float',
                in1Limits: [0, 20],
            }
        },
        renderType: this.props.blockType,
        btn1: 0,
        btn2: 0,
        btn3: 0,
        btn4: 0,
        btn1Txt: '',
        btn2Txt: '',
        btn3Txt: '',
        btn4Txt: '',
        in1: 0,
        in2: 0,
        in3: 0,
        in4: 0,
        in5: 0,
        slid1: 255,
        slid2: 255,
        slid3: 255,
        slid4: 125,
        sel1Txt: '',
        sel2Txt: '',
        sel1: 0,
        sel2: 0,
    }

    componentDidMount() {
        let updates = {control: this.props.control};
        this.componentRefresh(updates);
    }

    componentRefresh = (updates) => {
        
        let tempRenderType = this.state.renderType;
        
        if ('name' in updates) {
            tempRenderType = updates.name;
            this.setState( {renderType: updates.name} );
        }

        let tempLDCBlocks = { ...this.state.LDCBlocksOptions };

        tempLDCBlocks[tempRenderType].id = this.props.id
        if ((typeof updates.control !== "undefined") && (Object.keys(updates.control).length !== 0)) {
            let keyNames = Object.keys(updates.control);
            keyNames.forEach((val, i) => {
                tempLDCBlocks[tempRenderType].control[val] = updates.control[val];
                this.setState( {[val]: updates.control[val]} );
                if (tempLDCBlocks[tempRenderType].hasOwnProperty('codeRef')) {
                    tempLDCBlocks[tempRenderType].code = tempLDCBlocks[tempRenderType].codeRef(tempLDCBlocks[tempRenderType].control);
                }
            });
        } else {
            if (tempLDCBlocks[tempRenderType].hasOwnProperty('codeRef')) {
                tempLDCBlocks[tempRenderType].code = tempLDCBlocks[tempRenderType].codeRef(tempLDCBlocks[tempRenderType].control);
            }

            const controls = ['btn1', 'btn2', 'btn3', 'btn4', 'in1', 'in2', 'in3', 'in4', 'in5', 'slid1', 'slid2', 'slid3', 'slid4', 'sel1', 'sel2'];

            controls.forEach(control => {
                if (control in tempLDCBlocks[tempRenderType].control) {
                    if (control.startsWith('btn')) {
                        const optionKey = `${control}Options`;
                        const mapKey = `${control}map`;
                        tempLDCBlocks[tempRenderType].control[control] = tempLDCBlocks[tempRenderType][mapKey][tempLDCBlocks[tempRenderType][optionKey][0]];
                        tempLDCBlocks[tempRenderType].control[`${control}Txt`] = tempLDCBlocks[tempRenderType][optionKey][0];
                        this.setState({ [`${control}Txt`]: tempLDCBlocks[tempRenderType].control[`${control}Txt`] });
                    } else if (control.startsWith('in')) {
                        this.setState({ [control]: tempLDCBlocks[tempRenderType].control[control] });
                    } else if (control.startsWith('slid')) {
                        this.setState({ [control]: tempLDCBlocks[tempRenderType].control[control] });
                    } else if (control.startsWith('sel')) {
                        const optionKey = `${control}Options`;
                        tempLDCBlocks[tempRenderType].control[`${control}Txt`] = tempLDCBlocks[tempRenderType][optionKey][0];
                        this.setState({ [`${control}Txt`]: tempLDCBlocks[tempRenderType].control[`${control}Txt`] });
                    }
                }
            });
        }
        
        this.setState( {LDCBlocksOptions: tempLDCBlocks} );
        this.props.handleSubState(tempLDCBlocks[tempRenderType]);
    }

    handleInOnBlur = (event, name) => {
        if (!this.state.hasOwnProperty(name)) {
            return
        }
        //let m = event.target.value
        //let inName = event.target.name

        let m = this.state[name]
        let inName = name

        let inLimits = inName + 'Limits'
        let inCust = inName + 'Cust'
        let inType = inName + "Type"

        let tempLDCBlocks = this.state.LDCBlocksOptions;
        if (!isNaN(parseFloat(m)) && isFinite(m)) {
            let n = Number(m);
            
            if (inLimits in tempLDCBlocks[this.state.renderType]) {
                if (n < tempLDCBlocks[this.state.renderType][inLimits][0]) {
                    if (tempLDCBlocks[this.state.renderType][inLimits][0] < 0) {
                        n = 0
                        m = "0"
                    } else {
                        n = tempLDCBlocks[this.state.renderType][inLimits][0]
                        m = String(tempLDCBlocks[this.state.renderType][inLimits][0])
                    }
                } else if (n > tempLDCBlocks[this.state.renderType][inLimits][1]) {
                    n = tempLDCBlocks[this.state.renderType][inLimits][1]
                    m = String(tempLDCBlocks[this.state.renderType][inLimits][1])
                }
            } else if (inCust in tempLDCBlocks[this.state.renderType]) {
                n = tempLDCBlocks[this.state.renderType].boundRef(n)
                m = String(n)
            }
            if (tempLDCBlocks[this.state.renderType][inType] === 'int') {
                tempLDCBlocks[this.state.renderType].control[inName] = Math.floor(n);
                m = String(Math.floor(n))
                tempLDCBlocks[this.state.renderType].code = tempLDCBlocks[this.state.renderType].codeRef(tempLDCBlocks[this.state.renderType].control);
            } else {
                tempLDCBlocks[this.state.renderType].control[inName] = n;
                tempLDCBlocks[this.state.renderType].code = tempLDCBlocks[this.state.renderType].codeRef(tempLDCBlocks[this.state.renderType].control);
            }
        } else {
            if (tempLDCBlocks[this.state.renderType][inType] === 'hexString') {
                // check if m is a valid hex string
                if (m.length === 10) {
                    let hexRegex = /^[0-9A-Fa-f]+$/;
                    if (hexRegex.test(m)) {
                        tempLDCBlocks[this.state.renderType].control[inName] = m;
                    } else {
                        m = "E7E7E7E7E7"
                        tempLDCBlocks[this.state.renderType].control[inName] = m;
                    }
                } else {
                    m = "E7E7E7E7E7"
                    tempLDCBlocks[this.state.renderType].control[inName] = m;
                }
                tempLDCBlocks[this.state.renderType].code = tempLDCBlocks[this.state.renderType].codeRef(tempLDCBlocks[this.state.renderType].control);
            } else {
                if (inLimits in tempLDCBlocks[this.state.renderType]) {
                    if (tempLDCBlocks[this.state.renderType][inLimits][0] < 0) {
                        m = "0"
                    } else {
                        m = String(tempLDCBlocks[this.state.renderType][inLimits][0])
                    }
                } else {
                    m = "0"
                }
                tempLDCBlocks[this.state.renderType].code = tempLDCBlocks[this.state.renderType].codeRef(tempLDCBlocks[this.state.renderType].control);
            }
        }

        //this.setState( {LDCBlocksOptions: tempLDCBlocks, [event.target.name]: m} );
        this.setState( {LDCBlocksOptions: tempLDCBlocks, [name]: m} );
        this.props.handleSubState(tempLDCBlocks[this.state.renderType]);
    }

    handleIn = (event) => {
        let m = event.target.value;
        this.setState( {[event.target.name]: m} );
    }
    
    handleBtn = (num) => {

        let numStr = String(num);
        let btnMap = 'btn' + numStr + 'map'
        let btnOptions = 'btn' + numStr + 'Options'
        let btnName = 'btn' + numStr
        let btnText = 'btn' + numStr + 'Txt'

        let tempLDCBlocks = this.state.LDCBlocksOptions;

        let nowIndex = tempLDCBlocks[this.state.renderType][btnOptions].indexOf(tempLDCBlocks[this.state.renderType].control[btnText])
        nowIndex += 1
        if (nowIndex >= tempLDCBlocks[this.state.renderType][btnOptions].length) {
            nowIndex = 0
        }
        tempLDCBlocks[this.state.renderType].control[btnName] = 
                    tempLDCBlocks[this.state.renderType][btnMap][tempLDCBlocks[this.state.renderType][btnOptions][nowIndex]];

        if (btnMap in tempLDCBlocks[this.state.renderType]) {
            tempLDCBlocks[this.state.renderType].control[btnText] = tempLDCBlocks[this.state.renderType][btnOptions][nowIndex];
            this.setState( {[btnText]: tempLDCBlocks[this.state.renderType].control[btnText]} );
        } else {
            this.setState( {[btnText]: tempLDCBlocks[this.state.renderType].control[btnName]} );
        }
        tempLDCBlocks[this.state.renderType].code = tempLDCBlocks[this.state.renderType].codeRef(tempLDCBlocks[this.state.renderType].control);
        
        this.setState( {LDCBlocksOptions: tempLDCBlocks} );
        this.props.handleSubState(tempLDCBlocks[this.state.renderType]);
    }

    handleSlider = (event, value, name) => {
        
        this.setState({[name]: value});
        
        let tempLDCBlocks = this.state.LDCBlocksOptions;
        tempLDCBlocks[this.state.renderType].control[name] = value;
        tempLDCBlocks[this.state.renderType].code = tempLDCBlocks[this.state.renderType].codeRef(tempLDCBlocks[this.state.renderType].control);
        
        this.setState( {LDCBlocksOptions: tempLDCBlocks} );
        this.props.handleSubState(tempLDCBlocks[this.state.renderType]);
    };

    handleSelect = (event) => {

        let hName = event.target.name;
        let hText = hName + 'Txt';
        let hMap = hName + 'map';

        let tempLDCBlocks = this.state.LDCBlocksOptions;
        if (hMap in tempLDCBlocks[this.state.renderType]) {
            tempLDCBlocks[this.state.renderType].control[hName] = tempLDCBlocks[this.state.renderType][hMap][event.target.value];                
            tempLDCBlocks[this.state.renderType].control[hText] = event.target.value;

            this.setState( {[hText]: tempLDCBlocks[this.state.renderType].control[hText]} );
        }
        
        tempLDCBlocks[this.state.renderType].code = tempLDCBlocks[this.state.renderType].codeRef(tempLDCBlocks[this.state.renderType].control);
        
        this.setState( {LDCBlocksOptions: tempLDCBlocks} );
        this.props.handleSubState(tempLDCBlocks[this.state.renderType]);
    }

    getCombinedColor = () => {
        const { slid1, slid2, slid3 } = this.state;
        const slid4 = this.state.slid4 / 255;
        const r = Math.min(255, Math.max(0, Math.round(slid1 * slid4)));
        const g = Math.min(255, Math.max(0, Math.round(slid2 * slid4)));
        const b = Math.min(255, Math.max(0, Math.round(slid3 * slid4)));
        return `rgb(${r}, ${g}, ${b})`;
    };

    render() {

        // Referenced below for setting styles
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Box 
                    height={this.state.LDCBlocksOptions[this.state.renderType].height}
                    style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                >
                    {this.state.LDCBlocksOptions[this.state.renderType]?.title ? (
                        <Typography 
                            className={classes.titleText}
                            variant='h4'
                            style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                        >
                            {this.state.LDCBlocksOptions[this.state.renderType].title}
                        </Typography>
                    ) : (
                        <div style={{ paddingTop: '8px' }}></div> // Add padding when there is no title
                    )}
                    {
                    ((this.state.renderType === 'iloop') 
                    || (this.state.renderType === 'ihover')) && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{
                                    width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                  }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'ibuzzer' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs={12}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs="auto">
                                <Typography 
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LDCBlocksOptions[this.state.renderType].text1}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <Button
                                    className={classes.textButton}
                                    style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                    variant="contained"
                                    onClick={() => this.handleBtn(1)}
                                    disabled={this.props.isDisabled}
                                >
                                    {this.state.btn1Txt}
                                </Button>
                            </Grid>
                            <Grid item xs="auto">
                                <Typography 
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LDCBlocksOptions[this.state.renderType].text2}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <Select
                                    className={classes.textSelect}
                                    style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                    onClick={this.handleSelect}
                                    disabled={this.props.isDisabled}
                                    name='sel1'
                                    value={this.state.sel1Txt}
                                >
                                {this.state.LDCBlocksOptions[this.state.renderType].sel1Options.map((val) => {
                                    return (
                                        <MenuItem 
                                            key = {'sel1' + val} 
                                            value = {val}
                                            style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color, color: "white"}}
                                        >{val}</MenuItem>
                                    );
                                })}
                                </Select>
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid 
                                container 
                                spacing={0}
                                direction="row"
                            >
                            <Grid item xs="auto">
                                <Typography 
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LDCBlocksOptions[this.state.renderType].text3}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <Select
                                    className={classes.textSelect}
                                    style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                    onClick={this.handleSelect}
                                    disabled={this.props.isDisabled}
                                    name='sel2'
                                    value={this.state.sel2Txt}
                                >
                                {this.state.LDCBlocksOptions[this.state.renderType].sel2Options.map((val) => {
                                    return (
                                        <MenuItem 
                                            key = {'sel2' + val} 
                                            value = {val}
                                            style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color, color: "white"}}
                                        >{val}</MenuItem>
                                    );
                                })}
                                </Select>
                            </Grid>
                            <Grid item xs="auto">
                                <Typography 
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LDCBlocksOptions[this.state.renderType].text4}
                                </Typography>
                            </Grid>
                            <Grid item xs="auto">
                                <Input 
                                    className={classes.inputField}
                                    name='in1'
                                    style={{
                                            width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                        }}
                                    onChange = {this.handleIn}
                                    onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                    value={this.state.in1}
                                    disabled={this.props.isDisabled}
                                    autoComplete="off"
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <Typography 
                                    className={classes.regularText}
                                    style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                                >
                                    {this.state.LDCBlocksOptions[this.state.renderType].text5}
                                </Typography>
                            </Grid> 
                            </Grid>
                        </Grid>
                        
                        </Grid>
                    )}
                    {this.state.renderType === 'ilights_rgb' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid 
                                container 
                                spacing={0}
                                justifyContent = "space-around"
                                className={classes.gridWhite}
                                direction="row"
                            >
                            <Grid item xs={3}>
                                <Slider
                                    defaultValue={255}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    value={this.state.slid1}
                                    style={{color:'red'}}
                                    step={1}
                                    min={0}
                                    max={255}
                                    disabled={this.props.isDisabled}
                                    onChange={(event, value) => this.handleSlider(event, value, 'slid1')}
                                    onChangeCommitted={(event, value) => this.handleSlider(event, value, 'slid1')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Slider
                                    defaultValue={255}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    value={this.state.slid2}
                                    style={{color:'green'}}
                                    step={1}
                                    min={0}
                                    max={255}
                                    disabled={this.props.isDisabled}
                                    onChange={(event, value) => this.handleSlider(event, value, 'slid2')}
                                    onChangeCommitted={(event, value) => this.handleSlider(event, value, 'slid2')}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Slider
                                    defaultValue={255}
                                    aria-labelledby="discrete-slider"
                                    valueLabelDisplay="auto"
                                    value={this.state.slid3}
                                    style={{color:'blue'}}
                                    step={1}
                                    min={0}
                                    max={255}
                                    disabled={this.props.isDisabled}
                                    onChange={(event, value) => this.handleSlider(event, value, 'slid3')}
                                    onChangeCommitted={(event, value) => this.handleSlider(event, value, 'slid3')}
                                />
                            </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <Slider
                                defaultValue={125}
                                aria-labelledby="discrete-slider"
                                valueLabelDisplay="auto"
                                value={this.state.slid4}
                                style={{color:'gray'}}
                                step={1}
                                min={0}
                                max={255}
                                disabled={this.props.isDisabled}
                                onChange={(event, value) => this.handleSlider(event, value, 'slid4')}
                                onChangeCommitted={(event, value) => this.handleSlider(event, value, 'slid4')}
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <div
                                className={classes.colorDisplay}
                                style={{ backgroundColor: this.getCombinedColor() }}
                            />
                        </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'icircle' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in2'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in2')}
                                value={this.state.in2}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        </Grid>
                    )}
                    {this.state.renderType === 'iflip' && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        </Grid>
                    )}
                    {(this.state.renderType === 'iyaw') && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>

                        </Grid>
                    )}
                    {(this.state.renderType === 'imove') && (
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color, marginLeft: '5px'}}
                                variant="contained"
                                onClick={() => this.handleBtn(2)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn2Txt}
                            </Button>
                        </Grid>

                        </Grid>
                    )}
                    {(this.state.renderType === 'ipos') && (
                        <React.Fragment>
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in1'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in2'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in2')}
                                value={this.state.in2}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in3'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in3')}
                                value={this.state.in3}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        </Grid>
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text3}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in4'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in4')}
                                value={this.state.in4}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text4}
                            </Typography>
                        </Grid>

                    </Grid>
                    </React.Fragment>
                    )}
                    {(this.state.renderType === 'irc') && (
                        <React.Fragment>
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(1)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn1Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputFieldRC}
                                name='in1'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in1')}
                                value={this.state.in1}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(2)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn2Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputFieldRC}
                                name='in2'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in2')}
                                value={this.state.in2}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        </Grid>
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(3)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn3Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputFieldRC}
                                name='in3'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in3')}
                                value={this.state.in3}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Button
                                className={classes.textButton}
                                style={{backgroundColor:this.state.LDCBlocksOptions[this.state.renderType].color}}
                                variant="contained"
                                onClick={() => this.handleBtn(4)}
                                disabled={this.props.isDisabled}
                            >
                                {this.state.btn4Txt}
                            </Button>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in4'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in4')}
                                value={this.state.in4}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        </Grid>
                        <Grid 
                            container 
                            spacing={0}
                            className={classes.grid}
                            direction="row"
                        >
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text1}
                            </Typography>
                        </Grid>
                        <Grid item xs="auto">
                            <Input 
                                className={classes.inputField}
                                name='in5'
                                style={{
                                        width: this.state.LDCBlocksOptions[this.state.renderType].inWidth,
                                      }}
                                onChange = {this.handleIn}
                                onBlur = {(e) => this.handleInOnBlur(e, 'in5')}
                                value={this.state.in5}
                                disabled={this.props.isDisabled}
                                autoComplete="off"
                            />
                        </Grid>
                        <Grid item xs="auto">
                            <Typography 
                                className={classes.regularText}
                                style={{backgroundColor: this.state.LDCBlocksOptions[this.state.renderType].color}}
                            >
                                {this.state.LDCBlocksOptions[this.state.renderType].text2}
                            </Typography>
                        </Grid>
                        </Grid>
                        </React.Fragment>
                    )}

                </Box>
            </React.Fragment>
        );
    }



}


export default withStyles(styles)(LocoDroneCBlocks);