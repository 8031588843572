import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import InputLabel from "@material-ui/core/InputLabel";

import { AuthContext } from "../context/auth";

import LocoDroneT from "../libraries/LocoDroneT";
import LocoDroneTT from "../libraries/LocoDroneTT";

import { DRONE_RC_ITEMS } from "./store/drone_rc_store";
import Topbar from "./Topbar";
import LDTSettingsModal from "./modals/LDTSettingsModal";
import GeneralMessage from "./drawers/GeneralMessage";
import { Typography } from "@material-ui/core";

import lKeys from '../images/droneRC/WSAD_adobe_express.svg';
import rKeys from '../images/droneRC/IJKL_adobe_express.svg';
import fKeys from '../images/droneRC/flip-keys.svg'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    root: {            
        flexGrow: 1,
        backgroundColor: "white",
    },
    formControl: {
        width: "95%",
        justify: "center",
        margin: theme.spacing(0),
    },
    inputField: {
        width: "90%",
        marginLeft: theme.spacing(2),
    },
    inputHelper: {
        marginLeft: theme.spacing(4),
    },
    grid: {       
        width: '95%', 
        flexGrow: 1,
        margin: theme.spacing(4),
    },
    boxGraphs: {
        backgroundColor: theme.palette.primary["light"],
        //maxWidth: '90%',
    },
    instructBox: {
        backgroundColor: theme.palette.primary["light"],
    },
    speedBox: {
        backgroundColor: theme.palette.primary["light"],
        margin: '10px',
    },
    paper: {
        //flexGrow: 1,
        padding: theme.spacing(1),
        textAlign: "center",
        alignItems:"center",
        justifyContent:"center",
        color: theme.palette.primary,
        backgroundColor: theme.palette.primary["light"],
        maxWidth: '80%',
        borderRadius:'25px',
    },
    graphSpec: {
        alignItems:"center",
        justifyContent:"center",
        borderRadius:'25px',
        padding: 10,
        width: 500,
    },
    actionButton: {
        justify: "center",
        margin: theme.spacing(1),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
        borderRadius: '25px',
    },
    input: {
        justify: "center",
        margin: theme.spacing(2),
        color: theme.palette.primary["contrastText"],
        backgroundColor: theme.palette.primary["light"],
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        height: 150, // Set the height of the card
        maxWidth: 500, // Set a maximum width for the cards
        margin: 'auto', // Center the cards horizontally
        borderRadius: '10px',
    },
    cardLeft: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        height: 150, // Set the height of the card
        maxWidth: 500, // Set a maximum width for the cards
        margin: 'auto', // Center the cards horizontally
        borderRadius: '10px',
        transition: 'transform 0.3s, box-shadow 0.3s', // Add transition for smooth hover effect
        '&:hover': {
            transform: 'scale(1.05)', // Slightly scale up the card on hover
            boxShadow: theme.shadows[6], // Add a shadow effect on hover
        },
    },
    icon: {
        fontSize: 40,
        marginBottom: theme.spacing(1),
        //transition: 'transform 0.3s', // Add transition for smooth rotation
        //'&:hover': {
        //    transform: 'rotate(360deg)', // Rotate the icon on hover
        //},
        transition: 'color 0.2s', // Add transition for smooth effect
        '&:hover': {
            animation: '$moveBackAndForth 0.5s ease-in-out', // Apply animation on hover
        },
    },
    '@keyframes moveBackAndForth': {
        '0%': {
            transform: 'translateX(0)',
        },
        '25%': {
            transform: 'translateX(-5px)',
        },
        '50%': {
            transform: 'translateX(5px)',
        },
        '75%': {
            transform: 'translateX(-5px)',
        },
        '100%': {
            transform: 'translateX(0)',
        },
    },
    formControlSelect: {
        justify: "center",
        margin: theme.spacing(1),
    },
    gridSelect: {
        //marginTop: theme.spacing(4),
        background: `linear-gradient(45deg, ${theme.palette.tertiary.lighter} 30%, ${theme.palette.tertiary.lighter} 90%)`,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadius,
        height: '100%', // Take up the full height of the parent
        display: 'flex',
        flexDirection: 'row', // Ensure the cards stay in the same row
        alignItems: 'center', // items vertically
        justifyContent: 'flex-start', // Align items to the start
    },
    cardCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
        height: '50%', // Set the height of the card
        maxWidth: 500, // Set a maximum width for the cards
        margin: 'auto', // Center the cards horizontally
        borderRadius: '10px',
        transform: 'translateY(-25%)', // Offset items slightly above center
        transition: 'transform 0.3s, box-shadow 0.3s', // Add transition for smooth hover effect
        '&:hover': {
            transform: 'translateY(-25%) scale(1.05)', // Slightly scale up the card on hover
            boxShadow: theme.shadows[6], // Add a shadow effect on hover
        },
    },
    select: {
        width: 175,
        //'&:hover': {
        //    backgroundColor: theme.palette.tertiary.lighter,
        //},
        transition: 'border-width 0.2s', // Add transition for smooth effects
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.tertiary.lighter,
            borderWidth: '4px', // Change border thickness on hover
        }
    },
    menuItem: {
        '&:hover': {
            backgroundColor: theme.palette.tertiary.lighter,
        },
    }
  });


const CONFIG = {
    displaylogo: false,
    modeBarButtonsToRemove: [
        'zoom2d', 'pan2d', 'select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d', 'resetScale2d', 'hoverClosestCartesian', 'hoverCompareCartesian', 'zoom3d', 'pan3d', 'resetCameraDefault3d', 'resetCameraLastSave3d', 'hoverClosest3d', 'orbitRotation', 'tableRotation', 'zoomInGeo', 'zoomOutGeo', 'resetGeo', 'hoverClosestGeo', 'toImage', 'sendDataToCloud', 'hoverClosestGl2d', 'hoverClosestPie', 'toggleHover', 'resetViews', 'toggleSpikelines', 'resetViewMapbox'
    ]
}

const BAR_COLORS = [
                    '#f16749', 
                    '#fbc22d',
                    '#4caf50', 
                    '#8057c1', 
                ]

const VALUE_OFF_P = 1
const VALUE_OFF_N = -1
let KEY_MAP = {
    KeyI: {index: 3, value: VALUE_OFF_P, state: 0},
    KeyJ: {index: 2, value: VALUE_OFF_N, state: 0},
    KeyK: {index: 3, value: VALUE_OFF_N, state: 0},
    KeyL: {index: 2, value: VALUE_OFF_P, state: 0},
    KeyW: {index: 0, value: VALUE_OFF_P, state: 0},
    KeyA: {index: 1, value: VALUE_OFF_N, state: 0},
    KeyS: {index: 0, value: VALUE_OFF_N, state: 0},
    KeyD: {index: 1, value: VALUE_OFF_P, state: 0},
}
const USEFUL_KEYS = Object.keys(KEY_MAP)

const FLIP_KEYS_MAP = {
    KeyT: {value: "FLIP_FORWARD"},
    KeyF: {value: "FLIP_LEFT"},
    KeyG: {value: "FLIP_BACK"},
    KeyH: {value: "FLIP_RIGHT"},
}
const FLIP_KEYS = Object.keys(FLIP_KEYS_MAP)

const UPDATE_RC_RATE = 250
const BATT_LVL_ERROR = 11

const INSTRUCTIONS = {
    notConnected:"Power on your drone and plug in the USB Controller, then press Connect.",
    connecting: "Drone Connecting...",
    connected:"Press Takeoff when ready.",
    takingoff: "Drone takeoff started...\nKeyboard flight control is disabled.",
    flying:"Use the control keys to fly the drone.",
    landing: "Drone landing started...\nKeyboard flight control is disabled.",
}
const SPEED_OPTS = {
    Slow: {
        KeyI: 20, KeyK: 20, //pitch
        KeyJ: 20, KeyL: 20, //roll
        KeyW: 20, KeyS: 20, //throttle
        KeyA: 30, KeyD: 30, //yaw
    },
    Medium: {
        KeyI: 40, KeyK: 40, //pitch
        KeyJ: 40, KeyL: 40, //roll
        KeyW: 40, KeyS: 40, //throttle
        KeyA: 60, KeyD: 60, //yaw
    },
    Fast: {
        KeyI: 60, KeyK: 60, //pitch
        KeyJ: 60, KeyL: 60, //roll
        KeyW: 60, KeyS: 60, //throttle
        KeyA: 90, KeyD: 90, //yaw
    },
}

class DroneRC extends Component {
  
    static contextType = AuthContext;

    constructor(props) {
        super(props)

        this.updateID = null
        this.LDTConnMsgElement = React.createRef()
        this.LDTNoConnMsgElement = React.createRef()
        this.LDTScanMsgElement = React.createRef()
        this.LDTSelectionElement = React.createRef()

        this.graphRef = React.createRef();

        this.data = [{
            x: ['Throttle', 'Yaw', 'Roll', 'Pitch', ],
            y: [0, 0, 0, 0],
            name: 'RC State',
            type: 'bar',
            marker: {
                color: BAR_COLORS,
            }
        }]
        this.layout = {
            xaxis: {fixedrange: true, tickangle:45},
            yaxis: {
                fixedrange: true,
                autorange: false,
                range: [-100, 100],
            },
            paper_bgcolor:"rgba(0, 0, 0, 0)",
            font: {
                size: 22,
                color: '#ffffff'
            },
            height: 350,
        }
        this.updatingRC = false
    }

    state = {
        hOptions: [],
        hardwareSelection: "",
        selected: false,
        LRobj: null,
        isConnected: false, 
        connectText: "Connect",
        isFlying: false,
        flightText: "Takeoff",
        rcState: [0, 0, 0, 0],
        instrIndex: "notConnected",
        btnConnDis: false,
        btnFlyDis: true,
        speedStr: 'Slow',
        monitor_str: '',
        ldtTimeoutID: null,
        flipState: "",
        NaNCount: 0,
        updateFlightCond: false,
    };

  componentDidMount() {
    this.hardwareOptions()
    document.addEventListener('keydown', this.onKeyDown, false);
    document.addEventListener('keyup', this.onKeyUp, false);
    /*
    this.setState({isFlying: true})
    this.updateID = setTimeout(this.updateRC, UPDATE_RC_RATE)
    */
  }
  componentWillUnmount() {
    clearTimeout(this.updateID)
    try {
        this.state.LRobj.clearLDTTimeouts()
    } catch {}
    document.removeEventListener('keydown', this.onKeyDown, false);
    document.removeEventListener('keyup', this.onKeyUp, false);
    try {
        this.state.LRobj.disconnect_usb()
    } catch {}

  }

  resetKeys = () => {
    KEY_MAP = {
        KeyI: {index: 3, value: VALUE_OFF_P, state: 0},
        KeyJ: {index: 2, value: VALUE_OFF_N, state: 0},
        KeyK: {index: 3, value: VALUE_OFF_N, state: 0},
        KeyL: {index: 2, value: VALUE_OFF_P, state: 0},
        KeyW: {index: 0, value: VALUE_OFF_P, state: 0},
        KeyA: {index: 1, value: VALUE_OFF_N, state: 0},
        KeyS: {index: 0, value: VALUE_OFF_N, state: 0},
        KeyD: {index: 1, value: VALUE_OFF_P, state: 0},
    }
  }

  resetData = () => {

    this.setState( {
        selected: false,
        LRobj: null,
        isConnected: false, 
        connectText: "Connect",
        isFlying: false,
        flightText: "Takeoff",
        rcState: [0, 0, 0, 0],
        instrIndex: "notConnected",
        btnConnDis: false,
        btnFlyDis: true,
        speedStr: 'Slow',
        monitor_str: '',
        ldtTimeoutID: null,
        flipState: "",
        NaNCount: 0,
        updateFlightCond: false,
    })
    this.data = [{
        x: ['Throttle', 'Yaw', 'Roll', 'Pitch', ],
        y: [0, 0, 0, 0],
        name: 'RC State',
        type: 'bar',
        marker: {
            color: BAR_COLORS,
        }
    }]
    this.layout = {
        xaxis: {fixedrange: true, tickangle:45},
        yaxis: {
            fixedrange: true,
            autorange: false,
            range: [-100, 100],
        },
        paper_bgcolor:"rgba(0, 0, 0, 0)",
        font: {
            size: 22,
            color: '#ffffff'
        },
        height: 350,
    }
    this.updatingRC = false
  }

  hardwareOptions = () => {
    let hardware = []
    for (let j = 0; j < DRONE_RC_ITEMS.length; j++) {
        if (this.context.AuthInstance.userAccess.includes(DRONE_RC_ITEMS[j])) {
            hardware.push(DRONE_RC_ITEMS[j])
        }
    }
    this.setState({hOptions: hardware, hardwareSelection: hardware[0]})
  }

    openDisplay = () => {
        if (this.state.hardwareSelection === 'LocoDroneT') {
            let tempObj = new LocoDroneT();
            this.setState( {LRobj: tempObj} );
        } else if (this.state.hardwareSelection === 'LocoDroneTT') {
            let tempObj = new LocoDroneTT();
            this.setState( {LRobj: tempObj} );
        }
        this.updateID = setTimeout(this.changeSelect, 50);    
    }

    changeSelect = () => {
        this.setState( {selected: true} );
        this.updateID = setTimeout(this.createView, 50)
    }

    handleBack = () => {
        if (this.state.isConnected === true) {
            try {
                if (this.state.isFlying === true) {
                    this.setState({isFlying: false, flightText: "Takeoff"})
                    this.resetKeys()
                    this.state.LRobj.drone_land().then(() => {
                        this.state.LRobj.disconnect().then(() => {
                            try {
                                this.state.LRobj.clearLDTTimeouts()
                            } catch {}
                        })
                    })
                } else {
                    this.state.LRobj.disconnect().then(() => {
                        try {
                            this.state.LRobj.clearLDTTimeouts()
                        } catch {}
                    })
                }
            } catch {}
            this.setState( {isConnected: false, connectText: "Connect"} );
        } else {
            try {
                this.state.LRobj.clearLDTTimeouts()
            } catch {}
        }
        //this.setState( {selected: false} );
        this.resetData()
    }

    handleConnect = async() => {

        if (this.state.isConnected === false) {
            this.LDTConnMsgElement.current.handleOpenStay('blue', 'Connecting...');
            return this.state.LRobj.connect().then(() => {
                this.updateID = setTimeout(this.postConnectLDT, 250);
                this.setState( {connectText: "Connecting..."} );
            })
        } else {
            try {
                if (this.state.isFlying === true) {
                    this.resetKeys()
                    this.state.LRobj.drone_land().then(() => {
                        this.state.LRobj.disconnect().then(() => {
                            this.state.LRobj.clearLDTTimeouts()
                        })
                    })
                    this.setState({isFlying: false, flightText: "Takeoff"})
                } else {
                    this.state.LRobj.disconnect().then(() => {
                        this.state.LRobj.clearLDTTimeouts()
                    })
                }
                this.setState( {btnFlyDis: true} )
            } catch {}
            this.setState( {
                isConnected: false, 
                connectText: "Connect", 
                instrIndex: 'notConnected',
                monitor_str: '',
            } );
        }

    }

    postConnectLDT = () => {
        
        this.LDTConnMsgElement.current.handleClose();
        if (!window.serialport) {
            this.setState( {isConnected: false, connectText: "Connect"} );
            return
        }

        this.LDTScanMsgElement.current.handleOpenStay('green', 'Scanning...')
        this.state.LRobj.drone_scan().then((res) => {
            this.LDTScanMsgElement.current.handleClose();
            if (res === "timeout") {
                let msgStr = 'Error Completing Scan, Please Try Again. If The Problem Persists, Reset Your Controller.'
                this.LDTSelectionElement.current.handleMessage(msgStr, 4000)
            }
        });
    }

    openLDTSelection = () => {
        this.LDTSelectionElement.current.handleOpen("PICK", this.state.LRobj.sharedData.drones['scanData']);
    }

    LDTSelection = async (r_event) => {
        let targetIndex = -1
        for (let i = 0; i < this.state.LRobj.sharedData.drones['scanData'].length; i++) {
            if (this.state.LRobj.sharedData.drones['scanData'][i] === r_event) {
                targetIndex = i
                break
            }
        }
        if (targetIndex !== -1) {
            this.setState( {btnConnDis: true, instrIndex: "connecting"} )
            this.state.LRobj.connect_wifi(targetIndex).then((res) => {
                this.setState( {btnConnDis: false, btnFlyDis: false} )
                this.setState( {isConnected: true, connectText: "Disconnect", instrIndex: 'connected'} );
                this.updateID = setTimeout(this.checkData, UPDATE_RC_RATE)
            }).catch((err) => {
                this.LDTNoConnMsgElement.current.handleOpen('red', 'Could Not Connect To Drone, Please Try Again.', 1000);
                this.state.LRobj.clearLDTTimeouts()
                this.setState( {
                    btnFlyDis: true, btnConnDis: false, isConnected: false, 
                    connectText: "Connect", instrIndex: 'notConnected',
                    flightText: "Takeoff", isFlying: false,
                    monitor_str: '',
                } );
            });
        } else {
            await this.state.LRobj.disconnect_drone();
            await this.state.LRobj.disconnect();
            this.setState( {isConnected: false, connectText: "Connect", instrIndex: 'notConnected'} );
        }
    }

    handleFlight = async() => {
        this.setState({updateFlightCond: true})
        this.setState( {btnFlyDis: true, btnConnDis: true} )
    }

    /*
    handleFlight = async() => {
        if (this.state.isFlying === true) {
            this.resetKeys()
            this.setState( {btnFlyDis: true, btnConnDis: true, instrIndex: 'landing'} )
            this.state.LRobj.drone_rc(0,0,0,0).then(() => {
                this.state.LRobj.drone_land().then(() => {
                    this.setState( {btnFlyDis: false, btnConnDis: false} )
                    this.setState({flightText: "Takeoff", isFlying: false, instrIndex: 'connected'})
                })
            })
        } else if (this.state.isFlying === false) {

            var me = this;

            return new Promise(function(resolve, reject) {

                var f = async function() {
                    if (me.checkingData === true) {
                        me.ldtTimeoutID = setTimeout(f, 100);
                    } else if ((me.checkingData === false) && (me.state.isConnected === true)) {

                        const res = await me.state.LRobj.drone_get_data("DATA_BATTERY")
                        if (res <= BATT_LVL_ERROR) {
                            me.LDTNoConnMsgElement.current.handleOpen(
                                'red', 'Drone Battery Low, Please Charge Drone', 3000
                            );
                            resolve("done")
                        }
                        me.setState( {btnFlyDis: true, btnConnDis: true, instrIndex: 'takingoff'} )
                        me.state.LRobj.drone_takeoff().then(() => {
                            //document.addEventListener('keydown', me.onKeyDown, false);
                            //document.addEventListener('keyup', me.onKeyUp, false);
                            me.setState( {btnFlyDis: false, btnConnDis: false} )
                            me.setState({flightText: "Land", isFlying: true, instrIndex: 'flying'})
                            resolve("done")
                        })
                    } else {
                        resolve("done")
                    }
                }

                f()

            })

        }
    }
    */

    onKeyUp = (event) => {
        const C_REF = event.code
        let y = this.data[0].y
        if (USEFUL_KEYS.indexOf(C_REF) > -1) {
            y[KEY_MAP[C_REF].index] = 0
            KEY_MAP[C_REF].state = 0
            window.Plotly.update(this.graphRef.current, this.data, this.layout, CONFIG);
            this.setState( {rcState: [y[2], y[3], y[0], y[1]]} )
        }
    }

    onKeyDown = (event) => {
        const C_REF = event.code
        if (USEFUL_KEYS.indexOf(C_REF) > -1) {
            let y = this.data[0].y
            if (KEY_MAP[C_REF].state === 0) {
                y[KEY_MAP[C_REF].index] = SPEED_OPTS[this.state.speedStr][C_REF] * KEY_MAP[C_REF].value
                this.data[0].y = y
                window.Plotly.update(this.graphRef.current, this.data, this.layout, CONFIG);
                if (this.state.isFlying === true) {
                    this.setState( {rcState: [y[2], y[3], y[0], y[1]]} )
                }
                KEY_MAP[C_REF].state = 1
            }
        } else if (FLIP_KEYS.indexOf(C_REF) > -1) {
            this.setState( {flipState: FLIP_KEYS_MAP[C_REF].value} )
        }
    }

    createView = async () => {
        await window.Plotly.newPlot(this.graphRef.current, this.data, this.layout, CONFIG);
    }

    updateRC = async () => {
        //UPDATE_RC_RATE
        this.updatingRC = true
        const ns = this.state.rcState
        try {
            //console.log(ns)
            await this.state.LRobj.drone_rc(ns[0], ns[1], ns[2], ns[3])
            //if (this.state.isFlying === true) {
            //    this.updateID = setTimeout(this.updateRC, UPDATE_RC_RATE)
            //}
            this.updatingRC = false
        } catch {
            this.updatingRC = false
        }
        if (this.state.flipState !== "") {
            if (this.state.LRobj.battery_last > 50) {
                try {
                    await this.state.LRobj.drone_flip(this.state.flipState)
                    this.setState( {flipState: ""})
                    this.updatingRC = false
                } catch {
                    this.updatingRC = false
                }
                this.setState( {flipState: ""})
            } else {
                this.setState( {flipState: ""})
                this.LDTNoConnMsgElement.current.handleOpen(
                    'red', 'Battery Below 50%. Flip Is Disabled.', 1000
                );
            }
        }
    }

    handleSpeed = async(name) => {
        this.setState( {speedStr: name} )
    }

    checkBatteryAndTakeoff = async() => {
        var me = this;

        return new Promise(function(resolve, reject) {

            var f = async function() {
                if (me.checkingData === true) {
                    me.ldtTimeoutID = setTimeout(f, 100);
                } else if ((me.checkingData === false) && (me.state.isConnected === true)) {

                    const res = await me.state.LRobj.drone_get_data("DATA_BATTERY")
                    if (res <= BATT_LVL_ERROR) {
                        me.LDTNoConnMsgElement.current.handleOpen(
                            'red', 'Drone Battery Low, Please Charge Drone.', 3000
                        );
                        resolve("fail")
                    }
                    me.setState( {btnFlyDis: true, btnConnDis: true, instrIndex: 'takingoff'} )
                    me.state.LRobj.drone_takeoff().then(() => {
                        //document.addEventListener('keydown', me.onKeyDown, false);
                        //document.addEventListener('keyup', me.onKeyUp, false);
                        me.setState( {btnFlyDis: false, btnConnDis: false} )
                        me.setState({flightText: "Land", isFlying: true, instrIndex: 'flying'})
                        resolve("done")
                    })
                } else {
                    resolve("done")
                }
            }

            f()

        })
    }

    stopAndLand = async() => {

        /*
        this.resetKeys()
        this.setState( {btnFlyDis: true, btnConnDis: true, instrIndex: 'landing'} )

        this.state.LRobj.drone_rc(0,0,0,0).then(() => {
            this.state.LRobj.drone_land().then(() => {
                this.setState( {btnFlyDis: false, btnConnDis: false} )
                this.setState({flightText: "Takeoff", isFlying: false, instrIndex: 'connected'})
            })
        })
        */

        var me = this;

        return new Promise(function(resolve, reject) {

            var f = async function() {

                await me.state.LRobj.drone_rc(0,0,0,0)
                me.ldtTimeoutID = setTimeout(m, 100);
            }

            var m = async function() {

                await me.state.LRobj.drone_land()
                
                me.setState( {btnFlyDis: false, btnConnDis: false} )
                me.setState({flightText: "Takeoff", isFlying: false, instrIndex: 'connected'})
                resolve("done")
            }

            f();
        });

    }


    checkData = async () => {

        /*
        if (this.state.btnFlyDis === true) {
            if (this.state.isConnected) {
                this.updateID = setTimeout(this.checkData, UPDATE_RC_RATE)
            }
            return
        }*/

        // Check for takeoff or land ready
        if (this.state.updateFlightCond === true) {
            this.setState({updateFlightCond: false})
            if (this.state.isFlying === true) {
                
                await this.stopAndLand()
                if (this.state.isConnected) {
                    this.updateID = setTimeout(this.checkData, 3000)
                }
                return
            } else if (this.state.isFlying === false) {

                await this.checkBatteryAndTakeoff()
            }

        }

        let NaNRepeat = false

        this.checkingData = true
        const res1 = await this.state.LRobj.drone_get_data("DATA_BATTERY")
        if (res1 === "failure") {
                
            if (this.state.isConnected) {
                this.LDTNoConnMsgElement.current.handleOpen(
                    'red', 'Lost Communication with Drone. Disconnecting.', 7000
                );
                if (this.state.isFlying === true) {
                    this.resetKeys()
                    this.setState( {btnFlyDis: false, btnConnDis: false} )
                    this.setState({flightText: "Takeoff", isFlying: false, instrIndex: 'connected'})
                }
                await this.state.LRobj.disconnect().then(() => {
                    this.state.LRobj.clearLDTTimeouts()
                    this.setState( {
                        btnFlyDis: true, isConnected: false, 
                        connectText: "Connect", instrIndex: 'notConnected',
                        flightText: "Takeoff", isFlying: false, instrIndex: 'connected',
                        monitor_str: '',
                    } );
                });
            }
            return
        }

        let testStrBatt = "Battery Level (%) " + this.state.LRobj.battery_last
        if (testStrBatt.includes("NaN")) {
            await this.state.LRobj.drone_get_data("DATA_BATTERY")
            testStrBatt = "Battery Level (%) " + this.state.LRobj.battery_last
            
            if (testStrBatt.includes("NaN")) {
                NaNRepeat = true
            }
        }

        const res2 = await this.state.LRobj.drone_get_data("DATA_TEMP")
        if (res2 === "failure") {
            
            if (this.state.isConnected) {
                this.LDTNoConnMsgElement.current.handleOpen(
                    'red', 'Lost Communication with Drone. Disconnecting.', 7000
                );
                if (this.state.isFlying === true) {
                    this.resetKeys()
                    this.setState( {btnFlyDis: false, btnConnDis: false} )
                    this.setState({flightText: "Takeoff", isFlying: false, instrIndex: 'connected'})
                }
                await this.state.LRobj.disconnect().then(() => {
                    this.state.LRobj.clearLDTTimeouts()
                    this.setState( {
                        btnFlyDis: true, isConnected: false, 
                        connectText: "Connect", instrIndex: 'notConnected',
                        flightText: "Takeoff", isFlying: false, instrIndex: 'connected',
                        monitor_str: '',
                    } );    
                });
            }
            return
        }

        let testStrTemp = "Temperature (C): " + this.state.LRobj.temperature_last
        if (testStrTemp.includes("NaN")) {
            await this.state.LRobj.drone_get_data("DATA_TEMP")
            testStrTemp = "Temperature (C): " + this.state.LRobj.temperature_last
            if (testStrTemp.includes("NaN")) {
                NaNRepeat = true
            }
        }

        this.checkingData = false
        if ((res1 !== "failure") && (res2 !== "failure")) {
            const msg_str = "Battery Level (%) " + this.state.LRobj.battery_last + 
                            ",  Temperature (C): " + this.state.LRobj.temperature_last
            
            if (this.state.LRobj.battery_last <= BATT_LVL_ERROR) {
                this.LDTNoConnMsgElement.current.handleOpen(
                    'red', 'Drone Battery Low, Please Charge Drone.', 3000
                );
                if (this.state.isFlying === true) {
                    try {
                        await this.state.LRobj.drone_land()
                    } catch {}
                }
                await this.state.LRobj.disconnect()
                this.resetKeys()
                this.state.LRobj.clearLDTTimeouts()
                this.setState( {
                    btnFlyDis: true, isConnected: false, 
                    connectText: "Connect", instrIndex: 'notConnected',
                    flightText: "Takeoff", isFlying: false, instrIndex: 'connected',
                    monitor_str: '',
                } );
                    //this.resetKeys()
                    //this.setState( {btnFlyDis: false, btnConnDis: false} )
                    //this.setState({flightText: "Takeoff", isFlying: false, instrIndex: 'connected'})
                //}
                return
            }

            
            //if (msg_str.includes("NaN")) {
                //if (this.state.NaNCount > 10) {
            if (NaNRepeat === true) {
                this.LDTNoConnMsgElement.current.handleOpen(
                    'red', 'Drone Communication Degraded, Data May Be Inaccurate. Disconnecting.', 8000
                );
                if (this.state.isFlying === true) {
                    try {
                        await this.state.LRobj.drone_land()
                    } catch {}
                    this.resetKeys()
                    this.setState( {btnFlyDis: false, btnConnDis: false} )
                    this.setState({flightText: "Takeoff", isFlying: false, instrIndex: 'connected'})
                }
                await this.state.LRobj.disconnect().then(() => {
                    this.state.LRobj.clearLDTTimeouts()
                    this.setState( {
                        btnFlyDis: true, isConnected: false, 
                        connectText: "Connect", instrIndex: 'notConnected',
                        flightText: "Takeoff", isFlying: false, instrIndex: 'connected',
                        monitor_str: '',
                    } );
                });
                return
            }
                /*
                } else {
                    const tempCount = this.state.NaNCount + 1
                    this.setState({
                        monitor_str: "Drone Communication Degraded",
                        NaNCount: tempCount,
                    })
                }
                */
            //} else {
            //    this.setState({
            //        NaNCount: 0,
            //        monitor_str: msg_str
            //    })
            //}
            this.setState( {monitor_str: msg_str} )
            
    
            if (this.state.isFlying === true) {
                await this.updateRC()
            }
    
            if (this.state.isConnected) {
                this.updateID = setTimeout(this.checkData, UPDATE_RC_RATE)
            }
        }
    }

    handleChange = event => {
        this.setState( {[event.target.name]: event.target.value} );
    };

  render() {
    const { classes } = this.props;

    // For TopBar Route Highlight
    const currentPath = this.props.location.pathname;

    // What to Display - Selectable Views Based on activeStep
    return (
        <div className={classes.root}    
            style={{ height: '100vh', maxHeight: '100vh', backgroundColor: 'white', overflow: 'hidden'}}>
            <CssBaseline />
            <Topbar currentPath={currentPath} />
            {this.state.selected === false && (
                <Grid container justifyContent="center" className={classes.gridSelect}>
                    <Grid item xs={12} md={12}>
                        <Card className={classes.cardCenter}>
                            <Grid container justifyContent="center" alignItems="center">
                                <Grid item>
                                    <SportsEsportsIcon className={classes.icon} color="primary" />
                                </Grid>
                                <Grid item>
                                    <CardContent>
                                        <Typography variant="h5" component="h2">
                                            Drone Remote Control
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            Choose an option and press select to continue.
                                        </Typography>
                                    </CardContent>
                                </Grid>
                                <Grid item>
                                    <FormControl
                                        variant="outlined"
                                        className={classes.formControlSelect}
                                    >
                                        <InputLabel id="hardware-select-label">Hardware</InputLabel>
                                        <Select
                                            className={classes.select}
                                            labelId="hardware-select-label"
                                            label="Hardware"
                                            name="hardwareSelection"
                                            value={this.state.hardwareSelection}
                                            onChange={this.handleChange}
                                            aria-describedby="hardware-pn-helper-text"
                                        >
                                            {this.state.hOptions.map((val) => {
                                                return (
                                                    <MenuItem key={val} value={val}
                                                        className={classes.menuItem}
                                                    >
                                                        {val}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.actionButton}
                                        onClick={this.openDisplay}
                                    >
                                        Select
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            )}
            {this.state.selected === true && (
                <React.Fragment>
                <Grid
                    spacing = {1}
                    justifyContent="center"
                    container
                    className={classes.grid}
                >
                    <Grid item xs={12} container style={{justifyContent:"space-around"}}>
                        <div style = {{display:'flex', 
                            borderRadius:'25px'}}
                            className={classes.instructBox}>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className={classes.actionButton}
                                    onClick={this.handleConnect}
                                    disabled={this.state.btnConnDis}
                                >
                                    {this.state.connectText}
                                </Button>     
                            </FormControl>
                            <FormControl
                                variant="outlined"
                                className={classes.formControl}
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={this.state.btnFlyDis}
                                    className={classes.actionButton}
                                    onClick={this.handleFlight}
                                >
                                    {this.state.flightText}
                                </Button>     
                            </FormControl>
                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.actionButton}
                                onClick={this.handleBack}
                            >
                                Back
                            </Button>
                        </div>
                        <div
                            style = {{
                                display: 'flex', 
                                justifyContent: 'space-around',
                                borderRadius:'25px'
                            }}
                            className={classes.instructBox}
                        >
                            <Button
                                className={classes.actionButton}
                                name= "Slow"
                                onClick={() => {this.handleSpeed("Slow")}}
                                style = {{
                                    backgroundColor: 
                                        this.state.speedStr === 'Slow' ?
                                            "blue" : "green"
                                }}
                            >
                                Slow
                            </Button>
                            <Button
                                className={classes.actionButton}
                                name= "Medium"
                                onClick={() => {this.handleSpeed("Medium")}}
                                style = {{
                                    backgroundColor: 
                                        this.state.speedStr === 'Medium' ?
                                            "blue" : "green"
                                }}
                            >
                                Medium
                            </Button>
                            <Button
                                className={classes.actionButton}
                                name= "Fast"
                                onClick={() => {this.handleSpeed("Fast")}}
                                style = {{
                                    backgroundColor: 
                                        this.state.speedStr === 'Fast' ?
                                            "blue" : "green"
                                }}
                            >
                                Fast
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} container style={{justifyContent:"center"}}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius='25px'
                            className={classes.instructBox}
                            style = {{maxHeight: '125px', width: 700}}
                        >
                            <Typography
                                style = {{
                                    padding: 5, color:"white", fontSize: '34px', 
                                    textAlign: 'center', whiteSpace: 'pre-line'
                                }}
                            >
                                {INSTRUCTIONS[this.state.instrIndex]}
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} container style={{justifyContent:"center"}}>
                        <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            borderRadius='25px'
                            className={classes.instructBox}
                            style = {{minHeight: '40px', width: 700}}
                        >
                            <Typography
                                style = {{padding: 5, color:"white", fontSize: '22px', textAlign: 'center'}}
                            >
                                {this.state.monitor_str}
                            </Typography>
                        </Box>
                    </Grid>
                    </Grid>
                    <div style = {{display:'flex', justifyContent:"center"}}>
                        <img src={lKeys} 
                            alt=""
                            height='300px'>
                        </img>
                        <div style = {{display:'inline'}}>
                            <Box
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                borderRadius='25px'
                                className={classes.boxGraphs}
                            >
                                <div className = {classes.graphSpec} ref={this.graphRef}></div>
                            </Box>
                        </div>
                        <img src={rKeys} 
                            alt=""
                            height='300px'>
                        </img>
                    </div>
                    <Grid item xs={12} container style={{justifyContent:"space-around"}}>
                        <img src={fKeys} 
                            alt=""
                            height='200px'>
                        </img>
                    </Grid>
                    </React.Fragment>
            )}
            <LDTSettingsModal
                ref = {this.LDTSelectionElement}
                onClose= {this.LDTSelection}
            />
            <GeneralMessage
                ref = {this.LDTScanMsgElement}
                onClose = {this.openLDTSelection}
            />
            <GeneralMessage
                ref = {this.LDTConnMsgElement}
                onClose = {() => void 0}
            />
            <GeneralMessage
                ref = {this.LDTNoConnMsgElement}
                onClose = {() => void 0}
            />
        </div>
    );
  }
}

export default withRouter(withStyles(styles)(DroneRC));
