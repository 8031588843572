// Child Component of: 
// --- Program

// Import React and Material-UI Modules
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core';
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';

import { AuthContext } from "../context/auth";


import NameProgram from "./drawers/NameProgram";

// Material-UI CSS-type Style Specifications
const styles = theme => ({
    form: {
        width: '100%', // Ensure the form takes up the full width
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center', // Center the form contents
        marginBottom: theme.spacing(3), // Add spacing between FormControl components
    },
    formControl: {
        minWidth: 100,
        width: '90%', // Ensure the FormControl takes up the full width of the Grid item
        marginBottom: theme.spacing(3), // Add spacing between FormControl components
    },
    inputField: {
        width: "90%",
        //marginLeft: theme.spacing(4),
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.light, // Change border color on hover
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main, // Ensure border color is visible on focus
        },
        '& .MuiOutlinedInput-input': {
            color: theme.palette.text.primary, // Ensure text color is visible
        },
        '& .MuiOutlinedInput-input:focus': {
            color: theme.palette.text.primary, // Ensure text color is visible on focus
        },
    },
    inputText: {
        fontSize: '22px',
    },
    actionButton: {
        type: "submit",
        justify: "center",
        margin: theme.spacing(1),
        color: theme.palette.primary["contrastText"],
        backgroundColor: "green",
        fontSize: '15px',
        borderRadius: '10px',
        width: '50%',       
        transition: 'transform 0.2s', // Add transition for smooth scaling
        '&:hover': {
            transform: 'scale(1.1)', // Scale up slightly on hover
        },
    },
    selectItemText: {
        fontSize: '20px',
        '&:hover': {
            backgroundColor: theme.palette.action.hover, // Change background color on hover
        },
    },
    selectHover: {
        fontSize: '20px',
        '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.primary.main,
        }
    },
    scrollableContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        //overflowY: 'auto',
        //overflowX: 'hidden',
        //maxHeight: `calc(100vh - ${window.navBarOffset} - ${window.programBarOffset})`, // Adjust height dynamically
        //height: `calc(100vh - ${window.navBarOffset} - ${window.programBarOffset})`, // Ensure container takes up space
        //height: '400px',
    },
});


// Don't like doing it like this but oh well, update when needed
var options = {
    "PythonOnly": ['Python'],
    "LocoDrone": ['GUI', 'Python'],
    "LocoDroneT": ['GUI', 'Python'],
    "LocoDroneTT": ['GUI', 'Python'],
    "LocoArm": ['Python'],
    "LocoArmS": ['Python'],
    "LocoArmM": ['Python'],
    "LocoArmMS": ['Python'],
    "LocoIoT": ['Python'],
    "LocoWear": ['Python'],
    "LocoXtreme": ['GUI','Python'],
    "LocoScout": ['GUI','Python'],
    "LocoCraze": ['GUI','Python'],
    "LocoDroneC": ['GUI','Python'],
    "LocoHex": ['GUI','Python'],
}


// Component Class - ProgramCreate
class ProgramCreate extends Component {

    static contextType = AuthContext;


    // Class state
    state = {
        nameProgramElement: React.createRef(),
        programLanguage: options[this.context.AuthInstance.userAccess[0]][0],
        hardwareSelection: this.context.AuthInstance.userAccess[0],
        nameBuffer: "",
    };

    // General "state" Update handler
    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
        if (event.target.name === 'hardwareSelection') {
            this.setState( {programLanguage: options[event.target.value][0]} );
        }
    };

    // Create Program button pressed
    createProgram = event => {
        if (/^[a-zA-Z0-9_]+$/.test(this.state.nameBuffer)) {
            // Send Information to Parent component: Program
            let selection = {language: this.state.programLanguage, name: this.state.nameBuffer, instructions: "", revision: 1, deleted: false};
            var progDetails = {type: "new", selection: selection, hardware: this.state.hardwareSelection};
            this.props.onCreateProgram(progDetails);
        } else {
            this.state.nameProgramElement.current.handleOpen();
        }
    }

    closeBadName = () => {
        this.state.nameProgramElement.current.handleClose();
    }

    // Handler to update text stored in "state" when Input field text for program name is changed
    handleNameUpdate = event => {
        this.setState({nameBuffer: event.target.value});
    };

    // Takes input data, returns what to display
    render() {
        
        // Referenced below for setting styles
        const { classes } = this.props;
        
        // What to Display - Program name Input field, drop-down Select program type, create program Button
        return (
            <div className={classes.scrollableContainer}>
                <FormControl fullWidth className={classes.form}>
                    <TextField 
                      className={classes.inputField}
                      label="Program Name"
                      variant="outlined"
                      id="standard-adornment-pn"
                      aria-describedby="standard-pn-helper-text"
                      inputProps={{
                        'aria-label': 'pn',
                      }}
                      InputProps={{
                            classes: {
                                input: classes.inputText, // Apply custom font size to input
                            },
                        }}
                      onChange = {this.handleNameUpdate}
                      autoComplete="off"
                    />
                </FormControl>
                <FormControl
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel id="hardware-select-label">Product Type</InputLabel>
                    <Select
                        labelId="hardware-select-label"
                        label="Hardware"
                        name="hardwareSelection"
                        value={this.state.hardwareSelection}
                        onChange={this.handleChange}
                        aria-describedby="hardware-pn-helper-text"
                        className={classes.selectHover}
                    >
                        {this.context.AuthInstance.userAccess.map((val) => {
                            return (
                                <MenuItem key={val} value={val} className={classes.selectItemText}>
                                    {val}
                                </MenuItem>
                            );
                        })}                        
                    </Select>
                </FormControl>
                <FormControl
                    variant="outlined"
                    className={classes.formControl}
                >
                    <InputLabel id="program-select-label">Program Type</InputLabel>
                    <Select
                        labelId="program-select-label"
                        label="Program Type"
                        name="programLanguage"
                        value={this.state.programLanguage}
                        onChange={this.handleChange}
                        aria-describedby="progtype-pn-helper-text"
                        className={classes.selectHover}
                    >
                        {options[this.state.hardwareSelection].map((val) => {
                            return (
                                <MenuItem key={val} value={val} className={classes.selectItemText}>
                                    {val}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </FormControl>
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.actionButton}
                    onClick={this.createProgram}
                >
                    Create Program
                </Button>
                <NameProgram
                    ref={this.state.nameProgramElement}
                    onClose={this.closeBadName}
                />
            </div>

        );
    }
}

export default withStyles(styles)(ProgramCreate);
