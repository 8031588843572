import { sleep } from '../../helpers/index.js';
import { CRC16, DataType, DeviceType, Header, ModeFlight, ControlQuad8, CommandType, Command, Receiver, StateLoading, RequestLDC, Joystick, State, Ping, Error, Ack, FlightEvent, ControlPosition, Altitude, Range, Motion, Position, LightModeController, LightModeDrone, LightModeColor, Buzzer, BuzzerMode, Note, ErrorFlagsForSensor, RawFlow, ErrorFlagsForState, Trim } from './LocoDroneCExtras.js';


const CDE_CONTROLLER_VID = 1155

const MAX_REQ_RETRIES = 3
const REQ_TIMEOUT = 3000 // ms
const REQ_CHECK_INTV = 50 // ms

class LocoDroneC {

    constructor() {
        this.port = null;
        this.reader = null;
        this.writer = null;
        
        this.can_process = false;
        
        this._bufferQueue = [];
        this._bufferHandler = [];

        this.timeStartProgram = Date.now(); // Program Start Time Recording

        this.eventHandlers = {};

        this.systemTimeMonitorData = 0;
        this.monitorData = [];
        this.informationData = [0.0, 0, "", 0, ""]; // timestamp, Drone model, drone firmware, controller model, controller firmware
        this.addressData = [0.0, 0, 0]; // timestamp, Drone address, controller address
        this.altitudeData = [0, 0, 0, 0, 0];
        this.motionData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.state_data = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.positionData = [0, 0, 0, 0];
        this.flowData = [0, 0, 0];
        this.rangeData = [0, 0, 0];
        this.joystickData = [0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.colorData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        this.trimData = [0, 0, 0, 0, 0];
        this.waypointData = [];
        this.previousLand = [0, 0];
        this.buttonData = [0, 0, 0];
        this.initPress = 0;
        this.errorData = [0.0, 0, 0];
        this.countData = [0.0, 0, 0, 0, 0]; // timestamp, time of flight, takeoff count, landing count, accident count

        this.x = 0; this.y = 0; this.z = 0; this.yaw = 0; this.pitch = 0; this.roll = 0;

        this.isFlying = false;

        this.waiting = false;
        this.respSuccess = false;

        this._control = new ControlQuad8();

        // Set the event handlers for the sensor requests
        this.setEventHandler("Altitude", this.updateAltitudeData);
        this.setEventHandler("State", this.updateStateData);
        this.setEventHandler("Motion", this.updateMotionData);
        this.setEventHandler("Position", this.updatePositionData);
        this.setEventHandler("RawFlow", this.updateFlowData);
        this.setEventHandler("Range", this.updateRangeData);
        this.setEventHandler("Joystick", this.updateJoystickData);
        //this.setEventHandler("CardColor", this.updateColorData);
        this.setEventHandler("Trim", this.updateTrimData);
        //this.setEventHandler(DataType.Button, this.buttonEvent);
        this.setEventHandler("Error", this.updateErrorData);
        this.setEventHandler("Information", this.updateInformation);
        //this.setEventHandler(DataType.Address, this.receiveAddressData);
        //this.setEventHandler(DataType.Count, this.updateCountData);
        this.parseMapping = {
            "Joystick": Joystick,
            "State": State,
            "Ping": Ping,
            "Position": Position,
            "Error": Error,
            "Ack": Ack,
            "Altitude": Altitude,
            "Range": Range,
            "Motion": Motion,
            "RawFlow": RawFlow,
            "Trim": Trim,
        };

        this._receiver = new Receiver();

        // For Py Refs
        this.DATA_TYPES = {
            ATTITUDE: "ATTITUDE",
            POSITION: "POSITION",
            BATTERY: "BATTERY",
            RANGE: "RANGE",
            ALTITUDE: "ALTITUDE",
            ACCELEROMETER: "ACCELEROMETER",
            GYROSCOPE: "GYROSCOPE",
            RAW_FLOW: "RAW_FLOW",
        };

        this.MOVE_DIRECTIONS = {
            FORWARD: 'forward',
            BACKWARD: 'backward',
            LEFT: 'left',
            RIGHT: 'right',
            UP: 'up',
            DOWN: 'down',
        };

        this.FLIP_DIRECTIONS = {
            FORWARD: 'forward',
            BACKWARD: 'backward',
            LEFT: 'left',
            RIGHT: 'right',
        };

        this.CIRCLE_DIRECTIONS = {
            LEFT: -1,
            RIGHT: 1,
        };
                
        this.COMPLETE_RES = "OK"
        this.FAILED_RES = "FAILED"



        // For DataVis
        
        this.bufferSize = 100;

        this.optionNames = [
            'Attitude', 'Range', 'Position', 'Battery', 'Altitude', 'Accelerometer', 'Gyroscope', 'Flow'
        ];

        this.options = {
            'Attitude': {refer: 'READ_ATT', count: 3, range: ['min', 'min', 'min'], offset: 2},
            'Range': {refer: 'READ_RANGE', count: 2, range: ['min', 'min'], offset: 10},
            'Position': {refer: 'READ_POS', count: 3, range: ['min', 'min', 'min'], offset: 1.2},
            'Battery': {refer: 'READ_BATTERY', count: 1, range: ['fixed'], fixed: [0, 100]},
            'Altitude': {refer: 'READ_ALT', count: 3, range: ['min', 'min', 'min'], offset: 2},
            'Accelerometer': {refer: 'READ_ACC', count: 3, range: ['min', 'min', 'min'], offset: 2},
            'Gyroscope': {refer: 'READ_GYR', count: 3, range: ['min', 'min', 'min'], offset: 2},
            'Flow': {refer: 'READ_FLOW', count: 2, range: ['min', 'min'], offset: 2},
        };
        
        this.self = {
            READ_ATT: {
                funcref: ['getMotionData'],
                varref: ['motionData', 'motionData', 'motionData'],
                varIndref: [7, 8, 9],
                names: ['Roll', 'Pitch', 'Yaw'],
                ylabels: ["Roll (deg)", "Pitch (deg)", "Yaw (deg)"],
                arrays: ['rArray', 'pArray', 'yArray'],
            },
            READ_RANGE: {
                funcref: ['getRangeData'],
                varref: ['rangeData', 'rangeData'],
                varIndref: [1, 2],
                names: ['Front', 'Bottom'],
                ylabels: ["Front (mm)", "Bottom (mm)"],
                arrays: ['rngFront', 'rngBottom'],
            },
            READ_BATTERY: {
                funcref: ['getStateData'],
                varref: ['state_data'],
                varIndref: [6],
                names: ['Battery'],
                ylabels: ["Battery (%)"],
                arrays: ['batArray'],
            },
            READ_ALT: {
                funcref: ['getAltitudeData'],
                varref: ['altitudeData', 'altitudeData', 'altitudeData'],
                varIndref: [1, 2, 3],
                names: ['Temperature', 'Pressure', 'Altitude'],
                ylabels: ["Temperature (C)", "Pressure (Pascals)", "Altitude ASL (m)"],
                arrays: ['tempArray', 'pressArray', 'altArray'],
            },
            READ_ACC: {
                funcref: ['getMotionData'],
                varref: ['motionData', 'motionData', 'motionData'],
                varIndref: [1, 2, 3],
                names: ['Acc X', 'Acc Y', 'Acc Z'],
                ylabels: ["X (g)", "Y (g)", "Z (g)"],
                arrays: ['axArray', 'ayArray', 'azArray'],
            },
            READ_GYR: {
                funcref: ['getMotionData'],
                varref: ['motionData', 'motionData', 'motionData'],
                varIndref: [4, 5, 6],
                names: ['Gyr X', 'Gyr Y', 'Gyr Z'],
                ylabels: ["X (dps)", "Y (dps)", "Z (dps)"],
                arrays: ['gxArray', 'gyArray', 'gzArray'],
            },
            READ_FLOW: {
                funcref: ['getFlowData'],
                varref: ['flowData', 'flowData'],
                varIndref: [1, 2],
                names: ['X', 'Y'],
                ylabels: ["X", "Y"],
                arrays: ['fxArray', 'fyArray'],
            },
            rArray: [],
            pArray: [],
            yArray: [],
            rngFront: [],
            rngBottom: [],
            batArray: [],
            tempArray: [],
            pressArray: [],
            altArray: [],
            axArray: [],
            ayArray: [],
            azArray: [],
            gxArray: [],
            gyArray: [],
            gzArray: [],
            fxArray: [],
            fyArray: [],
        };
    }

    resetArrays = () => {
        this.self.rArray = []
        this.self.pArray = []
        this.self.yArray = []
        this.self.rngFront = []
        this.self.rngBottom = []
        this.self.batArray = []
        this.self.tempArray = []
        this.self.pressArray = []
        this.self.altArray = []
        this.self.axArray = []
        this.self.ayArray = []
        this.self.azArray = []
        this.self.gxArray = []
        this.self.gyArray = []
        this.self.gzArray = []
        this.self.fxArray = []
        this.self.fyArray = []
    }
    get_data_array = (type) => {
        return type.map(name => this.self[name]);
    };
    set_buffer_size = (buffer_size) => {
        this.bufferSize = buffer_size
    }
    get_buffer_size = () => {
        return this.bufferSize
    }

    setEventHandler(dataType, handler) {
        this.eventHandlers[dataType] = handler.bind(this);
    }

    send_command = async (ref_obj) => {

        const {varref, varIndref, arrays} = ref_obj;
        // Create shallow copies
        const arraysCopy = [...arrays];
        const varrefCopy = [...varref];
        const varIndrefCopy = [...varIndref];
        const funcref = ref_obj.funcref[0];

        // call function
        await this[funcref]();

        // ex arrays: arrays: ['rArray', 'pArray', 'yArray'],
        for (let i = 0; i < varrefCopy.length; i++) {

            const val = this[varrefCopy[i]][varIndrefCopy[i]];
            this.self[arraysCopy[i]].push(val);
            if (this.self[arraysCopy[i]].length > this.bufferSize) {
                this.self[arraysCopy[i]].shift();
            }
        }
        //console.log(varrefCopy[0]);
        //console.log(this[varrefCopy[0]]);
        //console.log(this.self[arraysCopy[0]]);

    };

    connect = async() => {

        // Reset some things
        this._bufferQueue = [];
        this._bufferHandler = [];
        this._receiver = new Receiver();


        try {
            const usbVendorId = CDE_CONTROLLER_VID;
            this.port = await navigator.serial.requestPort({ filters: [{ usbVendorId }]});
            await this.port.open({ baudRate: 57600 });
            this.reader = this.port.readable.getReader();
            this.writer = this.port.writable.getWriter();
            console.log('Connected to the serial port');
            
            // Start Read Loop
            this.readLoop();
            //this.can_process = true;

            // Check State Data
            let stateFlight = null;


            // ignore initial messages
            await sleep(2000);
            // start listening for messages
            this.can_process = true;
            
            let state = null;
            for (let i = 0; i < 10; i++) {
                state = await this.getStateData();
                stateFlight = state[2];
                if (ModeFlight[stateFlight] === ModeFlight.Ready) {
                    break;
                } else {
                    await sleep(100);
                }   
            }

            if (ModeFlight[stateFlight] === ModeFlight.Ready) {

                //device_info = self.get_information_data()
                const battery = state[6]
                console.log('Battery:', battery)

                // set the speed to medium
                await this.speedChange(2);
                await sleep(200);

                // reset yrpt commands
                await this.sendControl(0, 0, 0, 0);
                await sleep(200);

                // reset the gyro
                //await this.resetGyro();

            } else {
                console.log("Failed to connect to the drone");
                
                // disconnect from the serial port
                await this.disconnect();

                return false;
            }
            return true;

        } catch (error) {
            console.error('Failed to connect to the serial port:', error);

            // disconnect from the serial port
            await this.disconnect();

            return false;
        }
    };
    
    disconnect = async() => {
        try {
            this.can_process = false;
            if (this.reader) {
                if (this.port && this.port.readable && this.port.readable.locked) {
                    await this.reader.cancel();
                    this.reader.releaseLock();
                }
            }
            if (this.writer) {
                await this.writer.releaseLock();
            }
            if (this.port) {
                if (this.port.readable.locked) {
                    await this.reader.cancel();
                    this.reader.releaseLock();
                }
                await this.port.close();
                this.port = null;
            }
            console.log('Disconnected from the serial port');
        } catch (error) {
            console.error('Failed to disconnect from the serial port:', error);
        }
    };
    
    
    readLoop = async() => {
        
        try {
            while (true) {
                const { value, done } = await this.reader.read();
                if (done) {
                    break;
                }
                if (value) {
                    if (this.can_process === true) {
                        //console.log('Read:', value);
                        //this._bufferQueue.push(...value);
                        this._bufferQueue.push(value);
                        this.check();
                    }
                }
            }
        } catch (error) {
            console.error('Read error:', error);
        } finally {
            this.reader.releaseLock();
        }
    };

    check = () => {
        while (this._bufferQueue.length > 0) {
            const dataArray = this._bufferQueue.shift();
            if (dataArray && dataArray.length > 0) {
                //this._printReceiveData(dataArray);
                this._bufferHandler.push(...dataArray);
            }
        }
        while (this._bufferHandler.length > 0) {

            // clear waiting flag
            this.waiting = false;

            const stateLoading = this._receiver.call(this._bufferHandler.shift());
            if (stateLoading === StateLoading.Failure) {
                //this._printReceiveDataEnd();
                //this._printError(this._receiver.message);
                console.log(this._receiver.message);
                console.log("receive error")
                // Update response success flag
                this.respSuccess = false;
            }
            if (stateLoading === StateLoading.Loaded) {
                //this._printReceiveDataEnd();
                //this._printLog(this._receiver.message);
                //console.log(this._receiver.message);
                // Update response success flag
                this.respSuccess = true;
            }
            if (this._receiver.state === StateLoading.Loaded) {
                // Update response success flag
                this.respSuccess = true;
                // 
                this._handler(this._receiver.header, this._receiver.data);
                return this._receiver.header.dataType;
            }
        }
        return DataType.None_;
    };

    _handler = (header, data) => {

        //console.log("response header");
        //console.log(header);
        //console.log(data);

        // Run parser
        //console.log(header.dataType);
        // convert data to Uint8Array
        data = new Uint8Array(data);
        if (!this.parseMapping[header.dataType] || !this.eventHandlers[header.dataType]) {
            //console.log("Non-parse or event type:", header.dataType);
        } else {
            let parserData = this.parseMapping[header.dataType].parse(data);
            // Run Update
            //console.log(header.dataType)
            this.eventHandlers[header.dataType]( parserData );
        }

        this._receiver.checked();
    };
    

    write = async(data) => {
        try {
            //console.log('data:', data);
            await this.writer.write(data);
            //console.log('Data written to the serial port');
        } catch (error) {
            console.error('Failed to write to the serial port:', error);
        }
    };

    transfer = async(header, data) => {
        if (this.can_process === false) {
            return;
        }

        const dataArray = this.makeTransferDataArray(header, data);

        await this.write(dataArray);

        return dataArray;
    };

    makeTransferDataArray = (header, data) => {
        if (header === null || data === null) {
            return null;
        }

        if (!(header instanceof Header)) {
            return null;
        }
        
        data = data.toArray();
        
        let crc = CRC16.calc(header.toArray(), 0);
        crc = CRC16.calc(data, crc);
        const headerArray = header.toArray();
        const dataArray = new Uint8Array(2 + headerArray.length + data.length + 2);
        dataArray.set([0x0A, 0x55], 0);
        dataArray.set(headerArray, 2);
        dataArray.set(data, 2 + headerArray.length);
        dataArray.set(new Uint8Array([(crc & 0xFF), (crc >> 8)]), 2 + headerArray.length + data.length);
        return dataArray;
    }

    getStateData = async(delay = 25) => { // 10
        await this.sendRequest(DeviceType.Drone, DataType.State);
        await sleep(delay)
        return this.state_data
    };

    sendRequest = async(deviceType, dataType) => {
        /**
         * Used to send requests to the drone.
         * @param {DeviceType} deviceType - device type
         * @param {DataType} dataType - data type
         * @return {any} transfer result
         */

        if (!Object.values(DeviceType).includes(deviceType) || !Object.values(DataType).includes(dataType)) {
            return null;
        }

        const header = new Header();
        header.dataType = DataType.Request;
        header.length = RequestLDC.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = deviceType;

        const data = new RequestLDC();
        data.dataType = dataType;
        
        //return await this.transfer(header, data);
        this.waiting = true;
        this.respSuccess = false;    

        let retries = 0;
        let elapsedTime = 0;
    
        const waitForResponse = async () => {
            while (elapsedTime < REQ_TIMEOUT && retries < MAX_REQ_RETRIES) {
                if (!this.waiting) {
                    if (this.respSuccess) {
                        this.waiting = false;
                        this.respSuccess = false;
                        return; // Finish if respSuccess is true
                    } else {
                        retries++;
                        if (retries < MAX_REQ_RETRIES) {
                            this.waiting = true;
                            await this.transfer(header, data); // Retry transfer
                        }
                    }
                }
                await new Promise(resolve => setTimeout(resolve, REQ_CHECK_INTV));
                elapsedTime += REQ_CHECK_INTV;
            }
            //throw new Error('Operation timed out or max retries reached');
            this.waiting = false;
            this.respSuccess = false;
            return;
        };
    
        await this.transfer(header, data); // Initial transfer
        await waitForResponse();
    };

    speedChange = async(speed_level = 1) => {
        if (speed_level > 3) {
            speed_level = 3;
        } else if (speed_level < 1) {
            speed_level = 1;
        }

        await this.sendCommand(CommandType.ControlSpeed, parseInt(speed_level, 10));
    };

    sendControl = async(roll, pitch, yaw, throttle) => {
        if (typeof roll !== 'number' || typeof pitch !== 'number' || typeof yaw !== 'number' || typeof throttle !== 'number') {
            return null;
        }
        const header = new Header();
        header.dataType = DataType.Control;
        header.length = ControlQuad8.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;
        this._control.roll = roll;
        this._control.pitch = pitch;
        this._control.yaw = yaw;
        this._control.throttle = throttle;
        return await this.transfer(header, this._control);    
    };

    sendCommand = async (commandType, option = 0) => {
        /**
         * Used to send commands to the drone.
         * The option must contain either a value of each format or a numeric value.
         * @param {CommandType} commandType - command type
         * @param {number} option - ModeControlFlight, FlightEvent, Headless, Trim, UInt8
         * @return {any} transfer result
         */
        if ((!Object.values(CommandType).includes(commandType)) || typeof option !== 'number') {
            return null;
        }

        const header = new Header();
        header.dataType = DataType.Command;
        header.length = Command.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;

        const data = new Command();
        data.commandType = commandType;
        data.option = option;

        return await this.transfer(header, data);
    };


    hover = async(duration = 10) => {
        /**
         * Hovers the drone in place for a duration of time.
         * @param {number} duration - number of milliseconds to perform the hover command
         * @return {Promise<void>}
         */
        await this.sendControl(0, 0, 0, 0);
        await sleep(duration);
    }

    resetMoveValues = async(attempts = 3) => {
        /**
         * Resets the values of roll, pitch, yaw, and throttle to 0.
         * @param {number} attempts - number of times hover() command is sent
         * @return {Promise<void>}
         */
        for (let i = 0; i < attempts; i++) {
            await this.hover();
        }
    };

    sendTakeOff = async() => {
        const header = new Header();
        header.dataType = DataType.Command;
        header.length = Command.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;

        const data = new Command();
        data.commandType = CommandType.FlightEvent;
        data.option = FlightEvent.TakeOff;

        return await this.transfer(header, data);
    };

    
    takeoff = async() => {
        await this.resetMoveValues();
        await this.sendTakeOff();
        const timeout = 4000; // 4 seconds in milliseconds
        const initTime = Date.now();
        let timeElapsed = Date.now() - initTime;

        while (timeElapsed < timeout) {
            timeElapsed = Date.now() - initTime;
            const state = await this.getStateData();
            const stateFlight = state[2];
            if (ModeFlight[stateFlight] === ModeFlight.TakeOff) {
                this.isFlying = true;
                break;
            } else {
                await this.sendTakeOff();
                await sleep(200);
            }
        }
        await sleep(4000);
    };

    drone_takeoff = async() => {
        await this.resetMoveValues();
        await this.sendTakeOff();
        const timeout = 4000; // 4 seconds in milliseconds
        let initTime = Date.now();
        let timeElapsed = Date.now() - initTime;

        while (timeElapsed < timeout) {
            timeElapsed = Date.now() - initTime;
            const state = await this.getStateData();
            const stateFlight = state[2];
            if (ModeFlight[stateFlight] === ModeFlight.TakeOff) {
                this.isFlying = true;
                break;
            } else {
                await this.sendTakeOff();
                await sleep(10);
            }
        }

        initTime = Date.now();
        timeElapsed = Date.now() - initTime;
        while (timeElapsed < timeout) {
            await this.getPositionData();
            await this.getMotionData();
            await sleep(250);
            timeElapsed = Date.now() - initTime;
        }
    };

    land = async() => {
        /**
         * Sends a command to land the drone gently.
         *
         * @return {void}
         */
        await this.resetMoveValues();
        // reset the land coordinate back to zero
        this.previousLand[0] += await this.getPositionData()[1];
        this.previousLand[1] += await this.getPositionData()[2];
        await this.sendLanding();
        const timeout = 4000;
        const initTime = Date.now();
        let timeElapsed = Date.now() - initTime;
        while (timeElapsed < timeout) {
            timeElapsed = Date.now() - initTime;
            const state = await this.getStateData();
            const stateFlight = state[2];
            if (ModeFlight[stateFlight] === ModeFlight.Landing) {
                this.isFlying = false;
                break;
            } else {
                await this.sendLanding();
                await sleep(200);
            }
        }
        await sleep(4000);
    };

    sendLanding = async() => {
        this._control.roll = 0;
        this._control.pitch = 0;
        this._control.yaw = 0;
        this._control.throttle = 0;
        await this.moveLanding();
        const header = new Header();
        header.dataType = DataType.Command;
        header.length = Command.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;
        const data = new Command();
        data.commandType = CommandType.FlightEvent;
        data.option = FlightEvent.Landing;
        return await this.transfer(header, data);
    }

    quick_land_and_dc = async() => {
        if (this.isFlying) {
            await this.sendLanding();
        }
        this.disconnect();
    }

    drone_land = async() => {

        await this.resetMoveValues();
        // reset the land coordinate back to zero
        this.previousLand[0] += await this.getPositionData()[1];
        this.previousLand[1] += await this.getPositionData()[2];
        await this.sendLanding();
        const timeout = 4000;
        let initTime = Date.now();
        let timeElapsed = Date.now() - initTime;
        while (timeElapsed < timeout) {
            timeElapsed = Date.now() - initTime;
            const state = await this.getStateData();
            const stateFlight = state[2];
            if (ModeFlight[stateFlight] === ModeFlight.Landing) {
                this.isFlying = false;
                break;
            } else {
                await this.sendLanding();
                await sleep(10);
            }
        }

        initTime = Date.now();
        timeElapsed = Date.now() - initTime;
        while (timeElapsed < timeout) {
            await this.getPositionData();
            await this.getMotionData();
            await sleep(250);
            timeElapsed = Date.now() - initTime;
        }
    };

    /*
    yaw = async(heading, rotationalVelocity) => {

        // limit heading to range -360 to 360
        heading = Math.max(-360, Math.min(360, heading));
        // limit rotational velocity to range 10 to 360 dps
        rotationalVelocity = Math.max(10, Math.min(360, rotationalVelocity));

        const header = new Header();
        header.dataType = DataType.Command;
        header.length = ControlPosition.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;

        const data = new ControlPosition();
        data.positionX = 0;
        data.positionY = 0;
        data.positionZ = 0;
        data.velocity = 0;
        data.heading = heading;
        data.rotationalVelocity = rotationalVelocity;

        const wait = Math.abs(heading) / rotationalVelocity + 1;
        await this.transfer(header, data);
        await sleep(wait * 1000);
    };
    */

    gotoPosition = async (waypoint, velocity, relative = false) => {

        if (relative === true) {
            await this.gotoWaypointRelative(waypoint, velocity);

        } else {
            await this.gotoWaypoint(waypoint, velocity);

        }
    };

    gotoWaypoint = async(waypoint, velocity) => {
        /**
         * Drone movement command
         *
         * Use real values for position and velocity, and
         * integer values for heading and rotational Velocity.
         * @param {Array} waypoint - [positionX, positionY, positionZ]
         * @param {number} velocity - float 0.5 ~ 2.0 m/s position movement speed
         * @return {void}
         */
        if (typeof waypoint[0] !== 'number' || typeof waypoint[1] !== 'number' || typeof waypoint[2] !== 'number') {
            console.error("Error: Waypoint coordinates must be numbers.");
            return;
        }
        if (typeof velocity !== 'number') {
            console.error("Error: Velocity must be a number.");
            return;
        }

        // convert waypoint from cm per item to meters
        waypoint = waypoint.map((item) => item / 100);
        // convert velocity from cm/s to m/s
        velocity = velocity / 100;

        const header = new Header();
        header.dataType = DataType.Control;
        header.length = ControlPosition.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;
        const data = new ControlPosition();
        const positionData = await this.getPositionData();
        data.positionX = waypoint[0] - (this.previousLand[0] + positionData[1]);
        data.positionY = waypoint[1] - (this.previousLand[1] + positionData[2]);
        data.positionZ = waypoint[2]// - positionData[3];
        data.velocity = velocity;
        data.heading = 0;
        data.rotationalVelocity = 0;
        const temp = Math.sqrt(data.positionX ** 2 + data.positionY ** 2);
        const wait = (temp / velocity) + 1;
        console.log("Waypoint: ", data.positionX, data.positionY, data.positionZ);
        console.log("Speed: ", data.velocity);
        console.log("Wait: ", wait);
        await this.transfer(header, data);
        await sleep(wait * 1000);
    }

    gotoWaypointRelative = async(waypoint, velocity) => {
        /**
         * Drone movement command
         *
         * Use real values for position and velocity, and
         * integer values for heading and rotational Velocity.
         * @param {Array} waypoint - [positionX, positionY, positionZ]
         * @param {number} velocity - float 0.5 ~ 2.0 m/s position movement speed
         * @return {void}
         */
        if (typeof waypoint[0] !== 'number' || typeof waypoint[1] !== 'number' || typeof waypoint[2] !== 'number') {
            console.error("Error: Waypoint coordinates must be numbers.");
            return;
        }
        if (typeof velocity !== 'number') {
            console.error("Error: Velocity must be a number.");
            return;
        }

        // convert waypoint from cm per item to meters
        waypoint = waypoint.map((item) => item / 100);
        // convert velocity from cm/s to m/s
        velocity = velocity / 100;

        const header = new Header();
        header.dataType = DataType.Control;
        header.length = ControlPosition.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;
        const data = new ControlPosition();
        // Use the waypoint values as relative distances
        data.positionX = waypoint[0];
        data.positionY = waypoint[1];
        data.positionZ = waypoint[2];
        data.velocity = velocity;
        data.heading = 0;
        data.rotationalVelocity = 0;
        const temp = Math.sqrt(data.positionX ** 2 + data.positionY ** 2 + data.positionZ ** 2);
        const wait = (temp / velocity) + 1;
        await this.transfer(header, data);
        await sleep(wait * 1000); // sleep for wait seconds
    }

    drone_go = async(waypoint, velocity = 50) => {
        /**
         * Drone movement command
         *
         * Use real values for position and velocity, and
         * integer values for heading and rotational Velocity.
         * @param {Array} waypoint - [positionX, positionY, positionZ]
         * @param {number} velocity - float 0.5 ~ 2.0 m/s position movement speed
         * @return {void}
         */
        if (typeof waypoint[0] !== 'number' || typeof waypoint[1] !== 'number' || typeof waypoint[2] !== 'number') {
            console.error("Error: Waypoint coordinates must be numbers.");
            return;
        }
        if (typeof velocity !== 'number') {
            console.error("Error: Velocity must be a number.");
            return;
        }

        // convert waypoint from cm per item to meters
        waypoint = waypoint.map((item) => item / 100);
        // convert velocity from cm/s to m/s
        velocity = velocity / 100;

        const header = new Header();
        header.dataType = DataType.Control;
        header.length = ControlPosition.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;
        const data = new ControlPosition();
        // Use the waypoint values as relative distances
        data.positionX = waypoint[0];
        data.positionY = waypoint[1];
        data.positionZ = waypoint[2];
        data.velocity = velocity;
        data.heading = 0;
        data.rotationalVelocity = 0;
        const temp = Math.sqrt(data.positionX ** 2 + data.positionY ** 2 + data.positionZ ** 2);
        const wait = (temp / velocity) + 1;
        await this.transfer(header, data);

        let initTime = Date.now();
        let timeElapsed = Date.now() - initTime;
        while (timeElapsed < wait * 1000) {
            await this.getPositionData();
            await this.getMotionData();
            await sleep(250);
            timeElapsed = Date.now() - initTime;
        }
    };

    moveLanding = async(duration = null) => {
        /**
         * Used with set_roll, set_pitch, set_yaw, set_throttle commands.
         * Sends flight movement values to the drone.
         *
         * @param {number|null} duration - Number of seconds to perform the action
         * @return {void}
         */
        const { roll, pitch, yaw, throttle } = this._control;
        if (duration === null) {
            await this.sendControl(roll, pitch, yaw, throttle);
            await sleep(3); // sleep for 3 milliseconds
        } else {
            const milliseconds = Math.floor(duration * 1000);
            await this.sendControlWhile(roll, pitch, yaw, throttle, milliseconds);
        }
    };

    move = async(direction, distance, units, speed) => {
        /**
         * Moves the drone in the specified direction.
         *
         * @param {string} direction - The direction to move (forward, backward, left, right, up, down)
         * @param {number} distance - The numerical value of the distance to move
         * @param {string} units - The units of the distance (inches, centimeters, meters, feet)
         * @param {number} speed - The speed of the movement (default 1 meter per second, max 2 meters/second)
         * @return {void}
         */
        
        let distanceMeters = 0;
        if (units == "cm") {
            if (speed === null) {
                speed = 0.5;
            } else {
                // convert the speed from cm/s to m/s
                speed = speed / 100;
            }
            // convert cm to m
            distanceMeters = distance / 100;
        } else if (units == "inches") {
            if (speed === null) {
                speed = 0.5;
            } else {
                // inches/s to meters/second
                speed = speed * 0.0254;
            }
            // inches to meters
            distanceMeters = distance * 0.0254;
        }

        // Cap the speed
        if (speed > 2) {
            speed = 2;
        } else if (speed < 0) {
            speed = 0;
        }
        // Read the current position and move relative to position
        const data = await this.getPositionData();
        const x = data[1];
        const y = data[2];
        const z = data[3];
        console.log("Current Position: ", x, y, z);
        let newX = x;
        let newY = y;
        let newZ = z;
        switch (direction) {
            case this.MOVE_DIRECTIONS.FORWARD:
                newX += distanceMeters;
                break;
            case this.MOVE_DIRECTIONS.BACKWARD:
                newX -= distanceMeters;
                break;
            case this.MOVE_DIRECTIONS.LEFT:
                newY += distanceMeters;
                break;
            case this.MOVE_DIRECTIONS.RIGHT:
                newY -= distanceMeters;
                break;
            case this.MOVE_DIRECTIONS.UP:
                newZ += distanceMeters;
                break;
            case this.MOVE_DIRECTIONS.DOWN:
                newZ -= distanceMeters;
                break;
            default:
                console.error("Error: Not a valid direction.");
                return this.FAILED_RES;
        }
        try {
            console.log("New Position: ", newX, newY, newZ);
            console.log("Speed: ", speed);
            await this.sendAbsolutePosition(newX, newY, newZ, speed, 0, 0);
            return this.COMPLETE_RES;
        } catch (error) {
            console.error("Error: Failed to move drone.", error);
            return this.FAILED_RES;
        }
    }

    sendLightDefaultColor = async(target, lightMode, interval, r, g, b) => {
        if (!Number.isInteger(interval) || !Number.isInteger(r) || !Number.isInteger(g) || !Number.isInteger(b)) {
            return null;
        }

        const header = new Header();
        header.dataType = DataType.LightDefault;
        header.length = LightModeColor.getSize();
        header.from_ = DeviceType.Base;

        const data = new LightModeColor();

        if (target === "Drone") {
            header.to_ = DeviceType.Drone;
        } else if (target === "Controller") {
            header.to_ = DeviceType.Controller;
        } else {
            return null;
        }
        data.mode.mode = lightMode;

        data.mode.interval = interval;
        data.color.r = r;
        data.color.g = g;
        data.color.b = b;
        return await this.transfer(header, data);
    }

    sendStop = async() => {
        const header = new Header();
        header.dataType = DataType.Command;
        header.length = Command.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;

        const data = new Command();
        data.commandType = CommandType.Stop;
        data.option = 0;

        return await this.transfer(header, data);
    }

    emergencyStop = async() => {

        await this.resetMoveValues();
        await this.sendStop();
    }

    sendFlip = async(mode) => {
        const header = new Header();
        header.dataType = DataType.Command;
        header.length = Command.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;

        const data = new Command();
        data.commandType = CommandType.FlightEvent;
        data.option = mode;

        return await this.transfer(header, data);
    }

    flip = async(direction = this.FLIP_DIRECTIONS.BACKWARD) => {
        /**
         * Calls sendFlip() command to flip the drone in desired direction.
         * Options are: "front", "back", "left", and "right"
         *
         * @param {string} direction - The direction to flip the drone
         * @return {void}
         */

        const state = await this.getStateData();
        const battery = state[6];
        if (battery < 50) {
            console.warn("Warning: Unable to perform flip; battery level is below 50%.");
            //this.controllerBuzzer(587, 100);
            //this.controllerBuzzer(554, 100);
            //this.controllerBuzzer(523, 100);
            //this.controllerBuzzer(494, 150);
            return;
        }

        let mode;
        switch (direction) {
            case this.FLIP_DIRECTIONS.BACKWARD:
                mode = FlightEvent.FlipRear;
                break;
            case this.FLIP_DIRECTIONS.FORWARD:
                mode = FlightEvent.FlipFront;
                break;
            case this.FLIP_DIRECTIONS.RIGHT:
                mode = FlightEvent.FlipRight;
                break;
            case this.FLIP_DIRECTIONS.LEFT:
                mode = FlightEvent.FlipLeft;
                break;
            default:
                console.error("Invalid flip direction.");
                return;
        }

        await this.sendFlip(mode);
    }

    //circleTurn(circle_direction, duration, speed)
    circleTurn = async(direction = 1, seconds = 1, speed = 30) => {
        let pitch = speed;
        let roll = 0;
        const steps = 4;
        const stepDuration = (seconds * 1000) / (steps * 4); // Total duration divided by number of steps
    
        for (let i = 0; i < steps; i++) {
            await this.sendControlWhile(roll, pitch, 0, 0, stepDuration);
            roll += 10 * direction;
            pitch -= 10 * direction;
            await sleep(stepDuration);
        }
    
        for (let i = 0; i < steps; i++) {
            await this.sendControlWhile(roll, pitch, 0, 0, stepDuration);
            roll -= 10 * direction;
            pitch -= 10 * direction;
            await sleep(stepDuration);
        }
    
        for (let i = 0; i < steps; i++) {
            await this.sendControlWhile(roll, pitch, 0, 0, stepDuration);
            roll -= 10 * direction;
            pitch += 10 * direction;
            await sleep(stepDuration);
        }
    
        for (let i = 0; i < steps; i++) {
            await this.sendControlWhile(roll, pitch, 0, 0, stepDuration);
            roll += 10 * direction;
            pitch += 10 * direction;
            await sleep(stepDuration);
        }
    }

    setDroneLight = async(r, g, b, brightness) => {

        await this.sendLightDefaultColor("Drone", LightModeDrone.BodyHold, brightness, r, g, b);
        await sleep(10);
    }

    setControllerLight = async(r, g, b, brightness) => {

        await this.sendLightDefaultColor("Controller", LightModeController.BodyHold, brightness, r, g, b);
        await sleep(10);
    }

    sendControlWhile = async(roll, pitch, yaw, throttle, timeMs) => {
        /**
         * Sends roll, pitch, yaw, throttle values continuously to the drone for timeMs
         *
         * @param {number} roll - int from -100 to 100
         * @param {number} pitch - int from -100 to 100
         * @param {number} yaw - int from -100 to 100
         * @param {number} throttle - int from -100 to 100
         * @param {number} timeMs - int from -100 to 1000000 in milliseconds
         * @return {void}
         */
        if (!Number.isInteger(roll) || !Number.isInteger(pitch) || !Number.isInteger(yaw) || !Number.isInteger(throttle)) {
            return null;
        }
        const timeSec = timeMs / 1000;
        const timeStart = performance.now();
        while ((performance.now() - timeStart) < timeSec * 1000) {
            await this.sendControl(roll, pitch, yaw, throttle);
            await sleep(3);
        }
    };

    async sendAbsolutePosition(positionX, positionY, positionZ, velocity, heading, rotationalVelocity) {
        /**
         * Drone movement command
         *
         * Use real values for position and velocity, and
         * integer values for heading and rotational Velocity.
         * @param {number} positionX - float -10.0 ~ 10.0 meter Front (+), Back (-)
         * @param {number} positionY - float -10.0 ~ 10.0 meter Left(+), Right(-)
         * @param {number} positionZ - float -10.0 ~ 10.0 meter Up (+), Down (-)
         * @param {number} velocity - float 0.5 ~ 2.0 m/s position movement speed
         * @param {number} heading - int -360 ~ 360 degree Turn left (+), turn right (-)
         * @param {number} rotationalVelocity - int 10 ~ 360 degree/s left and right rotation speed
         * @return {void}
         */
        if (typeof positionX !== 'number') {
            console.error("Error: positionX must be a number.");
            return;
        }
        if (typeof positionY !== 'number') {
            console.error("Error: positionY must be a number.");
            return;
        }
        if (typeof positionZ !== 'number') {
            console.error("Error: positionZ must be a number.");
            return;
        }
        if (typeof velocity !== 'number') {
            console.error("Error: velocity must be a number.");
            return;
        }
        if (typeof heading !== 'number' || !Number.isInteger(heading)) {
            console.error("Error: heading must be an integer.");
            return;
        }
        if (typeof rotationalVelocity !== 'number' || !Number.isInteger(rotationalVelocity)) {
            console.error("Error: rotationalVelocity must be an integer.");
            return;
        }
        const header = new Header();
        header.dataType = DataType.Control;
        header.length = ControlPosition.getSize();
        header.from_ = DeviceType.Base;
        header.to_ = DeviceType.Drone;
        const data = new ControlPosition();
        const posData = await this.getPositionData();
        // calculate the deltas in position needed to fly in those axis.
        data.positionX = positionX - (this.previousLand[0] + posData[1]);
        data.positionY = positionY - (this.previousLand[1] + posData[2]);
        data.positionZ = positionZ - posData[3];
        data.velocity = velocity;
        data.heading = heading;
        data.rotationalVelocity = rotationalVelocity;
        const temp = Math.sqrt(data.positionX ** 2 + data.positionY ** 2 + data.positionZ ** 2); // distance travelled
        let wait;
        if (data.rotationalVelocity === 0) {
            wait = (temp / velocity) + 1;
        } else if (data.velocity === 0) {
            wait = (data.heading / data.rotationalVelocity) + 1;
        } else {
            // wait time is equal to the largest duration of movement: linear or rotational movement
            wait = Math.max((temp / velocity) + 1, (data.heading / data.rotationalVelocity) + 1);
        }
        await this.transfer(header, data);
        await sleep(wait * 1000);
    }

    getFlightState = async() => {
        const stateData = await this.getStateData();
        return stateData[2];
    }

    getMovementState = async() => {
        const stateData = await this.getStateData();
        return stateData[7];
    }


    turnDegree = async (degree, timeout = 3, p_value = 10) => {
        /**
         * Turns right or left with absolute reference frame to
         * drone's initial heading.
         * Positive degrees turn to right and
         * negative degrees turn to the left.
         *
         * @param {number} degree - integer from -180 to 180 degrees
         * @param {number} timeout - duration in seconds that drone will try to turn
         * @param {number} p_value - the gain of the proportional controller,
         * if this increased CDE will turn quicker, the smaller the slower.
         * examples values 0.5 to 1.5
         * @return {void}
         */

        return new Promise(async (resolve, reject) => {
            let interval;
            try {
                // Make sure you aren't moving
                await this.hover(0.01);
                const initTime = Date.now();
                let timeElapsed = (Date.now() - initTime) / 1000;
                //const initAngle = this.get_angle_z();
                let desiredAngle = degree;

                if (desiredAngle >= 180) {
                    desiredAngle = 180;
                } else if (desiredAngle <= -180) {
                    desiredAngle = -180;
                }

                interval = setInterval(async () => {
                    try {
                        timeElapsed = (Date.now() - initTime) / 1000;
                        const motionData = await this.getMotionData();
                        const currentAngle = motionData[9];
                        // Find the distance to the desired angle
                        const degreeDiff = desiredAngle - currentAngle;

                        // Save the first distance turning in one direction
                        const degreeDist1 = Math.abs(degreeDiff);

                        // Now we find what direction to turn
                        // Sign will be either +1 or -1
                        const sign = degreeDist1 > 0 ? degreeDiff / degreeDist1 : 1;

                        // Now save the second distance going
                        // the opposite direction turning
                        const degreeDist2 = 360 - degreeDist1;

                        // Find which one is the shorter path
                        let errorPercent;
                        let speed;
                        if (degreeDist1 <= degreeDist2) {
                            // Normalize the degree distance so it goes from 0-100%
                            // where 100% would be 360 degree distance
                            errorPercent = Math.floor((degreeDist1 / 360) * 100);
                            errorPercent = Math.floor(errorPercent * p_value);
                            // Cap the value between -100% and 100%
                            errorPercent = Math.max(-100, Math.min(100, errorPercent));
                            // Now we can use the sign to determine the turning direction
                            speed = Math.floor(sign * errorPercent);
                            await this.sendControl(0, 0, speed, 0);
                        } else {
                            errorPercent = Math.floor((degreeDist2 / 360) * 100);
                            errorPercent = Math.floor(errorPercent * p_value);
                            // Cap the value between -100% and 100%
                            errorPercent = Math.max(-100, Math.min(100, errorPercent));
                            speed = Math.floor(-1 * sign * errorPercent);
                            await this.sendControl(0, 0, speed, 0);
                        }

                        if (timeElapsed >= timeout) {
                            clearInterval(interval);
                            // Stop any movement just in case
                            await this.hover(0.05);
                            resolve(); // Resolve the promise when the turning process is complete
                        }
                    } catch (error) {
                        clearInterval(interval);
                        reject(error); // Reject the promise if an error occurs
                    }
                }, 25);
            } catch (error) {
                if (interval) {
                    clearInterval(interval); // Clear the interval if an error occurs
                }
                reject(error); // Reject the promise if an error occurs
            }
        });
    }

    droneBuzzer = async(note, duration) => {
        /**
         * Starts the drone buzzer for a specified duration.
         *
         * @param {number|Note} note - Integer frequency or Note object
         * @param {number} duration - Duration in milliseconds
         * @return {void}
         */
        let mode;
        let noteValue;

        mode = BuzzerMode.Scale;
        noteValue = Note[note];

        const header = new Header();
        header.dataType = DataType.Buzzer;
        header.length = Buzzer.getSize();
        header.from_ = DeviceType.Tester;
        header.to_ = DeviceType.Drone;

        const data = new Buzzer();
        data.mode = mode;
        data.value = noteValue;
        data.time = duration;

        await this.transfer(header, data);
        await sleep(duration);
    }

    
    controllerBuzzer = async(note, duration) => {
        /**
         * Starts the controller buzzer for a specified duration.
         *
         * @param {number|Note} note - Integer frequency or Note object
         * @param {number} duration - Duration in milliseconds
         * @return {void}
         */
        let mode;
        let noteValue;

        mode = BuzzerMode.Scale;
        noteValue = Note[note];

        const header = new Header();
        header.dataType = DataType.Buzzer;
        header.length = Buzzer.getSize();
        header.from_ = DeviceType.Tester;
        header.to_ = DeviceType.Controller;

        const data = new Buzzer();
        data.mode = mode;
        data.value = noteValue;
        data.time = duration;

        await this.transfer(header, data);
        await sleep(duration);
    }

    sendClearBias = async() => {

        const header = new Header()

        header.dataType = DataType.Command
        header.length = Command.getSize()
        header.from_ = DeviceType.Base
        header.to_ = DeviceType.Drone

        const data = new Command()

        data.commandType = CommandType.ClearBias
        data.option = 0

        await this.transfer(header, data);
    }

    resetGyro = async() => {
        /**
         * This method will reset the gyroscope and calibrate it.
         *
         * While this program runs the drone must be on a leveled flat surface
         * in order for the Gyroscope to be cleared.
         * If the calibration is done while the drone is moving
         * the drone will have incorrect calibration values
         * resulting in incorrect gyro angle values
         *
         * @return: void
         */
            
        try {
            // Send RF command to clear gyro bias and initiate the calibration
            await this.sendClearBias();

            const timeout = 10000; // 10 seconds timeout
            const startTime = Date.now();    

            while (true) {
                await this.getErrorData(200, false);
                const errorFlagNow = this.errorData[1];
                if ((errorFlagNow & ErrorFlagsForSensor.Motion_Calibrating) === 0) {
                    break;
                }
                
                if (Date.now() - startTime > timeout) {
                    throw new Error("Gyro calibration timed out");
                }
            }
        } catch (error) {
            console.error("Failed to reset gyro:", error);
            throw error;
        }
    }

    getErrorData = async(delay = 200, printError = true) => {
        /**
         * Will get and print the current error state
         * @param {number} delay - Delay in milliseconds
         * @param {boolean} printError - Whether to print the error
         * @return {Array} - The error data
         */
        await this.sendRequest(DeviceType.Drone, DataType.Error);

        return new Promise((resolve) => {
            const timeoutId = setTimeout(() => {
                const sensorErrorFlag = this.errorData[1];
                const stateErrorFlag = this.errorData[2];

                if (printError) {
                    if (sensorErrorFlag & ErrorFlagsForSensor.Motion_Calibrating) {
                        console.log("Motion_Calibrating");
                    } else if (sensorErrorFlag & ErrorFlagsForSensor.Motion_NoAnswer) {
                        console.log("Motion_NoAnswer");
                    } else if (sensorErrorFlag & ErrorFlagsForSensor.Motion_WrongValue) {
                        console.log("Motion_WrongValue");
                    } else if (sensorErrorFlag & ErrorFlagsForSensor.Motion_NotCalibrated) {
                        console.log("Motion_NotCalibrated");
                    } else if (sensorErrorFlag & ErrorFlagsForSensor.Pressure_NoAnswer) {
                        console.log("Pressure_NoAnswer");
                    } else if (sensorErrorFlag & ErrorFlagsForSensor.Pressure_WrongValue) {
                        console.log("Pressure_WrongValue");
                    } else if (sensorErrorFlag & ErrorFlagsForSensor.RangeGround_NoAnswer) {
                        console.log("RangeGround_NoAnswer");
                    } else if (sensorErrorFlag & ErrorFlagsForSensor.RangeGround_WrongValue) {
                        console.log("RangeGround_WrongValue");
                    } else if (sensorErrorFlag & ErrorFlagsForSensor.Flow_NoAnswer) {
                        console.log("Flow_NoAnswer");
                    } else if (sensorErrorFlag & ErrorFlagsForSensor.Flow_CannotRecognizeGroundImage) {
                        console.log("Flow_CannotRecognizeGroundImage");
                    } else {
                        console.log("No sensor errors.");
                    }

                    if (stateErrorFlag & ErrorFlagsForState.NotRegistered) {
                        console.log("Device not registered.");
                    } else if (stateErrorFlag & ErrorFlagsForState.FlashReadLock_UnLocked) {
                        console.log("Flash memory read lock not engaged.");
                    } else if (stateErrorFlag & ErrorFlagsForState.BootloaderWriteLock_UnLocked) {
                        console.log("Bootloader write lock not engaged.");
                    } else if (stateErrorFlag & ErrorFlagsForState.LowBattery) {
                        console.log("Low battery.");
                    } else if (stateErrorFlag & ErrorFlagsForState.TakeoffFailure_CheckPropellerAndMotor) {
                        console.log("Takeoff failure. Check propeller and motor.");
                    } else if (stateErrorFlag & ErrorFlagsForState.CheckPropellerVibration) {
                        console.log("Propeller vibration detected.");
                    } else if (stateErrorFlag & ErrorFlagsForState.Attitude_NotStable) {
                        console.log("Attitude not stable.");
                    } else if (stateErrorFlag & ErrorFlagsForState.CanNotFlip_LowBattery) {
                        console.log("Cannot flip. Battery below 50%.");
                    } else if (stateErrorFlag & ErrorFlagsForState.CanNotFlip_TooHeavy) {
                        console.log("Cannot flip. Drone too heavy.");
                    } else {
                        console.log("No state errors.");
                    }
                }

                resolve(this.errorData);
            }, delay);
            // Clear the timeout if the promise is resolved or rejected
            return () => clearTimeout(timeoutId);
        });
    };

    getPositionData = async(delay = 0.01) => {
        /**
         * position_data[0] = Date.now() - this.timeStartProgram
         * position_data[1] = X axis in meters
         * position_data[2] = Y axis in meters
         * position_data[3] = Z axis in meters
         * @param {number} delay
         * @return {Array}
         */
        await this.sendRequest(DeviceType.Drone, DataType.Position);
        await sleep(delay * 1000);
        return this.positionData;
    }

    getAltitudeData = async(delay = 0.01) => {
        await this.sendRequest(DeviceType.Drone, DataType.Altitude);
        await sleep(delay * 1000);
        return this.altitudeData;
    }

    getMotionData = async(delay = 0.01) => {
        await this.sendRequest(DeviceType.Drone, DataType.Motion);
        await sleep(delay * 1000);
        return this.motionData;
    }

    getRangeData = async(delay = 0.01) => {
        /**
         * Sends a request for updating the range data then the range data should update and the list will be returned
         * does not check if the data has been updated just returns whatever is in the list
         * [] Front millimeters
         * bottom millimeters
         * @param {number} delay
         * @return {Array}
         */
        await this.sendRequest(DeviceType.Drone, DataType.Range);
        await sleep(delay * 1000);
        return this.rangeData;
    }

    getPositionData = async(delay = 0.01) => {
        /**
         * position_data[0] = Date.now() - this.timeStartProgram
         * position_data[1] = X axis in meters
         * position_data[2] = Y axis in meters
         * position_data[3] = Z axis in meters
         * @param {number} delay
         * @return {Array}
         */
        this.sendRequest(DeviceType.Drone, DataType.Position);
        await sleep(delay * 1000); // sleep for delay seconds
        return this.positionData;
    }

    getFlowData = async(delay = 0.01) => {
        await this.sendRequest(DeviceType.Drone, DataType.RawFlow);
        await sleep(delay * 1000);
        return this.flowData;
    }

    getTrimData = async(delay = 0.08) => {
        await this.sendRequest(DeviceType.Drone, DataType.Trim);
        await sleep(delay * 1000);
        return this.trimData;
    }

    sendTrim = async(roll, pitch, yaw, throttle) => {
        if (!Number.isInteger(roll) || !Number.isInteger(pitch) || !Number.isInteger(yaw) || !Number.isInteger(throttle)) {
            console.log("Error: Trim values must be integers.");
            return null;
        }
        roll = Math.max(-100, Math.min(100, roll));
        pitch = Math.max(-100, Math.min(100, pitch));
        yaw = Math.max(-100, Math.min(100, yaw));
        throttle = Math.max(-100, Math.min(100, throttle));

        const header = new Header()
        header.dataType = DataType.Trim
        header.length = Trim.getSize()
        header.from_ = DeviceType.Base
        header.to_ = DeviceType.Drone
        const data = new Trim()
        data.roll = roll
        data.pitch = pitch
        data.yaw = yaw
        data.throttle = throttle
        return await this.transfer(header, data)
    }

    updateAltitudeData = (droneType) => {
        /**
         * temperature Float32 4 Byte - temp.(℃) Celsius
         * pressure Float32 4 Byte - pressure (Pascals)
         * altitude Float32 4 Byte - Converting pressure to elevation above sea level(meters)
         * rangeHeight Float32 4 Byte - Height value output from distance sensor (meters)
         */
        this.altitudeData[0] = (Date.now() - this.timeStartProgram) / 1000; // Convert to seconds
        this.altitudeData[1] = droneType.temperature;
        this.altitudeData[2] = droneType.pressure;
        this.altitudeData[3] = droneType.altitude;
        this.altitudeData[4] = droneType.rangeHeight;
        //console.log("Updated Altitude Data", droneType);
    }

    updateStateData = (drone_type) => {
        /*
        variable name	    form	          size	    range	Explanation
        modeSystem	        ModeSystem	      1 Byte	-	    System operating mode
        modeFlight	        ModeFlight	      1 Byte	-	    Flight controller operating mode
        modeControlFlight	ModeControlFlight 1 Byte	-	    flight control mode
        modeMovement	    ModeMovement	  1 Byte	-	    Moving state
        headless	        Headless	      1 Byte	-	    Headless setting status
        sensorOrientation	SensorOrientation 1 Byte	-	    Sensor orientation
        battery	            UInt8	          1 Byte	0~100	Drone battery level
        */
        this.state_data[0] = (Date.now() - this.timeStartProgram) / 1000;
        this.state_data[1] = drone_type.modeSystem
        this.state_data[2] = drone_type.modeFlight
        this.state_data[3] = drone_type.modeControlFlight
        this.state_data[4] = drone_type.headless
        this.state_data[5] = drone_type.sensorOrientation
        this.state_data[6] = drone_type.battery
        this.state_data[7] = drone_type.modeMovement
        this.state_data[8] = drone_type.controlSpeed
        //console.log("Updated State Data", drone_type.battery);
    }

    updateJoystickData = (drone_type) => {
        /**
         * variable  form                size      Range       Explanation
         * x         Int8                1 Byte    -100~100    X-axis value
         * y         Int8                1 Byte    -100~100    Y-axis value
         * direction Joystick Direction  1 Byte    -           joystick direction
         * event     JoystickEvent       1 Byte    -           Event
         */
        this.joystickData[0] = (Date.now() - this.timeStartProgram) / 1000;
        this.joystickData[1] = drone_type.left.x;
        this.joystickData[2] = drone_type.left.y;
        this.joystickData[3] = drone_type.left.direction.name;
        this.joystickData[4] = drone_type.left.event.name;
        this.joystickData[5] = drone_type.right.x;
        this.joystickData[6] = drone_type.right.y;
        this.joystickData[7] = drone_type.right.direction.name;
        this.joystickData[8] = drone_type.right.event.name;
    }

    updateTrimData = (drone_type) => {
        /*
        Updates and reads the trim values for
        roll, pitch, yaw, and throttle
        that are set on the drones internal memory.
        :param drone_type:
        :return: N/A
        */
        this.trimData[0] = (Date.now() - this.timeStartProgram) / 1000;
        this.trimData[1] = drone_type.roll
        this.trimData[2] = drone_type.pitch
        this.trimData[3] = drone_type.yaw
        this.trimData[4] = drone_type.throttle
    }

    updateInformation = (information) => {
        this.informationData[0] = (Date.now() - this.timeStartProgram) / 1000; // Convert to seconds
        const deviceType = (information.modelNumber >> 8) & 0xFF;
        if (deviceType === DeviceType.Drone) {
            this.informationData[1] = information.modelNumber;
            this.informationData[2] = this.formatFirmwareVersion(information.version);
        } else if (deviceType === DeviceType.Controller) {
            this.informationData[3] = information.modelNumber;
            this.informationData[4] = this.formatFirmwareVersion(information.version);
        } else {
            console.error("Error: Not a valid device type.");
        }
    }

    updateErrorData = (droneType) => {
        /**
         * ErrorFlagsForSensor -----------------------------------------------------------
         *
         * None_                                   = 0x00000000
         *
         * Motion_NoAnswer                         = 0x00000001    // Motion 센서 응답 없음
         * Motion_WrongValue                       = 0x00000002    // Motion 센서 잘못된 값
         * Motion_NotCalibrated                    = 0x00000004    // Gyro Bias 보정이 완료되지 않음
         * Motion_Calibrating                      = 0x00000008    // Gyro Bias 보정 중
         *
         * Pressure_NoAnswer                       = 0x00000010    // 압력 센서 응답 없음
         * Pressure_WrongValue                     = 0x00000020    // 압력 센서 잘못된 값
         *
         * RangeGround_NoAnswer                    = 0x00000100    // 바닥 거리 센서 응답 없음
         * RangeGround_WrongValue                  = 0x00000200    // 바닥 거리 센서 잘못된 값
         *
         * Flow_NoAnswer                           = 0x00001000    // Flow 센서 응답 없음
         * Flow_WrongValue                         = 0x00002000    // Flow 잘못된 값
         * Flow_CannotRecognizeGroundImage         = 0x00004000    // 바닥 이미지를 인식할 수 없음
         *
         * ErrorFlagsForState -------------------------------------------------------------
         *
         * None_                                   = 0x00000000
         *
         * NotRegistered                           = 0x00000001    // 장치 등록이 안됨
         * FlashReadLock_UnLocked                  = 0x00000002    // 플래시 메모리 읽기 Lock이 안 걸림
         * BootloaderWriteLock_UnLocked            = 0x00000004    // 부트로더 영역 쓰기 Lock이 안 걸림
         * LowBattery                              = 0x00000008    // Low Battery
         *
         * TakeoffFailure_CheckPropellerAndMotor   = 0x00000010    // 이륙 실패
         * CheckPropellerVibration                 = 0x00000020    // 프로펠러 진동발생
         * Attitude_NotStable                      = 0x00000040    // 자세가 많이 기울어져 있거나 뒤집어져 있을때
         *
         * CanNotFlip_LowBattery                   = 0x00000100    // 배터리가 30이하
         * CanNotFlip_TooHeavy                     = 0x00000200    // 기체가 무거움
         *
         * @param droneType
         * @return
         */
        this.errorData[0] = (Date.now() - this.timeStartProgram) / 1000; // Convert to seconds
        this.errorData[1] = droneType.errorFlagsForSensor;
        this.errorData[2] = droneType.errorFlagsForState;
    }

    updateMotionData = (droneType) => {
        /**
         * Variable    Name    Type        Size          Range Unit     Description
         * [0] time_elapsed          float                                 seconds
         * [1] accelX    Int16    2 Byte    -1568 ~ 1568 (-156.8 ~ 156.8)    m/s2 x 10     X
         * [2] accelY    Int16    2 Byte    -1568 ~ 1568 (-156.8 ~ 156.8)    m/s2 x 10     Y
         * [3] accelZ    Int16    2 Byte    -1568 ~ 1568 (-156.8 ~ 156.8)    m/s2 x 10     Z
         * [4] gyroRoll  Int16    2 Byte    -2000 ~ 2000    degree/second Roll
         * [5] gyroPitch Int16    2 Byte    -2000 ~ 2000    degree/second Pitch
         * [6] gyroYaw   Int16    2 Byte    -2000 ~ 2000    degree/second Yaw
         * [7] angleRoll Int16    2 Byte    -180 ~ 180    degree Roll
         * [8] anglePitch Int16    2 Byte    -180 ~ 180    degree Pitch
         * [9] angleYaw  Int16    2 Byte    -180 ~ 180    degree Yaw
         */
        this.motionData[0] = (Date.now() - this.timeStartProgram) / 1000; // Convert to seconds
        this.motionData[1] = droneType.accelX / 98.1;
        this.motionData[2] = droneType.accelY / 98.1;
        this.motionData[3] = droneType.accelZ / 98.1;
        this.motionData[4] = droneType.gyroRoll;
        this.motionData[5] = droneType.gyroPitch;
        this.motionData[6] = droneType.gyroYaw;
        this.motionData[7] = droneType.angleRoll;
        this.motionData[8] = droneType.anglePitch;
        this.motionData[9] = droneType.angleYaw;

        this.roll = droneType.angleRoll;
        this.pitch = droneType.anglePitch;
        this.yaw = droneType.angleYaw;
    }

    updateRangeData = (droneType) => {
        /**
         * Uses the time of flight sensor to detect the distance to an object
         * Bottom range sensor will not update unless flying.
         * Will display -1000 by default
         *
         * Front:  millimeters
         * Bottom: millimeters
         */
        this.rangeData[0] = (Date.now() - this.timeStartProgram) / 1000; // Convert to seconds
        this.rangeData[1] = droneType.front;
        this.rangeData[2] = droneType.bottom;
    }

    updatePositionData = (droneType) => {
        /**
         * location of the drone
         * x Float32 4 Byte - X axis in meters
         * y Float32 4 Byte - Y axis in meters
         * z Float32 4 Byte - Z axis in meters
         */
        this.positionData[0] = (Date.now() - this.timeStartProgram) / 1000; // Convert to seconds
        this.positionData[1] = droneType.x;
        this.positionData[2] = droneType.y;
        this.positionData[3] = droneType.z;
        this.x = droneType.x; // m
        this.y = droneType.y; // m
        this.z = droneType.z; // m
    }

    updateFlowData = (droneType) => {

        /*
        Relative position value calculated by optical flow sensor
        x   Float32 4 Byte  -   X axis(m)
        y   Float32 4 Byte  -   Y axis(m)
        will be in meters
        */
        this.flowData[0] = (Date.now() - this.timeStartProgram) / 1000; // Convert to seconds
        this.flowData[1] = droneType.x
        this.flowData[2] = droneType.y
    };

    drone_get_data = async(self, data_type) => {

        let return_data = null;
        if (data_type === self.ATTITUDE) {
            return_data = await this.getMotionData();
            return_data = return_data.slice(7, 10);
        } else if (data_type === self.POSITION) {
            return_data = await this.getPositionData();
            return_data = return_data.slice(1, 4);
        } else if (data_type === self.BATTERY) {
            return_data = await this.getStateData();
            return_data = return_data[6];
        } else if (data_type === self.RANGE) {
            return_data = await this.getRangeData();
            return_data = return_data.slice(1, 3);
        } else if (data_type === self.ALTITUDE) {
            return_data = await this.getAltitudeData();
            return_data = return_data.slice(1, 4);
        } else if (data_type === self.ACCELEROMETER) {
            return_data = await this.getMotionData();
            return_data = return_data.slice(1, 4);
        } else if (data_type === self.GYROSCOPE) {
            return_data = await this.getMotionData();
            return_data = return_data.slice(4, 7);
        } else if (data_type === this.RAW_FLOW) {
            return_data = await this.getFlowData();
            return_data = return_data.slice(1, 3);
        }
        return return_data;
    }

};

export { LocoDroneC };