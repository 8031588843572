// Parent Component of:
// --- ProgramList
// --- ProgramCreate
// --- ProgramCoding

// Import React and Material-UI Modules
import React, { Component } from "react";
import withStyles from "@material-ui/styles/withStyles";
import { withRouter } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";

import Topbar from "./Topbar";


import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from "@material-ui/core/IconButton";

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


// Import Custom Components
import ProgramCreate from "./ProgramCreate";
import ProgramCoding from "./ProgramCoding"
import ProgramList from "./ProgramList";
import ProgramGUI from "./ProgramGUI";

// Import Context to Access Auth/User Information
import { AuthContext } from "../context/auth";
//import { Button } from "@material-ui/core";

// Material-UI CSS-type Style Specifications - not all are used
const styles = theme => ({
  root: {
    //backgroundColor: "white",
    background: `linear-gradient(45deg, ${theme.palette.primary.lighter} 30%, ${theme.palette.primary.lighter} 90%)`,
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  paper: {
    textAlign: "left",
    backgroundColor: theme.palette.primary["light"],
    color: theme.palette.primary["contrastText"],
    height: window.programBarOffset,
  },
  paperList: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: "left",
    backgroundColor: theme.palette.primary["light"],
    color: theme.palette.primary["contrastText"],
    height: window.programBarOffset,
  },
  userOptions: {
    //textTransform: "none", borderRadius: '0%'
    //marginTop: 10,
    fontSize: theme.typography.h4.fontSize, // Match the font size of h4 typography
    justify: 'flex-end',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: theme.palette.secondary.green, // Add hover effect
    },
  },
  menu: {
      backgroundColor: theme.palette.background.paper, // Ensure the menu has a distinct background color
      boxShadow: theme.shadows[5], // Add shadow to make it stand out
  },
  menuItem: {
      '&:hover': {
          backgroundColor: theme.palette.primary.light, // Change background color on hover
          color: theme.palette.primary.contrastText, // Change text color on hover
      },
  },
  headerText: {
    padding: theme.spacing(2),
  },
  actionButton: {
    justify: "right",
    color: "white",
    backgroundColor: theme.palette.primary["light"],
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    //marginTop: theme.spacing(2),
    //margin: theme.spacing(2),
  },
  cardCreate: {
    marginTop: theme.spacing(1),//'5%',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[5], // Add shadow effect
    borderRadius: '10px',
    height: '95%',
    maxHeight: '500px',
  },
  cardList: {
    marginTop: theme.spacing(1),//'5%',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    boxShadow: theme.shadows[5], // Add shadow effect
    borderRadius: '10px',
    //height: '95%',
    //maxHeight: '500px',
    maxHeight: `calc(100vh - 100px)`, // Dynamically calculate maxHeight
  },
  scrollableContainer: {
    maxHeight: `calc(100vh - ${window.navBarOffset})`, // Adjust height based on the height of the Topbar
    overflowY: 'auto',
    //padding: theme.spacing(2),
    display: 'flex', // Use flexbox
    flexDirection: 'column', // Ensure children are stacked vertically
    flexGrow: 1, // Allow the container to grow
    scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`,
  }
});



// Component Class - Program
class Program extends Component {


  static contextType = AuthContext;

  constructor(props) {
    super(props);


    this.linkedGUI = React.createRef();
    this.linkedCoding = React.createRef();

    this.linkedGUIS = React.createRef();
    this.linkedCodingS = React.createRef();

    this.programList = React.createRef();

    
    this.filterword = '';

  }

  // Class state
  state = {
    activeStep: 0,
    data: [],
    progDetails: {},
    canPassCode: false,
    userMenu: false,
    userAnchor: null,
    programListType: 'My Programs',
    filtername: '',
  };

  // Run When This Component Mounted
  componentDidMount() {
  }

  // When Create-Program Button Is Pressed - Called by Child ProgramCreate
  handleCreateProgram = (progDetails) => {
    // Get fileName Details and Update State
    this.setState({ progDetails: progDetails} );
    
    if (progDetails.selection.language.toLowerCase() === "gui") {
      // Update State for View Selection
      this.setState(state => ({
        activeStep: 2
      }));
    } else if (progDetails.selection.language.toLowerCase() === "python") {
      // Update State for View Selection
      this.setState(state => ({
        activeStep: 1
      }));
    }
    this.setState( {canPassCode: false} );
  }

  handleCreateLink = (savedData) => {
    
    this.setState( {progDetails: savedData} );

    // Update State for View Selection
    this.setState(state => ({
      activeStep: 3
    }));
  }

  handleSplitClose = (sourceStr) => {
    
    if (sourceStr === 'gui') {

      let tempDetails = this.linkedGUIS.current.getProgDetails();
      tempDetails.selection.language = 'gui';
      this.setState( {progDetails: tempDetails} );

      // Update State for View Selection
      this.setState(state => ({
        activeStep: 2
      }));
    } else {

      let tempDetails = this.linkedCodingS.current.getProgDetails();
      tempDetails.selection.language = 'python';
      this.setState( {progDetails: tempDetails} );
            
      // Update State for View Selection
      this.setState(state => ({
        activeStep: 1
      }));
      this.setState( {programListType: 'My Programs'}) ;
    }
  }

  handleUnlink = () => {
    this.linkedGUIS.current.setState( {isLinked: false} );
  }

  enableCodeLink = () => {
    this.setState( {canPassCode: true} );
  }


  handleUpdateLinked = (buffer) => {
    if (this.state.canPassCode === true) {
      this.linkedCodingS.current.linkedBufferUpdate(buffer);
    }    
  }

  // When Close Or Delete Buttons Pressed in Child ProgramCoding - Called by ProgramCoding
  handleCloseDelete = (codingEvent) => {

    this.context.AuthInstance.getProgramList();

    // Update State for View Selection
    this.setState(state => ({
      activeStep: 0
    }));
    if ('backToShare' in codingEvent) {
      this.setState( {programListType: 'Programs shared with me'}) ;
    } else {
      this.setState( {programListType: 'My Programs'}) ;
    }
    
    this.setState( {canPassCode: false} );
  }


  handleUserMenuBtn = (event) => {
    
    this.setState( {userMenu: true, userAnchor: event.currentTarget} );
  };
  
  handleUserMenuClose = () => {

    this.setState( {userMenu: false} );
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  refreshFilter = () => {
    this.programList.current.refreshFilter()
  }

  handleListType = (type) => {
    this.refreshFilter()
    if (type === 0) {
      try {
        this.context.AuthInstance.getProgramList().then(res => {
          this.setState( {programListType: 'My Programs'}) ;
        });
      } catch {

      }

    } else if (type === 1) {
      try {
        this.context.AuthInstance.getSharingProgramList().then(res => {
          this.setState( {programListType: 'Programs I am sharing'}) ;
        });
      } catch {

      }

    } else if (type === 2) {
      try {
        this.context.AuthInstance.getSharedProgramList().then(res => {
          this.setState( {programListType: 'Programs shared with me'});
        });
      } catch {

      }

    }
    this.handleUserMenuClose();
  };


  // Takes input data, returns what to display
  render() {
    // Referenced below for setting styles
    const { classes } = this.props;
    // Referenced below for layout selection
    const { activeStep } = this.state;
    // For TopBar Route Highlight
    const currentPath = this.props.location.pathname;

    // What to Display - Selectable Views Based on activeStep
    return (
      
      <div className={classes.root}    
        style={{ height: '100vh', maxHeight: '100vh', backgroundColor: 'white', overflow: 'hidden'}}>
        <CssBaseline />
        <Topbar currentPath={currentPath} />
        {activeStep === 0 && (
          <div className={classes.scrollableContainer}>
            <Box justifyContent="center" style = {{ height: '100%' }}>
              <Grid container style = {{ height: '100%' }}>
                <Grid item xs={6} style={{ padding: '10px'}}>
                  <Card className={classes.cardList} elevation={5}>
                    <CardHeader 
                      title={
                        <React.Fragment>
                          <IconButton
                            aria-controls="customized-menu"
                            aria-haspopup="true"
                            variant="contained"
                            color="secondary"                
                            className={classes.userOptions}
                            onClick={this.handleUserMenuBtn}
                          >
                            {this.state.programListType} <ExpandMoreIcon fontSize="large" /> 
                          </IconButton>
                          <Menu
                            id="customized-menu"
                            elevation={0}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'center',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'center',
                            }}
                            anchorEl={this.state.userAnchor}
                            open={this.state.userMenu}
                            onClose={this.handleUserMenuClose}
                            classes={{ paper: classes.menu }} // Apply custom menu styles
                          >
                            <MenuItem className={classes.menuItem} onClick={() => this.handleListType(0)}>My Programs</MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.handleListType(1)}>Programs I am sharing</MenuItem>
                            <MenuItem className={classes.menuItem} onClick={() => this.handleListType(2)}>Programs shared with me</MenuItem>
                          </Menu>
                        </React.Fragment>
                      }
                      className={classes.paperList}
                    />
                    <ProgramList 
                      ref = {this.programList}
                      onCreateProgram = {this.handleCreateProgram} 
                      programListType={this.state.programListType}
                      listRefresh={this.handleListType}
                    />
                  </Card>
                </Grid>
                <Grid item xs={6} style={{ padding: '10px'}}>
                  <Card className={classes.cardCreate} elevation={5}>
                    <CardHeader 
                      title={
                        <Typography variant="h4" className={classes.headerText}>
                            Program Creation
                        </Typography>
                      }
                      className={classes.paper} 
                    />
                    <CardContent>
                      <ProgramCreate onCreateProgram = {this.handleCreateProgram}/>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </div>
        )}
        {activeStep === 1 && (
          <ProgramCoding 
            ref={this.linkedCoding}
            onCloseDeleteProgram={this.handleCloseDelete}
            progDetails={this.state.progDetails}
            linkedState={false}
            activeStep={this.state.activeStep}
          />
        )}
        {activeStep === 2 && (
          <ProgramGUI
            ref={this.linkedGUI}
            onCloseDeleteProgram={this.handleCloseDelete}
            progDetails={this.state.progDetails}
            linkedState={false}
            handleCreateLink={this.handleCreateLink}
            activeStep={this.state.activeStep}
          />
        )}
        {activeStep === 3 && (
          <Grid container justifyContent="center">
          <Grid
            spacing={0}
            container
          >
            <Grid item xs={6}>
                <ProgramGUI
                  ref={this.linkedGUIS}
                  onCloseDeleteProgram={this.handleCloseDelete}
                  progDetails={this.state.progDetails}
                  linkedState={true}
                  handleCreateLink={this.handleCreateLink}
                  handleUpdateLinked={this.handleUpdateLinked}
                  activeStep={this.state.activeStep}
                  handleSplitClose={this.handleSplitClose}
                />
            </Grid>
            <Grid item xs={6}>
                <ProgramCoding 
                  ref={this.linkedCodingS}
                  onCloseDeleteProgram={this.handleCloseDelete}
                  progDetails={this.state.progDetails}
                  linkedState={true}
                  setCodeStream={this.enableCodeLink}
                  handleUnlink={this.handleUnlink}
                  activeStep={this.state.activeStep}
                  handleSplitClose={this.handleSplitClose}
                />
            </Grid>
          </Grid>
          </Grid>
        )}
      </div>
    );
  }
}
// withRouter provides access to history for routes
export default withRouter(withStyles(styles)(Program));
