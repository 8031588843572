import React, { useRef, useState, useEffect, useImperativeHandle, forwardRef } from "react";

import { useFrame, extend, useThree, useLoader } from '@react-three/fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry';
//import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';


extend({ OrbitControls, TextGeometry });


//https://polyhaven.com/textures
/*
const groundTexture = textureLoader.load('../images/DroneView/rocky_terrain_02_diff_4k.jpg', (texture) => {
  console.log('Ground texture loaded', texture);
}, undefined, (err) => {
  console.error('Error loading ground texture', err);
});
//https://www.textures.com/
const skyTexture = textureLoader.load('../images/DroneView/DaySkyHDRI049A.jpg');

export const Ground = () => {

  const groundTexture = useLoader(THREE.TextureLoader, '/images/DroneView/textures/RockyGround.jpg');
  groundTexture.wrapS = groundTexture.wrapT = THREE.RepeatWrapping;
  groundTexture.repeat.set(10, 10);

  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 0]}>
      <circleGeometry args={[50, 64]} />
      <meshStandardMaterial 
        map={groundTexture}
      />
    </mesh>
  );
};

const GLTFModel = ({ url, onLoad }) => {
  const { scene } = useThree();
  const [model, setModel] = useState(null);

  useEffect(() => {
    const loader = new GLTFLoader();
    loader.load(url, (gltf) => {
      const loadedModel = gltf.scene;
      setModel(loadedModel);

      // Add the model to the scene
      scene.add(loadedModel);

      // Call the onLoad callback
      if (onLoad) {
        onLoad();
      }
    }, undefined, (error) => {
      console.error('An error happened while loading the GLTF model:', error);
    });

  }, [url, onLoad, scene]);

  return model ? (
    <primitive
      object={model}
    />
  ) : null;
};
*/

const RotatingCube = () => {
  const ref = useRef();

  useFrame(() => {
    if (ref.current) {
      ref.current.rotation.x += 0.01;
      ref.current.rotation.y += 0.01;
    }
  });

  return (
    <mesh ref={ref} position={[0, 4, 0]}>
      <boxGeometry args={[3, 3, 3]} />
      <meshStandardMaterial color="blue" />
    </mesh>
  );
};

export const Ground = ({ hdrTexture }) => {

  const groundTexture = useLoader(THREE.TextureLoader, '/images/DroneView/textures/GravellySand.jpg');
  //groundTexture.wrapS = groundTexture.wrapT = THREE.RepeatWrapping;
  //groundTexture.repeat.set(10, 10);
  groundTexture.wrapS = groundTexture.wrapT = THREE.RepeatWrapping;
  groundTexture.repeat.set(5, 5); // Adjust the repeat settings to reduce the number of times the texture is repeated
  groundTexture.anisotropy = 16; // Use anisotropic filtering to improve texture quality at oblique angles
  groundTexture.generateMipmaps = true; // Ensure mipmaps are generated

  if (!hdrTexture) return null;

  return (
    <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -0.5, 0]}>
      <circleGeometry args={[500, 64]} />
      <meshStandardMaterial 
        map={groundTexture}
        envMap={hdrTexture}
        metalness={0.5}
        roughness={0.5}
      />
    </mesh>
  );
};


export const Environment = ({ 
  setLoading, loading, position, orientation, trail, 
  onHDRLoad
}) => {
  const [hdrTexture, setHdrTexture] = useState(null);

  //const font = useLoader(FontLoader, '/fonts/helvetiker_regular.typeface.json');

  useEffect(() => {
    new RGBELoader().load('/images/DroneView/textures/KlippadDawn2.hdr', (texture) => {
      texture.mapping = THREE.EquirectangularReflectionMapping;
      texture.anisotropy = 16; // Use anisotropic filtering to improve texture quality
      setHdrTexture(texture);
      setTimeout(() => {
        setLoading(false); // Set loading to false after a delay
      }, 2000)
    });
  }, [setLoading]);
  
  const handleUpdate = () => {
    
    onHDRLoad();
  };

  //const handleModelLoad = () => {
    //onModelLoad();
  //}

  if (loading) {
    return (
      <>
        <ambientLight intensity={0.9} />
        <directionalLight position={[5, 5, 5]} intensity={1} />
        <RotatingCube />
      </>
    );
  }

  if (!hdrTexture) return null;

  return (
    <>
      <primitive object={hdrTexture} attach="background" onUpdate={handleUpdate} />
      <Ground hdrTexture={hdrTexture} />
      <ambientLight intensity={0.9} />
      <directionalLight position={[5, 5, 5]} intensity={1} />
      <Quadcopter position={position} orientation={orientation}/>
      {/*<DroneTrail trail={trail} />*/}
    </>
  );
};

/*
export const Sky = () => {
  
  const skyTexture = useLoader(THREE.TextureLoader, '/images/DroneView/textures/DaySky.jpg');
  skyTexture.wrapS = skyTexture.wrapT = THREE.RepeatWrapping;
  //skyTexture.repeat.set(10, 10);

  return (
    <mesh>
      <sphereGeometry args={[500, 32, 32]} />
      <meshBasicMaterial map={skyTexture} side={THREE.BackSide} />
    </mesh>
  );
};
*/

export const CombinedCamera = forwardRef((props, ref) => {
  const { camera, gl } = useThree();
  const controlsRef = useRef();
  const [lookAt, setLookAt] = useState({ x: 0, y: 0, z: 0 });
  const [initialSetup, setInitialSetup] = useState(false);

  useImperativeHandle(ref, () => ({
    updateLookAt: (x, y, z) => {
      setLookAt({ x, y, z });
    },
  }));

  useEffect(() => {
    if (!initialSetup) {
      // Set the camera's initial position and orientation once
      camera.position.set(7, 5, 7); 
      camera.lookAt(lookAt.x, lookAt.y, lookAt.z);
      setInitialSetup(true);
    } else {
      // Update the target of the controls
      if (controlsRef.current) {
        controlsRef.current.target.set(lookAt.x, lookAt.y, lookAt.z);
      }
    }
    
    if (controlsRef.current) {
      controlsRef.current.minPolarAngle = 0; // Prevent looking below the ground
      controlsRef.current.maxPolarAngle = Math.PI / 2; // Prevent looking above the horizon
      controlsRef.current.minDistance = 1; // Minimum distance the camera can be from the target
      controlsRef.current.maxDistance = 50; // Maximum distance the camera can be from the target
      controlsRef.current.update();
    }
  }, [camera, lookAt, initialSetup]);

  useFrame(() => {
    if (controlsRef.current) {
      controlsRef.current.update();
    }
  });

  return (
    <>
      <orbitControls ref={controlsRef} args={[camera, gl.domElement]} />
    </>
  );
});

export const DroneTrail = ({ trail }) => {
  
  const lineRef = useRef();

  useEffect(() => {
    if (lineRef.current) {
      const positionsArray = new Float32Array(trail.flatMap(v => [v.x, v.y, v.z]));
      lineRef.current.geometry.setAttribute('position', new THREE.BufferAttribute(positionsArray, 3));
      lineRef.current.geometry.attributes.position.needsUpdate = true;
    }
  }, [trail]);

  return (
    <line ref={lineRef}>
      <bufferGeometry attach="geometry">
        <bufferAttribute
          attachObject={['attributes', 'position']}
          array={new Float32Array(trail.flatMap(v => [v.x, v.y, v.z]))}
          count={trail.length}
          itemSize={3}
        />
      </bufferGeometry>
      <lineBasicMaterial attach="material" color="red" />
    </line>
  );
};

export const Box = ({ position, color, rotation, args }) => (
  <mesh position={position} rotation={rotation}>
    <boxGeometry args={args} />
    <meshStandardMaterial color={color} />
  </mesh>
);
    
export const Cylinder = ({ position, color, rotation, args }) => (
  <mesh position={position} rotation={rotation}>
      <cylinderGeometry args={args} />
      <meshStandardMaterial color={color} />
  </mesh>
);
  
const Quadcopter = ({ position, orientation }) => {
  const quadcopterRef = useRef();

  useFrame(() => {
    if (quadcopterRef.current) {

      const scaledPosition = {
        x: position.x * 10,
        y: position.y * 10,
        z: position.z * 10,
      };

      // Update position
      quadcopterRef.current.position.set(scaledPosition.x, scaledPosition.y, scaledPosition.z);

      // Update orientation
      quadcopterRef.current.rotation.set(
        THREE.MathUtils.degToRad(orientation.pitch),
        THREE.MathUtils.degToRad(orientation.yaw),
        THREE.MathUtils.degToRad(orientation.roll)
      );
    }
  });

  return (   	
    <group ref={quadcopterRef}>
      <Box args={[1, 0.2, 1]} position={[0, 0, 0]} rotation={[0, Math.PI / 4, 0]} color="blue" />
      
      <Box args={[1.2, 0.1, 0.1]} position={[0.6, 0, 0]} color="gray" />
      <Box args={[1.2, 0.1, 0.1]} position={[-0.6, 0, 0]} color="gray" />
      <Box args={[0.1, 0.1, 1.2]} position={[0, 0, 0.6]} color="gray" />
      <Box args={[0.1, 0.1, 1.2]} position={[0, 0, -0.6]} color="gray" />
      
      <Cylinder args={[0.1, 0.1, 0.2, 32]} position={[1.2, 0, 0]} rotation={[0, 0, 0]} color="red" />
      <Cylinder args={[0.1, 0.1, 0.2, 32]} position={[-1.2, 0, 0]} rotation={[0, 0, 0]} color="red" />
      <Cylinder args={[0.1, 0.1, 0.2, 32]} position={[0, 0, 1.2]} rotation={[0, 0, 0]} color="red" />
      <Cylinder args={[0.1, 0.1, 0.2, 32]} position={[0, 0, -1.2]} rotation={[0, 0, 0]} color="red" />
    </group>
  );
};