import { sleep } from '../../helpers/index.js';




class LocoHex {

    constructor() {
        this.port = null;
        this.reader = null;
        this.writer = null;

        this.lhTimeoutID = null;
        this.TIMEOUT_STR = "timeout";
        this.TIMEOUT_MAX = 3000;
        this.timeout_count = 0;
        
        this.can_process = false;
        
        this.read_loop = {
            targetLen: 0,
            targetLenOff: 0,
            header: 0,
            hasTargetLength: false,
            hasHeader: false
        }
        
        this.read_buffer = {
            data: [],
        }

        this.robotData = {
            scanEnd: false,
            scanData: [],
            connectEnd: false,
            connectState: 0,
            connectFirmware: false,
            receivedData: false,
            waitFlag: false,
            waitData: "",
            messageData: {}
        }

        this.CONNECT_STATES = {
            CONNECT_FAIL: 0,
            CONNECT_OK: 1 
        };

        this.POSE_TYPES = {
            DEFAULT_POSE: 0,
            DEFAULT_POSE_M: 1,
        };
        this.TRAVEL_DIRS = {
            FORWARD: 0,
            BACKWARD: 1,
            LEFT: 2,
            RIGHT: 3,
        };
        this.ACTION_TYPES = {
            TWIST: 0,
            STAND_HIGH: 1,
            STAND_MIDDLE: 2,
            STAND_LOW: 3,
            TURN_LEFT: 4,
            TURN_RIGHT: 5
        };
        this.COMMANDS = {
            SSID_LIST_ITEM: 84, // 'T'
            SSID_LIST_END: 85, // 'U'
            WIFI_SCAN: 83, // 'S'
            WIFI_CONNECT: 67, // 'C'
            WIFI_DISCONNECT: 68, // 'D'
            PT_VERSION: 86, // 'V'
            ROBOT_CONTROL: 82, // 'R'
            ROBOT_POSE: 80, // 'P'
            ROBOT_ACTION: 65, // 'A'
            ROBOT_TRAVEL: 84 // 'T'
        };
    }

    clearLHTimeout = () => {
        try {
            if (this.lhTimeoutID !== null) {
                window.clearTimeout(this.lhTimeoutID);
                this.lhTimeoutID = null;
            }
        } catch (err) { }
    }

    clearReadState = () => {

        this.read_loop = {
            targetLen: 0,
            targetLenOff: 0,
            header: 0,
            hasTargetLength: false,
            hasHeader: false
        }

        this.read_buffer = {
            data: [],
        }
        
        this.robotData = {
            scanEnd: false,
            scanData: [],
            connectEnd: false,
            connectState: 0,
            connectFirmware: false,
            receivedData: false,
            waitFlag: false,
            waitData: "",
            messageData: {}
        }


    }

    setup = async() => {
        try {
            
            this.clearLHTimeout();
            this.clearReadState();
            this.can_process = false;

            await sleep(500);

            const usbVendorId = 0x10C4;
            this.port = await navigator.serial.requestPort({ filters: [{ usbVendorId }]});
            // - Wait for the port to open.
            await this.port.open({ baudRate: 115200, bufferSize: 2048 });//115200, bufferSize: 2048 });
            
            this.reader = this.port.readable.getReader();
        
            this.readLoop();
            await sleep(4000); // wait for any potential junk data to stop coming in
            return 0
        } catch (err) {
            if (err.toString().includes("NotFoundError")) {
                //alert("Device not found. Please connect the device and try again.");
                return -1
            } else {
                return -2
            }
        }
    };

    readLoop = async() => {
        while (true) {
            let output = await this.update_buffer();
            if (output === -1) {
                this.reader.releaseLock();
                break;
            } else if (output === -2) {
                try {
                    this.reader = this.port.readable.getReader();
                } catch {
                    this.reader.releaseLock();
                    break;                    
                }
            }
            if (this.can_process === true) {
                this.process_buffer();
            }
        }
    }

    update_buffer = async() => {
        try {
            const { value, done } = await this.reader.read()
            let nowPacket = new Uint8Array(value.buffer);
            if (this.can_process === true) {
                this.read_buffer.data = [...this.read_buffer.data , ...nowPacket]
            }
            if (done) {
                return -1;
            } else {
                return 0;
            }
        } catch (err) {
            return -2
        }
    };

    process_buffer = async() => {
        if (this.read_loop.hasHeader === false) {
            // Have Header Now
            this.read_loop.header = this.read_buffer.data[0]
            this.read_loop.hasHeader = true
    
            // Not enough data for length byte
            if (this.read_buffer.data.length < 3) {
                return;
            }
    
            this.read_loop.targetLen = this.read_buffer.data[1]
            this.read_loop.targetLenOff = 2
            this.read_loop.hasTargetLength = true
    
            // Check if the rest of the packet is in the buffer - if not, return
            let fullLength = (this.read_loop.targetLen + this.read_loop.targetLenOff)
            if (this.read_buffer.data.length < fullLength) {
                return;
            }
    
            // Read Current Packet
            let myPacket = this.read_buffer.data.slice(0, fullLength)
    
            // Process Packet
            this.process_serial(myPacket)
    
            // Update Buffer without throwing out extra, if extra
            this.read_buffer.data = this.read_buffer.data.slice(fullLength, this.read_buffer.data.length)
    
            // Reset Parameters
            this.clear_packet_info()       
    
        } else {
            // Does Not Already Have Target Length?
            if (this.read_loop.hasTargetLength !== true) {
                
                // Not enough data for length byte
                if (this.read_buffer.data.length < 3) {
                    return;
                }
    
                this.read_loop.targetLen = this.read_buffer.data[1]
                this.read_loop.targetLenOff = 2
                this.read_loop.hasTargetLength = true
            }
            
            // Check if the rest of the packet is in the buffer - if not, return
            let fullLength = (this.read_loop.targetLen + this.read_loop.targetLenOff)
            if (this.read_buffer.data.length < fullLength) {
                return;
            }
            
            // Read Current Packet
            let myPacket = this.read_buffer.data.slice(0, fullLength)
    
            // Process Packet
            this.process_serial(myPacket)
    
            // Update Buffer without throwing out extra, if extra
            this.read_buffer.data = this.read_buffer.data.slice(fullLength, this.read_buffer.data.length)
    
            // Reset Parameters
            this.clear_packet_info()
        }
    };

    process_serial = async(packet) => {
        switch (packet[0]) {
            case this.COMMANDS.SSID_LIST_ITEM: {
                let name = packet.slice(2, 2 + packet[1] - 1);
                name = String.fromCharCode.apply(null, name);
                this.robotData['scanData'].push(name)
                break;
            }
            case this.COMMANDS.SSID_LIST_END: {
                this.robotData['scanEnd'] = true;
                break
            }
            case this.COMMANDS.WIFI_CONNECT: {
                this.robotData['connectEnd'] = true;
                this.robotData['connectState'] = packet[2];
                if (packet[2] === 3) {
                    let message = String.fromCharCode.apply(null, packet.slice(3));
                }
                break
            }
            case this.COMMANDS.WIFI_DISCONNECT: {
                this.robotData['connectEnd'] = true;
                this.robotData['connectState'] = packet[2];
                break
            }
            case this.COMMANDS.PT_VERSION: {
                break
            }
            case this.COMMANDS.ROBOT_CONTROL: {
                //let message = String.fromCharCode.apply(null, packet.slice(2));
                break;
            }
            default: {
                //let message = String.fromCharCode.apply(null, packet.slice(2));
                //console.log("misc message:", message);
                break;
            }
        }
        
    };

    clear_packet_info = () => {
        this.read_loop.header = 0
        this.read_loop.hasHeader = false
        this.read_loop.targetLen = 0
        this.read_loop.hasTargetLength = false
        this.read_loop.targetLenOff = 0
    };
    
    // Write Data Packet Over WebSerial
    write_packet = async (data) => {
        let packet = Uint8Array.from(data);
        await this.webserial_write(packet);
    };
    webserial_write = async(data) => {
        if (this.can_process === false) {
            this.can_process = true
        }
        //console.log("write:", data);
        const writer = this.port.writable.getWriter();
        await writer.write(data);
        writer.releaseLock();
    };

    scan = async() => {
        try {
            let scan_packet = [this.COMMANDS.WIFI_SCAN, 1, 0]; // scan, length, 0
            await this.write_packet(scan_packet);

            this.robotData['scanEnd'] = false;
            this.robotData['scanData'] = [];

            let me = this;
            this.timeout_count = 0;
            
            return new Promise(function (resolve, reject) {     
                var f = function () {
                    if (me.robotData['scanEnd'] === true) {
                        //console.log("in hex:", me.robotData['scanData'])
                        resolve(me.robotData['scanData']);
                    } else {
                        if (me.timeout_count < 30000) { // 15 seconds
                            me.lhTimeoutID = window.setTimeout(f, 5);
                        } else {
                            resolve(me.TIMEOUT_STR);
                        }                    
                        me.timeout_count += 10;
                    }
                }
                window.setTimeout(f, 5);
            });
        } catch (err) {
            return []
        }
    };

    connect = async(robots, robot) => {

        if (!robots.includes(robot)) {
            throw new Error('Robot not found in the list of robots');
        }


        this.robotData['connectEnd'] = false;
        this.robotData['connectState'] = 0;

        let me = this;
        
        return new Promise(function (resolve, reject) {
            
            let packet = [me.COMMANDS.WIFI_CONNECT, robot.length];
            for (let t = 0; t < robot.length; t++) {
                packet.push(robot.charCodeAt(t));
            }
            me.write_packet(packet);  
            
            let m = function () {
                me.pose(me.POSE_TYPES.DEFAULT_POSE, 2.0).then(() => {
                    resolve("done");
                })
            }

            let f = function () {
                if (me.robotData['connectEnd'] === true) {
                    if (me.robotData['connectState'] !== me.CONNECT_STATES.CONNECT_OK) {
                        reject("Could Not Connect To The Robot. Please Try Running the Program Again.");
                    } else {
                        window.setTimeout(m, 2500);
                    }
                } else {
                    window.setTimeout(f, 5);
                }
            }

            window.setTimeout(f, 5);
        });
    }

    disconnect = async() => {
        try {
            await this.disconnect_wifi();
            await this.disconnect_serial();
        } catch (err) {
        } finally {
            this.clearLHTimeout();
            this.clearReadState();
        }
    };

    disconnect_quick = async() => {
        try {
            await this.disconnect_wifi_quick();
            this.disconnect_serial();
        } catch (err) {
        } finally {
            this.clearLHTimeout();
            this.clearReadState();
        }
    }

    disconnect_wifi_quick = async() => {
        await this.pose(this.POSE_TYPES.DEFAULT_POSE, 1.0)
        await sleep(500);
        let packet = [this.COMMANDS.WIFI_DISCONNECT, 1, 0]; // disconnect, length, 0
        await this.write_packet(packet);
        await sleep(250);
    }

    disconnect_wifi = async() => {

        let packet = [this.COMMANDS.WIFI_DISCONNECT, 1, 0]; // disconnect, length, 0
        await this.write_packet(packet);

        this.robotData['connectEnd'] = false;
        this.robotData['connectState'] = 0;

        let me = this;
        this.timeout_count = 0;

        return new Promise(function (resolve, reject) {
            var f = function () {
                if (me.robotData['connectEnd'] === true) {
                    resolve("done");
                } else {
                    if (me.timeout_count < 30000) { // 15 seconds
                        me.lhTimeoutID = window.setTimeout(f, 5);
                    } else {
                        resolve(me.TIMEOUT_STR);
                    }
                    me.timeout_count += 10;
                }
            }
            window.setTimeout(f, 5);
        });
    }

    disconnect_serial = async() => {
        try {
            // Close the input stream (reader).
            if (this.reader) {
              try {
                  await this.reader.cancel();                
              } catch (err) {
              }
              this.reader = null;
            }
            // Close the port.
            await this.port.close();
            this.port = null;
          } catch (err) { }
    }


    pose = async(poseType, duration) => {
        if (!Object.values(this.POSE_TYPES).includes(poseType)) {
            throw new Error('Invalid pose type');
        }
        if (typeof duration !== 'number' || duration <= 0) {
            throw new Error('Duration must be a positive number');
        }

        let packet = [
            this.COMMANDS.ROBOT_CONTROL, 6, this.COMMANDS.ROBOT_POSE, poseType
        ];

        // Create a buffer with 4 bytes (32 bits) to hold the float
        let buffer = new ArrayBuffer(4);
        // Create a DataView to interact with the buffer
        let view = new DataView(buffer);
        // Set the float value at the beginning of the buffer
        view.setFloat32(0, duration, true); // true for little-endian
        // Extract the bytes from the buffer
        let bytes = [];
        for (let i = 0; i < 4; i++) {
            bytes.push(view.getUint8(i));
        }
        // Append the bytes to the packet
        packet = packet.concat(bytes);

        await this.write_packet(packet);

        let me = this;

        let waitTime = Math.round(duration * 1000) + 200; // duration and extra
        return new Promise(function (resolve, reject) {
            var f = function () {
                resolve("done");
            }
            me.lhTimeoutID = window.setTimeout(f, waitTime);
        });
    }

    travel = async(dir, duration) => {
        if (!Object.values(this.TRAVEL_DIRS).includes(dir)) {
            throw new Error('Invalid travel direction');
        }
        if (typeof duration !== 'number' || duration <= 0) {
            throw new Error('Duration must be a positive number');
        }
        duration = Math.floor(duration);

        let packet = [
            this.COMMANDS.ROBOT_CONTROL, 4, this.COMMANDS.ROBOT_TRAVEL, dir
        ];
        packet.push((duration >> 8) & 0xFF);
        packet.push(duration & 0xFF);
        await this.write_packet(packet);

        duration += 1000; // duration and extra

        let me = this;

        return new Promise(function (resolve, reject) {
            var f = function () {
                resolve("done");
            }
            me.lhTimeoutID = window.setTimeout(f, duration);
        });
    }

    action_set = async(actionType) => {
        if (!Object.values(this.ACTION_TYPES).includes(actionType)) {
            throw new Error('Invalid action type');
        }

        let packet = [
            this.COMMANDS.ROBOT_CONTROL, 2, this.COMMANDS.ROBOT_ACTION, actionType
        ];
        await this.write_packet(packet);

        let me = this;

        return new Promise(function (resolve, reject) {
            var f = function () {
                resolve("done");
            }
            me.lhTimeoutID = window.setTimeout(f, 600);
        });

    }




};


export { LocoHex };