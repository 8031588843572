import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import { forwardRef } from 'react';
import withStyles from "@material-ui/styles/withStyles";
import { CombinedCamera, Environment } from './DroneCanvasHelpers';

// Material-UI CSS-type Style Specifications
const styles = (theme) => ({
    canvasDiv: {
      display: 'flex',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'green'
    },
 
 });
 

const DroneCanvas = forwardRef((props, ref) => {
    
    const [state, setState] = useState({ x: 0, y: 0, z: 0, roll: 0, pitch: 0, yaw: 0 });
    const [trail, setTrail] = useState([]);
    const [loading, setLoading] = useState(true);
    const cameraRef = useRef();
    //const MAX_TRAIL_LENGTH = 100; // Limit the number of positions in the trail

    useImperativeHandle(ref, () => ({
      updateQuadcopter: (x, y, z, roll, pitch, yaw) => {
        setState(prevState => ({...prevState, x, y, z, roll, pitch, yaw }));
        /*
        setTrail(prevTrail => {
            const newTrail = [...prevTrail, new THREE.Vector3(x, y, z)];
            if (newTrail.length > MAX_TRAIL_LENGTH) {
              newTrail.shift(); // Remove the oldest position if the trail exceeds the maximum length
            }
            return newTrail;
        });*/
        if (cameraRef.current) {
            cameraRef.current.updateLookAt(x, y, z);
        }
      },
    }));
    
    useEffect(() => {
        if (props.onReady) {
            props.onReady();
        }
    }, [props.onReady]);
    
    return (
        <div className={props.classes.canvasDiv}>
            <Canvas>
                <CombinedCamera ref={cameraRef} />
                <Environment 
                    setLoading={setLoading} 
                    loading={loading} 
                    position = {{x: state.x, y: state.y, z: state.z }}
                    orientation = {{roll: state.roll, pitch: state.pitch, yaw: state.yaw }}
                    trail = {trail}
                    onHDRLoad={props.onHDRLoad}
                />
                {/*<axesHelper args={[5]} />*/}
                <gridHelper />
            </Canvas>
        </div>
    );
});

export default withStyles(styles)(DroneCanvas);