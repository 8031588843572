export const DeviceType = Object.freeze({
    None_: 0x00,

    Drone: 0x10,          // (Server)

    Controller: 0x20,     // (Client)

    Link: 0x30,           // (Client)
    LinkServer: 0x31,     // (Server, )
    BleClient: 0x32,      // BLE 
    BleServer: 0x33,      // BLE 

    Range: 0x40,          // 

    Base: 0x70,           // 

    ByScratch: 0x80,      // 
    Scratch: 0x81,        // 
    Entry: 0x82,          // 

    Tester: 0xA0,         // 
    Monitor: 0xA1,        // 
    Updater: 0xA2,        // 
    Encrypter: 0xA3,      // 

    Whispering: 0xFE,     // 
    Broadcasting: 0xFF
});

export const ModeFlight = Object.freeze({
    None_: 0x00,

    Ready: 0x10,

    Start: 0x11,
    TakeOff: 0x12,
    Flight: 0x13,
    Landing: 0x14,
    Flip: 0x15,
    Reverse: 0x16,

    Stop: 0x20,

    Accident: 0x30,
    Error: 0x31,

    Test: 0x40,

    EndOfType: 0x41,
    0x00: 'None_',
    0x10: 'Ready',
    0x11: 'Start',
    0x12: 'TakeOff',
    0x13: 'Flight',
    0x14: 'Landing',
    0x15: 'Flip',
    0x16: 'Reverse',
    0x20: 'Stop',
    0x30: 'Accident',
    0x31: 'Error',
    0x40: 'Test',
    0x41: 'EndOfType'
});


export const CommandType = Object.freeze({
    None_: 0x00,                   // 

    Stop: 0x01,                    // 

    // 
    ModeControlFlight: 0x02,       // 
    Headless: 0x03,                // 
    ControlSpeed: 0x04,            // 

    ClearBias: 0x05,               // 
    ClearTrim: 0x06,               // 

    FlightEvent: 0x07,             // 

    SetDefault: 0x08,              // 
    Backlight: 0x09,               // 
    ModeController: 0x0A,          // (0x10:, 0x80:)
    Link: 0x0B,                    // (0:Client Mode, 1:Server Mode, 2:Pairing Start)

    // 
    ClearCounter: 0xA0,            //

    // Navigation
    NavigationTargetClear: 0xE0,   //
    NavigationStart: 0xE1,         //
    NavigationPause: 0xE2,         //
    NavigationRestart: 0xE3,       //
    NavigationStop: 0xE4,          //
    NavigationNext: 0xE5,          //
    NavigationReturnToHome: 0xE6,  //

    GpsRtkBase: 0xEA,
    GpsRtkRover: 0xEB,

    EndOfType: 0xEC
});

export const FlightEvent = Object.freeze({
    None_: 0x00,

    Stop: 0x10,
    TakeOff: 0x11,
    Landing: 0x12,

    Reverse: 0x13,

    FlipFront: 0x14,
    FlipRear: 0x15,
    FlipLeft: 0x16,
    FlipRight: 0x17,

    Return: 0x18,

    Shot: 0x90,
    UnderAttack: 0x91,

    ResetHeading: 0xA0,

    EndOfType: 0xA1
});

export const DataType = Object.freeze({
    None_: 0x00,                       // 
    
    Ping: 0x01,                        // 
    Ack: 0x02,                         // 
    Error: 0x03,                       // reserve, 
    Request: 0x04,                     // 
    Message: 0x05,                     // 
    Address: 0x06,                     // MAC
    Information: 0x07,                 // 
    Update: 0x08,                      // 
    UpdateLocation: 0x09,              // 
    Encrypt: 0x0A,                     // 
    SystemCount: 0x0B,                 // 
    SystemInformation: 0x0C,           // 
    Registration: 0x0D,                // 
    Administrator: 0x0E,               // 
    Monitor: 0x0F,                     // 
    Control: 0x10,                     // 
    Command: 0x11,                     // 
    Pairing: 0x12,                     // 
    Rssi: 0x13,                        // RSSI
    TimeSync: 0x14,                    // 
    TransmissionPower: 0x15,           // 
    Configuration: 0x16,               // 
    Echo: 0x17,                        // 
    Battle: 0x1F,                      // 
    // Light
    LightManual: 0x20,                 // LED 
    LightMode: 0x21,                   // LED 
    LightEvent: 0x22,                  // LED 
    LightDefault: 0x23,                // LED 
    //  RAW 
    RawMotion: 0x30,                   // Motion
    RawFlow: 0x31,                     // Flow
    // 
    State: 0x40,                       // 
    Attitude: 0x41,                    // (Angle)
    Position: 0x42,                    // 
    Altitude: 0x43,                    // 
    Motion: 0x44,                      // Motion (IMU)
    Range: 0x45,                       // 
    Flow: 0x46,                        // optical flow
    // 
    Count: 0x50,                       // 
    Bias: 0x51,                        // 
    Trim: 0x52,                        // 
    Weight: 0x53,                      // 
    LostConnection: 0x54,              // 
    // Devices
    Motor: 0x60,                       // 
    MotorSingle: 0x61,                 // 
    Buzzer: 0x62,                      // 
    Vibrator: 0x63,                    // 
    // Input
    Button: 0x70,                      // 
    Joystick: 0x71,                    // 
    // Display
    DisplayClear: 0x80,                // 
    DisplayInvert: 0x81,               // 
    DisplayDrawPoint: 0x82,            // 
    DisplayDrawLine: 0x83,             // 
    DisplayDrawRect: 0x84,             // 
    DisplayDrawCircle: 0x85,           // 
    DisplayDrawString: 0x86,           // 
    DisplayDrawStringAlign: 0x87,      // 
    DisplayDrawImage: 0x88,            // 
    // Card
    CardClassify: 0x90,                // 
    CardRange: 0x91,                   // 
    CardRaw: 0x92,                     // 
    CardColor: 0x93,                   // 
    CardList: 0x94,                    // 
    CardFunctionList: 0x95,            // 
    
    // Information Assembled
    InformationAssembledForController: 0xA0, // 
    InformationAssembledForEntry: 0xA1,      // 
    InformationAssembledForByBlocks: 0xA2,   // 
    // Navigation
    NavigationTarget: 0xD0,            // 
    NavigationLocation: 0xD1,          // 
    NavigationMonitor: 0xD2,
    NavigationHeading: 0xD3,
    NavigationCounter: 0xD4,
    NavigationSatellite: 0xD5,         // 
    NavigationLocationAdjust: 0xD6,    // 
    NavigationTargetEcef: 0xD8,        // (ECEF)
    NavigationLocationEcef: 0xD9,      // (ECEF)
    GpsRtkNavigationState: 0xDA,               // RTK RAW
    GpsRtkExtendedRawMeasurementData: 0xDB,    // RTK RAW
    EndOfType: 0xDC,
    0x00: 'None_',
    0x01: 'Ping',
    0x02: 'Ack',
    0x03: 'Error',
    0x04: 'Request',
    0x05: 'Message',
    0x06: 'Address',
    0x07: 'Information',
    0x08: 'Update',
    0x09: 'UpdateLocation',
    0x0A: 'Encrypt',
    0x0B: 'SystemCount',
    0x0C: 'SystemInformation',
    0x0D: 'Registration',
    0x0E: 'Administrator',
    0x0F: 'Monitor',
    0x10: 'Control',
    0x11: 'Command',
    0x12: 'Pairing',
    0x13: 'Rssi',
    0x14: 'TimeSync',
    0x15: 'TransmissionPower',
    0x16: 'Configuration',
    0x17: 'Echo',
    0x1F: 'Battle',
    0x20: 'LightManual',
    0x21: 'LightMode',
    0x22: 'LightEvent',
    0x23: 'LightDefault',
    0x30: 'RawMotion',
    0x31: 'RawFlow',
    0x40: 'State',
    0x41: 'Attitude',
    0x42: 'Position',
    0x43: 'Altitude',
    0x44: 'Motion',
    0x45: 'Range',
    0x46: 'Flow',
    0x50: 'Count',
    0x51: 'Bias',
    0x52: 'Trim',
    0x53: 'Weight',
    0x54: 'LostConnection',
    0x60: 'Motor',
    0x61: 'MotorSingle',
    0x62: 'Buzzer',
    0x63: 'Vibrator',
    0x70: 'Button',
    0x71: 'Joystick',
    0x80: 'DisplayClear',
    0x81: 'DisplayInvert',
    0x82: 'DisplayDrawPoint',
    0x83: 'DisplayDrawLine',
    0x84: 'DisplayDrawRect',
    0x85: 'DisplayDrawCircle',
    0x86: 'DisplayDrawString',
    0x87: 'DisplayDrawStringAlign',
    0x88: 'DisplayDrawImage',
    0x90: 'CardClassify',
    0x91: 'CardRange',
    0x92: 'CardRaw',
    0x93: 'CardColor',
    0x94: 'CardList',
    0x95: 'CardFunctionList',
    0xA0: 'InformationAssembledForController',
    0xA1: 'InformationAssembledForEntry',
    0xA2: 'InformationAssembledForByBlocks',
    0xD0: 'NavigationTarget',
    0xD1: 'NavigationLocation',
    0xD2: 'NavigationMonitor',
    0xD3: 'NavigationHeading',
    0xD4: 'NavigationCounter',
    0xD5: 'NavigationSatellite',
    0xD6: 'NavigationLocationAdjust',
    0xD8: 'NavigationTargetEcef',
    0xD9: 'NavigationLocationEcef',
    0xDA: 'GpsRtkNavigationState',
    0xDB: 'GpsRtkExtendedRawMeasurementData', 
    0xDC: 'EndOfType'
});



export class CRC16 {
    static table = [
        0x0000, 0x1021, 0x2042, 0x3063, 0x4084, 0x50a5, 0x60c6, 0x70e7,
        0x8108, 0x9129, 0xa14a, 0xb16b, 0xc18c, 0xd1ad, 0xe1ce, 0xf1ef,
        0x1231, 0x0210, 0x3273, 0x2252, 0x52b5, 0x4294, 0x72f7, 0x62d6,
        0x9339, 0x8318, 0xb37b, 0xa35a, 0xd3bd, 0xc39c, 0xf3ff, 0xe3de,
        0x2462, 0x3443, 0x0420, 0x1401, 0x64e6, 0x74c7, 0x44a4, 0x5485,
        0xa56a, 0xb54b, 0x8528, 0x9509, 0xe5ee, 0xf5cf, 0xc5ac, 0xd58d,
        0x3653, 0x2672, 0x1611, 0x0630, 0x76d7, 0x66f6, 0x5695, 0x46b4,
        0xb75b, 0xa77a, 0x9719, 0x8738, 0xf7df, 0xe7fe, 0xd79d, 0xc7bc,
        0x48c4, 0x58e5, 0x6886, 0x78a7, 0x0840, 0x1861, 0x2802, 0x3823,
        0xc9cc, 0xd9ed, 0xe98e, 0xf9af, 0x8948, 0x9969, 0xa90a, 0xb92b,
        0x5af5, 0x4ad4, 0x7ab7, 0x6a96, 0x1a71, 0x0a50, 0x3a33, 0x2a12,
        0xdbfd, 0xcbdc, 0xfbbf, 0xeb9e, 0x9b79, 0x8b58, 0xbb3b, 0xab1a,
        0x6ca6, 0x7c87, 0x4ce4, 0x5cc5, 0x2c22, 0x3c03, 0x0c60, 0x1c41,
        0xedae, 0xfd8f, 0xcdec, 0xddcd, 0xad2a, 0xbd0b, 0x8d68, 0x9d49,
        0x7e97, 0x6eb6, 0x5ed5, 0x4ef4, 0x3e13, 0x2e32, 0x1e51, 0x0e70,
        0xff9f, 0xefbe, 0xdfdd, 0xcf7c, 0xbf1b, 0xaf3a, 0x9f59, 0x8f78,
        0x9188, 0x81a9, 0xb1ca, 0xa1eb, 0xd10c, 0xc12d, 0xf14e, 0xe16f,
        0x1080, 0x00a1, 0x30c2, 0x20e3, 0x5004, 0x4025, 0x7046, 0x6067,
        0x83b9, 0x9398, 0xa3fb, 0xb3da, 0xc33d, 0xd31c, 0xe37f, 0xf35e,
        0x02b1, 0x1290, 0x22f3, 0x32d2, 0x4235, 0x5214, 0x6277, 0x7256,
        0xb5ea, 0xa5cb, 0x95a8, 0x8589, 0xf56e, 0xe54f, 0xd52c, 0xc50d,
        0x34e2, 0x24c3, 0x14a0, 0x0481, 0x7466, 0x6447, 0x5424, 0x4405,
        0xa7db, 0xb7fa, 0x8799, 0x97b8, 0xe75f, 0xf77e, 0xc71d, 0xd73c,
        0x26d3, 0x36f2, 0x0691, 0x16b0, 0x6657, 0x7676, 0x4615, 0x5634,
        0xd94c, 0xc96d, 0xf90e, 0xe92f, 0x99c8, 0x89e9, 0xb98a, 0xa9ab,
        0x5844, 0x4865, 0x7806, 0x6827, 0x18c0, 0x08e1, 0x3882, 0x28a3,
        0xcb7d, 0xdb5c, 0xeb3f, 0xfb1e, 0x8bf9, 0x9bd8, 0xabbb, 0xbb9a,
        0x4a75, 0x5a54, 0x6a37, 0x7a16, 0x0af1, 0x1ad0, 0x2ab3, 0x3a92,
        0xfd2e, 0xed0f, 0xdd6c, 0xcd4d, 0xbdaa, 0xad8b, 0x9de8, 0x8dc9,
        0x7c26, 0x6c07, 0x5c64, 0x4c45, 0x3ca2, 0x2c83, 0x1ce0, 0x0cc1,
        0xef1f, 0xff3e, 0xcf5d, 0xdf7c, 0xaf9b, 0xbfba, 0x8fd9, 0x9ff8,
        0x6e17, 0x7e36, 0x4e55, 0x5e74, 0x2e93, 0x3eb2, 0x0ed1, 0x1ef0
    ];

    static calc(data, crc) {
        let crc2 = null;
        if (typeof data === 'number') {
            const index = ((crc >> 8) ^ data) & 0x00FF;
            crc2 = ((crc << 8) ^ CRC16.table[index]) & 0xFFFF;
        } else if (Array.isArray(data) || ArrayBuffer.isView(data)) {
            crc2 = crc;
            for (let i = 0; i < data.length; i++) {
                const index = ((crc2 >> 8) ^ data[i]) & 0x00FF;
                crc2 = ((crc2 << 8) ^ CRC16.table[index]) & 0xFFFF;
            }
        }
        return crc2;
    }
}

export class RequestLDC {
    constructor() {
        this.dataType = DataType.None_;
    }

    static getSize() {
        return 1;
    }

    toArray() {
        const buffer = new ArrayBuffer(1);
        const view = new DataView(buffer);
        view.setUint8(0, this.dataType);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== this.getSize()) {
            return null;
        }

        const data = new RequestLDC();
        const view = new DataView(dataArray.buffer);
        data.dataType = view.getUint8(0);
        data.dataType = DataType[data.dataType];

        return data;
    }
}

export class Header {
    constructor() {
        this.dataType = DataType.None_;
        this.length = 0;
        this.from_ = DeviceType.None_;
        this.to_ = DeviceType.None_;
    }

    static getSize() {
        return 4;
    }

    toArray() {
        const buffer = new ArrayBuffer(4);
        const view = new DataView(buffer);
        view.setUint8(0, this.dataType);
        view.setUint8(1, this.length);
        view.setUint8(2, this.from_);
        view.setUint8(3, this.to_);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== this.getSize()) {
            return null;
        }

        const header = new Header();
        const view = new DataView(dataArray.buffer);
        header.dataType = view.getUint8(0);
        header.length = view.getUint8(1);
        header.from_ = view.getUint8(2);
        header.to_ = view.getUint8(3);

        header.dataType = DataType[header.dataType];
        header.from_ = DeviceType[header.from_];
        header.to_ = DeviceType[header.to_];

        return header;
    }
}

export class Command {
    constructor() {
        this.commandType = CommandType.None_;
        this.option = 0;
    }

    static getSize() {
        return 2;
    }

    toArray() {
        const buffer = new ArrayBuffer(2);
        const view = new DataView(buffer);
        view.setUint8(0, this.commandType);
        view.setUint8(1, this.option);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== this.getSize()) {
            return null;
        }

        const command = new Command();
        const view = new DataView(dataArray.buffer);
        command.commandType = view.getUint8(0);
        command.option = view.getUint8(1);

        command.commandType = CommandType[command.commandType];

        return command;
    }
}

export class ControlQuad8 {
    constructor() {
        this.roll = 0;
        this.pitch = 0;
        this.yaw = 0;
        this.throttle = 0;
    }

    static getSize() {
        return 4;
    }

    toArray() {
        const buffer = new ArrayBuffer(4);
        const view = new DataView(buffer);
        view.setInt8(0, this.roll);
        view.setInt8(1, this.pitch);
        view.setInt8(2, this.yaw);
        view.setInt8(3, this.throttle);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== this.getSize()) {
            return null;
        }

        const data = new ControlQuad8();
        const view = new DataView(dataArray.buffer);
        data.roll = view.getInt8(0);
        data.pitch = view.getInt8(1);
        data.yaw = view.getInt8(2);
        data.throttle = view.getInt8(3);
        return data;
    }
};


export const StateLoading = Object.freeze({
    Ready: 0x00,
    Receiving: 0x01,
    Loaded: 0x02,
    Failure: 0x03,
    0x00: 'Ready',
    0x01: 'Receiving',
    0x02: 'Loaded',
    0x03: 'Failure'
});

const Section = Object.freeze({
    Start: 0x00,
    Header: 0x01,
    Data: 0x02,
    End: 0x03,
    0x00: 'Start',
    0x01: 'Header',
    0x02: 'Data',
    0x03: 'End'
});

export class Receiver {
    constructor() {
        this.state = StateLoading.Ready;
        this.sectionOld = Section.End;
        this.section = Section.Start;
        this.index = 0;

        this.header = new Header();
        this.timeReceiveStart = 0;
        this.timeReceiveComplete = 0;

        this.crc16received          = 0;
        this.crc16calculated        = 0;

        this._buffer = [];
        this.data = [];
        this.message = null;
    }

    call(data) {
        const now = performance.now();

        this.message = null;

        // First Step
        if (this.state === StateLoading.Failure) {
            this.state = StateLoading.Ready;
        }

        // Second Step
        if (this.state === StateLoading.Ready) {
            this.section = Section.Start;
            this.index = 0;
        } else if (this.state === StateLoading.Receiving) {
            // If 600ms have passed since data reception started, output an error
            if ((this.timeReceiveStart + 600) < now) {
                this.state = StateLoading.Failure;
                this.message = "Error / Receiver / StateLoading.Receiving / Time over.";
                return this.state;
            }
        } else if (this.state === StateLoading.Loaded) {
            return this.state;
        }

        // Third Step
        if (this.section !== this.sectionOld) {
            this.index = 0;
            this.sectionOld = this.section;
        }

        // Third Step
        if (this.section === Section.Start) {
            if (this.index === 0) {
                if (data === 0x0A) {
                    this.state = StateLoading.Receiving;
                } else {
                    this.state = StateLoading.Failure;
                    return this.state;
                }
                this.timeReceiveStart = now;
            } else if (this.index === 1) {
                if (data !== 0x55) {
                    this.state = StateLoading.Failure;
                    return this.state;
                } else {
                    this.section = Section.Header;
                }
            } else {
                this.state = StateLoading.Failure;
                this.message = "Error / Receiver / Section.Start / Index over.";
                return this.state;
            }
        } else if (this.section === Section.Header) {
            if (this.index === 0) {
                this.header = new Header();
                try {
                    this.header.dataType = Object.entries(DataType).find(([key, value]) => value === data)?.[0]//DataType[data];
                } catch (e) {
                    this.state = StateLoading.Failure;
                    this.message = `Error / Receiver / Section.Header / DataType Error. 0x${data.toString(16).toUpperCase()}`;
                    return this.state;
                }
                this.crc16calculated = CRC16.calc(data, 0);
            } else if (this.index === 1) {
                this.header.length = data;
                this.crc16calculated = CRC16.calc(data, this.crc16calculated);
                if (this.header.length > 128) {
                    this.state = StateLoading.Failure;
                    this.message = `Error / Receiver / Section.Header / Data length is longer than 128. [${this.header.length}]`;
                    return this.state;
                }
            } else if (this.index === 2) {
                try {
                    this.header.from_ = Object.entries(DeviceType).find(([key, value]) => value === data)?.[0]//DeviceType[data];
                } catch (e) {
                    this.state = StateLoading.Failure;
                    this.message = `Error / Receiver / Section.Header / DeviceType Error. 0x${data.toString(16).toUpperCase()}`;
                    return this.state;
                }
                this.crc16calculated = CRC16.calc(data, this.crc16calculated);
            } else if (this.index === 3) {
                try {
                    this.header.to_ = Object.entries(DeviceType).find(([key, value]) => value === data)?.[0]//DeviceType[data];
                } catch (e) {
                    this.state = StateLoading.Failure;
                    this.message = `Error / Receiver / Section.Header / DeviceType Error. 0x${data.toString(16).toUpperCase()}`;
                    return this.state;
                }
                this.crc16calculated = CRC16.calc(data, this.crc16calculated);
                if (this.header.length === 0) {
                    this.section = Section.End;
                } else {
                    this.section = Section.Data;
                    this._buffer = [];
                }
            } else {
                this.state = StateLoading.Failure;
                this.message = "Error / Receiver / Section.Header / Index over.";
                return this.state;
            }
        } else if (this.section === Section.Data) {
            this._buffer.push(data);
            this.crc16calculated = CRC16.calc(data, this.crc16calculated);
            if (this.index === this.header.length - 1) {
                this.section = Section.End;
            }
        } else if (this.section === Section.End) {
            if (this.index === 0) {
                this.crc16received = data;
            } else if (this.index === 1) {
                this.crc16received = (data << 8) | this.crc16received;
                if (this.crc16received === this.crc16calculated) {
                    this.data = [...this._buffer];
                    this.timeReceiveComplete = now;
                    this.state = StateLoading.Loaded;
                    this.message = `Success / Receiver / Section.End / Receive complete / ${this.header.dataType} / [receive: 0x${this.crc16received.toString(16).toUpperCase()}]`;
                    return this.state;
                } else {
                    this.state = StateLoading.Failure;
                    this.message = `Error / Receiver / Section.End / CRC Error / ${this.header.dataType} / [receive: 0x${this.crc16received.toString(16).toUpperCase()}, calculate: 0x${this.crc16calculated.toString(16).toUpperCase()}]`;
                    return this.state;
                }
            } else {
                this.state = StateLoading.Failure;
                this.message = "Error / Receiver / Section.End / Index over.";
                return this.state;
            }
        } else {
            this.state = StateLoading.Failure;
            this.message = "Error / Receiver / Section over.";
            return this.state;
        }

        // Fourth Step
        if (this.state === StateLoading.Receiving) {
            this.index += 1;
        }

        return this.state;
    }

    checked() {
        this.state = StateLoading.Ready
    }
};

export class Buzzer {
    constructor() {
        this.mode = BuzzerMode.Stop;
        this.value = 0;
        this.time = 0;
    }

    static getSize() {
        return 5;
    }

    toArray() {
        const buffer = new ArrayBuffer(5);
        const view = new DataView(buffer);
        view.setUint8(0, this.mode);
        view.setUint16(1, this.value, true); // true for little-endian
        view.setUint16(3, this.time, true); // true for little-endian
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== Buzzer.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new Buzzer();
        data.mode = view.getUint8(0);
        data.value = view.getUint16(1, true); // true for little-endian
        data.time = view.getUint16(3, true); // true for little-endian

        data.mode = BuzzerMode[data.mode];

        return data;
    }
};


   
export class BuzzerMode {
    static Stop = 0;
    static Mute = 1;
    static MuteReserve = 2;
    static Scale = 3;
    static ScaleReserve = 4;
    static Hz = 5;
    static HzReserve = 6;
    static EndOfType = 7;
}

export class BuzzerScale {
    static C1 = 0x00; static CS1 = 0x01; static D1 = 0x02; static DS1 = 0x03; static E1 = 0x04; static F1 = 0x05; static FS1 = 0x06; static G1 = 0x07; static GS1 = 0x08; static A1 = 0x09; static AS1 = 0x0A; static B1 = 0x0B;
    static C2 = 0x0C; static CS2 = 0x0D; static D2 = 0x0E; static DS2 = 0x0F; static E2 = 0x10; static F2 = 0x11; static FS2 = 0x12; static G2 = 0x13; static GS2 = 0x14; static A2 = 0x15; static AS2 = 0x16; static B2 = 0x17;
    static C3 = 0x18; static CS3 = 0x19; static D3 = 0x1A; static DS3 = 0x1B; static E3 = 0x1C; static F3 = 0x1D; static FS3 = 0x1E; static G3 = 0x1F; static GS3 = 0x20; static A3 = 0x21; static AS3 = 0x22; static B3 = 0x23;
    static C4 = 0x24; static CS4 = 0x25; static D4 = 0x26; static DS4 = 0x27; static E4 = 0x28; static F4 = 0x29; static FS4 = 0x2A; static G4 = 0x2B; static GS4 = 0x2C; static A4 = 0x2D; static AS4 = 0x2E; static B4 = 0x2F;
    static C5 = 0x30; static CS5 = 0x31; static D5 = 0x32; static DS5 = 0x33; static E5 = 0x34; static F5 = 0x35; static FS5 = 0x36; static G5 = 0x37; static GS5 = 0x38; static A5 = 0x39; static AS5 = 0x3A; static B5 = 0x3B;
    static C6 = 0x3C; static CS6 = 0x3D; static D6 = 0x3E; static DS6 = 0x3F; static E6 = 0x40; static F6 = 0x41; static FS6 = 0x42; static G6 = 0x43; static GS6 = 0x44; static A6 = 0x45; static AS6 = 0x46; static B6 = 0x47;
    static C7 = 0x48; static CS7 = 0x49; static D7 = 0x4A; static DS7 = 0x4B; static E7 = 0x4C; static F7 = 0x4D; static FS7 = 0x4E; static G7 = 0x4F; static GS7 = 0x50; static A7 = 0x51; static AS7 = 0x52; static B7 = 0x53;
    static C8 = 0x54; static CS8 = 0x55; static D8 = 0x56; static DS8 = 0x57; static E8 = 0x58; static F8 = 0x59; static FS8 = 0x5A; static G8 = 0x5B; static GS8 = 0x5C; static A8 = 0x5D; static AS8 = 0x5E; static B8 = 0x5F;
    static EndOfType = 0x60;
    static Mute = 0xEE;
    static Fin = 0xFF;
}

export class Note {
    static C1 = 0x00; static CS1 = 0x01; static D1 = 0x02; static DS1 = 0x03; static E1 = 0x04; static F1 = 0x05; static FS1 = 0x06; static G1 = 0x07; static GS1 = 0x08; static A1 = 0x09; static AS1 = 0x0A; static B1 = 0x0B;
    static C2 = 0x0C; static CS2 = 0x0D; static D2 = 0x0E; static DS2 = 0x0F; static E2 = 0x10; static F2 = 0x11; static FS2 = 0x12; static G2 = 0x13; static GS2 = 0x14; static A2 = 0x15; static AS2 = 0x16; static B2 = 0x17;
    static C3 = 0x18; static CS3 = 0x19; static D3 = 0x1A; static DS3 = 0x1B; static E3 = 0x1C; static F3 = 0x1D; static FS3 = 0x1E; static G3 = 0x1F; static GS3 = 0x20; static A3 = 0x21; static AS3 = 0x22; static B3 = 0x23;
    static C4 = 0x24; static CS4 = 0x25; static D4 = 0x26; static DS4 = 0x27; static E4 = 0x28; static F4 = 0x29; static FS4 = 0x2A; static G4 = 0x2B; static GS4 = 0x2C; static A4 = 0x2D; static AS4 = 0x2E; static B4 = 0x2F;
    static C5 = 0x30; static CS5 = 0x31; static D5 = 0x32; static DS5 = 0x33; static E5 = 0x34; static F5 = 0x35; static FS5 = 0x36; static G5 = 0x37; static GS5 = 0x38; static A5 = 0x39; static AS5 = 0x3A; static B5 = 0x3B;
    static C6 = 0x3C; static CS6 = 0x3D; static D6 = 0x3E; static DS6 = 0x3F; static E6 = 0x40; static F6 = 0x41; static FS6 = 0x42; static G6 = 0x43; static GS6 = 0x44; static A6 = 0x45; static AS6 = 0x46; static B6 = 0x47;
    static C7 = 0x48; static CS7 = 0x49; static D7 = 0x4A; static DS7 = 0x4B; static E7 = 0x4C; static F7 = 0x4D; static FS7 = 0x4E; static G7 = 0x4F; static GS7 = 0x50; static A7 = 0x51; static AS7 = 0x52; static B7 = 0x53;
    static C8 = 0x54; static CS8 = 0x55; static D8 = 0x56; static DS8 = 0x57; static E8 = 0x58; static F8 = 0x59; static FS8 = 0x5A; static G8 = 0x5B; static GS8 = 0x5C; static A8 = 0x5D; static AS8 = 0x5E; static B8 = 0x5F;
    static EndOfType = 0x60;
    static Mute = 0xEE;
    static Fin = 0xFF;
}

const ModeSystem = Object.freeze({
    None_: 0x00,
    Boot: 0x10,
    Start: 0x11,
    Running: 0x12,
    ReadyToReset: 0x13,
    Error: 0xA0,
    EndOfType: 0x06,
    0x00: 'None_',
    0x10: 'Boot',
    0x11: 'Start',
    0x12: 'Running',
    0x13: 'ReadyToReset',
    0xA0: 'Error',
    0x06: 'EndOfType'
});
const ModeControlFlight = Object.freeze({
    None_: 0x00,
    Attitude: 0x10,      // 자세 - X,Y는 각도(deg)로 입력받음, Z,Yaw는 속도(m/s)로 입력 받음
    Position: 0x11,      // 위치 - X,Y,Z,Yaw는 속도(m/s)로 입력 받음
    Manual: 0x12,        // 고도를 수동으로 조종함
    Rate: 0x13,          // Rate - X,Y는 각속도(deg/s)로 입력받음, Z,Yaw는 속도(m/s)로 입력 받음
    Function: 0x14,      // 기능
    EndOfType: 0x15,
    0x00: 'None_',
    0x10: 'Attitude',
    0x11: 'Position',
    0x12: 'Manual',
    0x13: 'Rate',
    0x14: 'Function',
    0x15: 'EndOfType'
});
const ModeMovement = Object.freeze({
    None_: 0x00,    
    Ready: 0x01,      // Ready
    Hovering: 0x02,      // Hovering
    Moving: 0x03,      // Moving
    ReturnHome: 0x04,      // Return Home
    EndOfType: 0x05,
    0x00: 'None_',
    0x01: 'Ready',
    0x02: 'Hovering',
    0x03: 'Moving',
    0x04: 'ReturnHome',
    0x05: 'EndOfType'
});
const Headless = Object.freeze({
    None_: 0x00,    
    Headless: 0x01,      // Headless
    Normal: 0x02,      // Normal
    EndOfType: 0x03,
    0x00: 'None_',
    0x01: 'Headless',
    0x02: 'Normal',
    0x03: 'EndOfType'
});
const SensorOrientation = Object.freeze({
    None_: 0x00,    
    Normal: 0x01,
    ReverseStart: 0x02,
    Reversed: 0x03,
    EndOfType: 0x04,
    0x00: 'None_',
    0x01: 'Normal',
    0x02: 'ReverseStart',
    0x03: 'Reversed',
    0x04: 'EndOfType'
});

export const LightModeDrone = Object.freeze({
    None_: 0x00,
    RearNone: 0x10,
    RearManual: 0x11,      // 
    RearHold: 0x12,        // 
    RearFlicker: 0x13,     // 
    RearFlickerDouble: 0x14, // 
    RearDimming: 0x15,     //
    RearSunrise: 0x16,
    RearSunset: 0x17,
    BodyNone: 0x20,
    BodyManual: 0x21,      // 
    BodyHold: 0x22,        // 
    BodyFlicker: 0x23,     // 
    BodyFlickerDouble: 0x24, // 
    BodyDimming: 0x25,     // 
    BodySunrise: 0x26,
    BodySunset: 0x27,
    BodyRainbow: 0x28,
    BodyRainbow2: 0x29,
    ANone: 0x30,
    AManual: 0x31,         // 
    AHold: 0x32,           // 
    AFlicker: 0x33,        // 
    AFlickerDouble: 0x34,  // 
    ADimming: 0x35,        // 
    ASunrise: 0x36,
    ASunset: 0x37,
    BNone: 0x40,
    BManual: 0x41,         // 
    BHold: 0x42,           // 
    BFlicker: 0x43,        // 
    BFlickerDouble: 0x44,  // 
    BDimming: 0x45,        // 
    BSunrise: 0x46,
    BSunset: 0x47,
    EndOfType: 0x60,
});

export const LightModeController = Object.freeze({
    None_: 0x00,
    // Body
    BodyNone: 0x20,
    BodyManual: 0x21,      // 
    BodyHold: 0x22,
    BodyFlicker: 0x23,
    BodyFlickerDouble: 0x24,
    BodyDimming: 0x25,
    BodySunrise: 0x26,
    BodySunset: 0x27,
    BodyRainbow: 0x28,
    BodyRainbow2: 0x29,
    EndOfType: 0x30,
});


const JoystickDirection = Object.freeze({
    None_: 0,         // 정의하지 않은 영역(무시함)
    VT: 0x10,         // 위(세로)
    VM: 0x20,         // 중앙(세로)
    VB: 0x40,         // 아래(세로)
    HL: 0x01,         // 왼쪽(가로)
    HM: 0x02,         // 중앙(가로)
    HR: 0x04,         // 오른쪽(가로)
    TL: 0x11, TM: 0x12, TR: 0x14,
    ML: 0x21, CN: 0x22, MR: 0x24,
    BL: 0x41, BM: 0x42, BR: 0x44
});

const JoystickEvent = Object.freeze({
    None_: 0,         // 이벤트 없음
    In: 1,            // 특정 영역에 진입
    Stay: 2,          // 특정 영역에서 상태 유지
    Out: 3,           // 특정 영역에서 벗어남
    EndOfType: 4
});

export const ErrorFlagsForSensor = Object.freeze({
    None_: 0x00000000,
    Motion_NoAnswer: 0x00000001, // No response from Motion sensor
    Motion_WrongValue: 0x00000002, // Incorrect value from Motion sensor
    Motion_NotCalibrated: 0x00000004, // Gyro Bias calibration not completed
    Motion_Calibrating: 0x00000008, // Gyro Bias calibration in progress
    Pressure_NoAnswer: 0x00000010, // No response from Pressure sensor
    Pressure_WrongValue: 0x00000020, // Incorrect value from Pressure sensor
    RangeGround_NoAnswer: 0x00000100, // No response from Ground distance sensor
    RangeGround_WrongValue: 0x00000200, // Incorrect value from Ground distance sensor
    Flow_NoAnswer: 0x00001000, // No response from Flow sensor
    Flow_WrongValue: 0x00002000, // Incorrect value from Flow sensor
    Flow_CannotRecognizeGroundImage: 0x00004000, // Cannot recognize ground image
});

export const ErrorFlagsForState = Object.freeze({
    None_: 0x00000000,
    NotRegistered: 0x00000001, // Device not registered
    FlashReadLock_UnLocked: 0x00000002, // Flash memory read lock not engaged
    BootloaderWriteLock_UnLocked: 0x00000004, // Bootloader write lock not engaged
    LowBattery: 0x00000008, // Low battery
    TakeoffFailure_CheckPropellerAndMotor: 0x00000010, // Takeoff failure
    CheckPropellerVibration: 0x00000020, // Propeller vibration detected
    Attitude_NotStable: 0x00000040, // Attitude is too tilted or inverted
    CanNotFlip_LowBattery: 0x00000100, // Battery below 50%
    CanNotFlip_TooHeavy: 0x00000200, // Device is too heavy
});

export class Trim {
    constructor() {
        this.roll = 0;
        this.pitch = 0;
        this.yaw = 0;
        this.throttle = 0;
    }

    static getSize() {
        return 8;
    }

    toArray() {
        const buffer = new ArrayBuffer(Trim.getSize());
        const view = new DataView(buffer);
        view.setInt16(0, this.roll, true); // true for little-endian
        view.setInt16(2, this.pitch, true); // true for little-endian
        view.setInt16(4, this.yaw, true); // true for little-endian
        view.setInt16(6, this.throttle, true); // true for little-endian
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== Trim.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new Trim();
        data.roll = view.getInt16(0, true); // true for little-endian
        data.pitch = view.getInt16(2, true); // true for little-endian
        data.yaw = view.getInt16(4, true); // true for little-endian
        data.throttle = view.getInt16(6, true); // true for little-endian

        return data;
    }
}


export class RawFlow {
    constructor() {
        this.x = 0;
        this.y = 0;
    }

    static getSize() {
        return 8;
    }

    toArray() {
        const buffer = new ArrayBuffer(RawFlow.getSize());
        const view = new DataView(buffer);
        view.setFloat32(0, this.x, true); // true for little-endian
        view.setFloat32(4, this.y, true); // true for little-endian
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== RawFlow.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new RawFlow();
        data.x = view.getFloat32(0, true); // true for little-endian
        data.y = view.getFloat32(4, true); // true for little-endian

        return data;
    }
}


class JoystickBlock {
    constructor() {
        this.x = 0;
        this.y = 0;
        this.direction = JoystickDirection.None_;
        this.event = JoystickEvent.None_;
    }

    static getSize() {
        return 4;
    }

    toArray() {
        const buffer = new ArrayBuffer(4);
        const view = new DataView(buffer);
        view.setInt8(0, this.x);
        view.setInt8(1, this.y);
        view.setUint8(2, this.direction);
        view.setUint8(3, this.event);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== JoystickBlock.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new JoystickBlock();
        data.x = view.getInt8(0);
        data.y = view.getInt8(1);
        data.direction = view.getUint8(2);
        data.event = view.getUint8(3);

        // Convert to enum values
        data.direction = Object.keys(JoystickDirection).find(key => JoystickDirection[key] === data.direction);
        data.event = Object.keys(JoystickEvent).find(key => JoystickEvent[key] === data.event);

        return data;
    }
}

export class Joystick {
    constructor() {
        this.left = new JoystickBlock();
        this.right = new JoystickBlock();
    }

    static getSize() {
        return JoystickBlock.getSize() * 2;
    }

    toArray() {
        const leftArray = this.left.toArray();
        const rightArray = this.right.toArray();
        return new Uint8Array([...leftArray, ...rightArray]);
    }

    static parse(dataArray) {
        if (dataArray.length !== Joystick.getSize()) {
            return null;
        }

        const data = new Joystick();
        const leftArray = dataArray.slice(0, JoystickBlock.getSize());
        const rightArray = dataArray.slice(JoystickBlock.getSize());

        data.left = JoystickBlock.parse(leftArray);
        data.right = JoystickBlock.parse(rightArray);

        return data;
    }
}

export class State {
    constructor() {
        this.modeSystem = ModeSystem.None_;
        this.modeFlight = ModeFlight.None_;
        this.modeControlFlight = ModeControlFlight.None_;
        this.modeMovement = ModeMovement.None_;
        this.headless = Headless.None_;
        this.controlSpeed = 0;
        this.sensorOrientation = SensorOrientation.None_;
        this.battery = 0;
    }

    static getSize() {
        return 8;
    }

    toArray() {
        const buffer = new ArrayBuffer(8);
        const view = new DataView(buffer);
        view.setUint8(0, this.modeSystem);
        view.setUint8(1, this.modeFlight);
        view.setUint8(2, this.modeControlFlight);
        view.setUint8(3, this.modeMovement);
        view.setUint8(4, this.headless);
        view.setUint8(5, this.controlSpeed);
        view.setUint8(6, this.sensorOrientation);
        view.setUint8(7, this.battery);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        
        let buffer;
        if (dataArray instanceof ArrayBuffer) {
            buffer = dataArray;
        } else if (dataArray instanceof Uint8Array) {
            buffer = dataArray.buffer;
        } else if (Array.isArray(dataArray)) {
            buffer = new Uint8Array(dataArray).buffer;
        } else {
            throw new TypeError('Data must be an ArrayBuffer, Uint8Array, or Array');
        }

        const view = new DataView(buffer);
        const data = new State();
        data.modeSystem = view.getUint8(0);
        data.modeFlight = view.getUint8(1);
        data.modeControlFlight = view.getUint8(2);
        data.modeMovement = view.getUint8(3);
        data.headless = view.getUint8(4);
        data.controlSpeed = view.getUint8(5);
        data.sensorOrientation = view.getUint8(6);
        data.battery = view.getUint8(7);
        
        // Convert to enum values
        data.modeSystem = ModeSystem[data.modeSystem];
        data.modeFlight = ModeFlight[data.modeFlight];
        data.modeControlFlight = ModeControlFlight[data.modeControlFlight];
        data.modeMovement = ModeMovement[data.modeMovement];
        data.headless = Headless[data.headless];
        data.sensorOrientation = SensorOrientation[data.sensorOrientation];

        return data;
    }
}

export class Ping {
    constructor() {
        this.systemTime = 0;
    }

    static getSize() {
        return 8;
    }

    toArray() {
        const buffer = new ArrayBuffer(8);
        const view = new DataView(buffer);
        view.setBigUint64(0, this.systemTime, true);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== Ping.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new Ping();
        data.systemTime = Number(view.getBigUint64(0, true));
        return data;
    }
}

export class Ack {
    constructor() {
        this.systemTime = 0;
        this.dataType = DataType.None_;
        this.crc16 = 0;
    }

    static getSize() {
        return 11;
    }

    toArray() {
        const buffer = new ArrayBuffer(11);
        const view = new DataView(buffer);
        view.setBigUint64(0, this.systemTime, true);
        view.setUint8(8, this.dataType);
        view.setUint16(9, this.crc16, true);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== Ack.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new Ack();
        data.systemTime = Number(view.getBigUint64(0, true));
        data.dataType = view.getUint8(8);
        data.crc16 = view.getUint16(9, true);

        // Convert to enum value
        data.dataType = DataType[data.dataType];
        return data;
    }
}

export class Error {
    constructor() {
        this.systemTime = 0;
        this.errorFlagsForSensor = 0;
        this.errorFlagsForState = 0;
    }

    static getSize() {
        return 16;
    }

    toArray() {
        const buffer = new ArrayBuffer(16);
        const view = new DataView(buffer);
        view.setBigUint64(0, this.systemTime, true);
        view.setUint32(8, this.errorFlagsForSensor, true);
        view.setUint32(12, this.errorFlagsForState, true);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== Error.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new Error();
        data.systemTime = Number(view.getBigUint64(0, true));
        data.errorFlagsForSensor = view.getUint32(8, true);
        data.errorFlagsForState = view.getUint32(12, true);
        return data;
    }
}

export class ControlPosition {
    constructor() {
        this.positionX = 0;
        this.positionY = 0;
        this.positionZ = 0;
        this.velocity = 0;
        this.heading = 0;
        this.rotationalVelocity = 0;
    }

    static getSize() {
        return 20;
    }

    toArray() {
        const buffer = new ArrayBuffer(20);
        const view = new DataView(buffer);
        view.setFloat32(0, this.positionX, true); // true for little-endian
        view.setFloat32(4, this.positionY, true);
        view.setFloat32(8, this.positionZ, true);
        view.setFloat32(12, this.velocity, true);
        view.setInt16(16, this.heading, true);
        view.setInt16(18, this.rotationalVelocity, true);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== ControlPosition.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new ControlPosition();
        data.positionX = view.getFloat32(0, true); // true for little-endian
        data.positionY = view.getFloat32(4, true);
        data.positionZ = view.getFloat32(8, true);
        data.velocity = view.getFloat32(12, true);
        data.heading = view.getInt16(16, true);
        data.rotationalVelocity = view.getInt16(18, true);

        return data;
    }
}

export class Altitude {
    constructor() {
        this.temperature = 0;
        this.pressure = 0;
        this.altitude = 0;
        this.rangeHeight = 0;
    }

    static getSize() {
        return 16;
    }

    toArray() {
        const buffer = new ArrayBuffer(16);
        const view = new DataView(buffer);
        view.setFloat32(0, this.temperature, true); // true for little-endian
        view.setFloat32(4, this.pressure, true);
        view.setFloat32(8, this.altitude, true);
        view.setFloat32(12, this.rangeHeight, true);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== Altitude.getSize()) {
            return null;
        }
        
        const view = new DataView(dataArray.buffer);
        const data = new Altitude();
        data.temperature = view.getFloat32(0, true); // true for little-endian
        data.pressure = view.getFloat32(4, true);
        data.altitude = view.getFloat32(8, true);
        data.rangeHeight = view.getFloat32(12, true);
        return data;
    }
}

export class Position {
    constructor() {
        this.x = 0;
        this.y = 0;
        this.z = 0;
    }

    static getSize() {
        return 12;
    }

    toArray() {
        const buffer = new ArrayBuffer(12);
        const view = new DataView(buffer);
        view.setFloat32(0, this.x, true); // true for little-endian
        view.setFloat32(4, this.y, true);
        view.setFloat32(8, this.z, true);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== Position.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new Position();
        data.x = view.getFloat32(0, true); // true for little-endian
        data.y = view.getFloat32(4, true);
        data.z = view.getFloat32(8, true);

        return data;
    }
}


export class Motion {
    constructor() {
        this.accelX = 0;
        this.accelY = 0;
        this.accelZ = 0;
        this.gyroRoll = 0;
        this.gyroPitch = 0;
        this.gyroYaw = 0;
        this.angleRoll = 0;
        this.anglePitch = 0;
        this.angleYaw = 0;
    }

    static getSize() {
        return 18;
    }

    toArray() {
        const buffer = new ArrayBuffer(18);
        const view = new DataView(buffer);
        view.setInt16(0, this.accelX, true); // true for little-endian
        view.setInt16(2, this.accelY, true);
        view.setInt16(4, this.accelZ, true);
        view.setInt16(6, this.gyroRoll, true);
        view.setInt16(8, this.gyroPitch, true);
        view.setInt16(10, this.gyroYaw, true);
        view.setInt16(12, this.angleRoll, true);
        view.setInt16(14, this.anglePitch, true);
        view.setInt16(16, this.angleYaw, true);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== Motion.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new Motion();
        data.accelX = view.getInt16(0, true); // true for little-endian
        data.accelY = view.getInt16(2, true);
        data.accelZ = view.getInt16(4, true);
        data.gyroRoll = view.getInt16(6, true);
        data.gyroPitch = view.getInt16(8, true);
        data.gyroYaw = view.getInt16(10, true);
        data.angleRoll = view.getInt16(12, true);
        data.anglePitch = view.getInt16(14, true);
        data.angleYaw = view.getInt16(16, true);

        return data;
    }
}

export class Range {
    constructor() {
        this.left = 0;
        this.front = 0;
        this.right = 0;
        this.rear = 0;
        this.top = 0;
        this.bottom = 0;
    }

    static getSize() {
        return 12;
    }

    toArray() {
        const buffer = new ArrayBuffer(12);
        const view = new DataView(buffer);
        view.setInt16(0, this.left, true); // true for little-endian
        view.setInt16(2, this.front, true);
        view.setInt16(4, this.right, true);
        view.setInt16(6, this.rear, true);
        view.setInt16(8, this.top, true);
        view.setInt16(10, this.bottom, true);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== Range.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new Range();
        data.left = view.getInt16(0, true); // true for little-endian
        data.front = view.getInt16(2, true);
        data.right = view.getInt16(4, true);
        data.rear = view.getInt16(6, true);
        data.top = view.getInt16(8, true);
        data.bottom = view.getInt16(10, true);

        return data;
    }
}

export class LightModeColor {
    constructor() {
        this.mode = new LightMode();
        this.color = new Color();
    }

    static getSize() {
        return LightMode.getSize() + Color.getSize();
    }

    toArray() {
        const modeArray = this.mode.toArray();
        const colorArray = this.color.toArray();
        const dataArray = new Uint8Array(modeArray.length + colorArray.length);
        dataArray.set(modeArray, 0);
        dataArray.set(colorArray, modeArray.length);
        return dataArray;
    }

    static parse(dataArray) {
        if (dataArray.length !== LightModeColor.getSize()) {
            return null;
        }

        const data = new LightModeColor();
        const modeSize = LightMode.getSize();
        const colorSize = Color.getSize();

        data.mode = LightMode.parse(dataArray.slice(0, modeSize));
        data.color = Color.parse(dataArray.slice(modeSize, modeSize + colorSize));

        return data;
    }
}


class Color {
    constructor() {
        this.r = 0;
        this.g = 0;
        this.b = 0;
    }

    static getSize() {
        return 3;
    }

    toArray() {
        const buffer = new ArrayBuffer(3);
        const view = new DataView(buffer);
        view.setUint8(0, this.r);
        view.setUint8(1, this.g);
        view.setUint8(2, this.b);
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== Color.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new Color();
        data.r = view.getUint8(0);
        data.g = view.getUint8(1);
        data.b = view.getUint8(2);

        return data;
    }
}

class LightMode {
    constructor() {
        this.mode = 0;
        this.interval = 0;
    }

    static getSize() {
        return 3;
    }

    toArray() {
        const buffer = new ArrayBuffer(3);
        const view = new DataView(buffer);
        view.setUint8(0, this.mode);
        view.setUint16(1, this.interval, true); // true for little-endian
        return new Uint8Array(buffer);
    }

    static parse(dataArray) {
        if (dataArray.length !== LightMode.getSize()) {
            return null;
        }

        const view = new DataView(dataArray.buffer);
        const data = new LightMode();
        data.mode = view.getUint8(0);
        data.interval = view.getUint16(1, true); // true for little-endian

        return data;
    }
}
